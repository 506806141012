import { DataConversion, Optional } from "@incert/data-conversion";
import {
  TouristAttractionTag,
  TouristAttractionModel,
  TouristAttractionType,
  TouristDestination,
  MarkerType,
} from "./tourist-attraction.model";
import { TouristAttractionChallenge } from "./tourist-attraction-challenge-user.model";
import { SelectItem } from "primeng/api";
import { Reward } from "@incert/bon/bon-core/src/lib/model/reward.model";

@DataConversion()
export class ChallengeTranslation {
  @Optional()
  id: string;
  @Optional()
  name: string;
  @Optional()
  code: string;
  @Optional()
  description: string;
}

@DataConversion()
export class TouristAttractionMetadata {
  @Optional()
  types: SelectItem[];
  @Optional()
  linkedAttractions: TouristAttractionModel[];
  @Optional()
  destinations: SelectItem[];
  @Optional()
  tags: SelectItem[];
  @Optional()
  markerTypes: SelectItem[];
  @Optional()
  rewards: SelectItem[];
}

@DataConversion()
export class Challenge {
  @Optional()
  id: string;
  @Optional()
  rewardAmount: number;
  @Optional()
  createdAt: Date;
  @Optional()
  validFrom: Date;
  @Optional()
  validUntil: Date;
  @Optional()
  translations: Array<ChallengeTranslation>;
  @Optional()
  touristAttractions: Array<TouristAttractionModel>;
  @Optional()
  reward: Reward;
  @Optional()
  active: boolean;
}

export class MappedTouristAttractionChallenge {
  @Optional()
  id: string;
  @Optional()
  touristAttraction: TouristAttractionModel;
}
