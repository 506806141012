import { OpeningTimeEntryDescription } from "./opening-time-entry-description.model";
import {
  ArrayType,
  DataConversion,
  DateConversion,
  Optional,
  Required,
} from "@incert/data-conversion";

@DataConversion()
export class OpeningTimeEntry {
  @Optional()
  id: number;

  @Optional()
  @DateConversion()
  day: Date;

  @Optional()
  @DateConversion()
  from: Date = null;

  @Optional()
  @DateConversion()
  to: Date = null;

  @Required()
  highlight: boolean;

  @Required()
  @ArrayType(() =>
    import("./opening-time-entry-description.model").then(
      (mod) => mod.OpeningTimeEntryDescription,
    ),
  )
  openingTimeEntryDescriptions?: OpeningTimeEntryDescription[];
}
