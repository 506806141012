import { DataConversion, Optional, Required } from "@incert/data-conversion";

@DataConversion()
export class CountryListEntry {
  @Required()
  id: number;

  @Required()
  name: string;

  @Optional()
  isoCode2: string;

  @Optional()
  isoCode3: string;

  @Optional()
  status: boolean;
}
