import { Optional } from "@angular/core";
import { Required, DataConversion } from "@incert/data-conversion";

@DataConversion()
export class CurrencyPrice {
  @Required()
  currency: {
    id: number;
  };

  @Optional()
  product: {
    id: number;
  };

  @Optional()
  productAttribute: {
    id: number;
  };

  @Required()
  price: number;
}
