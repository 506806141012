import {
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  Type,
} from "@angular/core";
import { WidgetConfiguration } from "./widget-configuration.interface";
import { LoadingService, OverlayService } from "@incert/incert-core";
import { WidgetConfigurationHostComponent } from "./widget-configuration-host.component";
import { ChangeStateCallback } from "../widget-handle.service";
import { globalWidgetConfigurationKey } from "./global-widget-configration-key.const";
import { Observable, ReplaySubject } from "rxjs";
import {
  isWidgetConfigurationDescription,
  WidgetDescriptionEntry,
} from "./widget-configuration.description";
import { WidgetModel } from "../widget.model";
import {
  isDetailWidgetConfigurationProperty,
  WidgetConfigurationProperty,
} from "../interface/widget-properties.interface";
import { OverlaySize } from "@incert/incert-core";
import { I18nService } from "@incert/i18n";

export class WidgetConfigurationValueResolver {
  public constructor(private state: any) {}

  public get<T extends WidgetConfiguration>(
    property: keyof T["configuration"],
  ): any {
    if (property in this.state) {
      return this.state[property];
    }
    return null;
  }
}

export class WidgetConfigurationService {
  private componentRefs: ComponentRef<WidgetConfiguration>[];
  private _change$ = new ReplaySubject<WidgetConfigurationValueResolver>();
  private state: any = {};
  public globalFilterState: any = {};

  private aggregatedConfiguration: WidgetConfiguration["configuration"] = {};
  private _globalFilterOverridden: boolean;

  public get globalFilterOverridden() {
    return this._globalFilterOverridden;
  }

  private get aggregatedConfigurationValue() {
    const configurationValue = {};
    for (const key of Object.keys(this.aggregatedConfiguration)) {
      configurationValue[key] = this.aggregatedConfiguration[key].get();
    }
    return configurationValue;
  }

  private get aggregatedConfigurationValid() {
    for (const ref of this.componentRefs) {
      if (!ref.instance.isConfigurationValid()) {
        return false;
      }
    }
    return true;
  }

  public get descriptionEntries() {
    const descriptionEntries: WidgetDescriptionEntry[] = [];
    for (const ref of this.componentRefs) {
      if (isWidgetConfigurationDescription(ref.instance)) {
        const entry = ref.instance.getWidgetConfigurationDescription(
          this.state,
          this.i18n,
        );
        if (entry) {
          descriptionEntries.push(entry);
        }
      }
    }
    return descriptionEntries;
  }

  public get change$(): Observable<WidgetConfigurationValueResolver> {
    return this._change$.asObservable();
  }

  public constructor(
    private widgetConfigurationProperties: WidgetConfigurationProperty<any>[],
    private widget: WidgetModel,
    private widgetChangeStateCallback: ChangeStateCallback,
    private overlayService: OverlayService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private loadingService: LoadingService,
    private i18n: I18nService,
  ) {}

  public async init(changed = true) {
    this.aggregatedConfiguration = {};
    this.componentRefs = [];
    for (const widgetConfigurationProperty of this
      .widgetConfigurationProperties) {
      let widgetConfiguration: Type<WidgetConfiguration> = null;
      if (isDetailWidgetConfigurationProperty(widgetConfigurationProperty)) {
        widgetConfiguration = widgetConfigurationProperty.configuration;
      } else {
        widgetConfiguration = widgetConfigurationProperty;
      }

      // resolve component
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          widgetConfiguration,
        );
      const ref = componentFactory.create(this.injector);

      //set options
      if (isDetailWidgetConfigurationProperty(widgetConfigurationProperty)) {
        for (const key of Object.keys(widgetConfigurationProperty.property)) {
          if (key in ref.instance) {
            ref.instance[key] = widgetConfigurationProperty.property[key];
          }
        }
      }

      for (const key of Object.keys(ref.instance.configuration)) {
        // add configuration to list
        this.aggregatedConfiguration[key] = ref.instance.configuration[key];
        // set value from state
        if (this.widget.state && key in this.widget.state) {
          this.aggregatedConfiguration[key].set(this.widget.state[key]);
        }
      }
      this.componentRefs.push(ref);
      await this.loadingService.load(async () => {
        await ref.instance.initConfiguration();
      });
    }

    if (changed) {
      const newState = { ...this.aggregatedConfigurationValue };

      //set global filter values
      this._globalFilterOverridden = false;
      for (const key of Object.keys(this.globalFilterState)) {
        if (key in newState) {
          if (newState[key] === globalWidgetConfigurationKey) {
            newState[key] = this.globalFilterState[key];
          } else {
            this._globalFilterOverridden = true;
          }
        }
      }

      // only trigger change when state changed
      if (JSON.stringify(this.state) !== JSON.stringify(newState)) {
        this.state = newState;
        this._change$.next(new WidgetConfigurationValueResolver(this.state));
      }
    }
  }

  public async setGlobalFilter() {
    for (const key of Object.keys(this.globalFilterState)) {
      if (key in this.state) {
        this.state[key] = globalWidgetConfigurationKey;
      }
    }
    this.widgetChangeStateCallback(this.state);
    await this.init();
  }

  public async show() {
    let changed = false;
    await this.overlayService.show({
      header: this.i18n.instant("dashboard.widgets.configuration"),
      type: WidgetConfigurationHostComponent,
      init: (type) => (type.componentRefs = this.componentRefs),
      actions: [
        {
          label: this.i18n.instant("core.action.cancel"),
          action: () => true,
          displayAsLink: true,
        },
        {
          label: this.i18n.instant("core.action.save"),
          action: () => {
            changed = true;
            return this.aggregatedConfigurationValid;
          },
        },
      ],
      size: OverlaySize.small,
    });

    if (changed) {
      this.widgetChangeStateCallback(this.aggregatedConfigurationValue);
    }
    this.init(changed);
  }
}
