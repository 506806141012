<div class="incert-control-buttons separator-bottom" *ngIf="(!maxControls || fa.controls.length < maxControls) && !autoAdd">
  <icon-button [iconType]="'plus'" *ngFor="let item of controlConfig | keyvalue" (click)="add(item.key)"
               label="{{item.value.name}}"></icon-button>
</div>

<div class="incert-control-selection">
  <ng-container *ngFor="let control of fa.controls; let i = index">
    <div class="incert-control-selection-item" pDraggable="dd" pDroppable="dd"
         (onDragStart)="dragStart(i)" (onDrop)="onDrop(i)">
      <component-outlet [formControl]="control.controls.value"
                        [config]="{component:controlConfig[control.value.type].component,init:controlConfig[control.value.type].init }"></component-outlet>
      <icon-button *ngIf="autoAdd && control.value.value !== ''" [iconType]="'delete'" [iconOnly]="true" [displayIconButtonAsButton]="true" (click)="remove(i)" buttonType="secondary"></icon-button>
      <icon-button *ngIf="!autoAdd" [iconType]="'delete'" [iconOnly]="true" [displayIconButtonAsButton]="true" (click)="remove(i)" buttonType="secondary"></icon-button>
    </div>
  </ng-container>
</div>
