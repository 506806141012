import { DataConversion, Optional } from "@incert/data-conversion";
import { Language } from "./language.model";
import { MetaTag } from "./metatag.model";

@DataConversion()
export class MetaTagDescription {
  @Optional()
  id: number;

  @Optional()
  metaTag: MetaTag;

  @Optional()
  language: Language;

  @Optional()
  content: string;
}
