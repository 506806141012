function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function createQuillTagBlot(
  Quill: any,
  {
    blotName = "tag",
    tagName = "span",
    className = "incert-tag",
    begin = "{{",
    end = "}}",
  } = {},
) {
  const Embed = Quill.import("blots/embed");
  const keywordRegex = new RegExp(
    escapeRegExp(begin) + "(.*?)" + escapeRegExp(end),
    "g",
  );

  class TagBlot extends Embed {
    static blotName = blotName;
    static tagName = tagName;
    static className = className;

    static create(value) {
      const node = super.create();
      node.innerText = begin + value + end;
      return node;
    }

    static value(node) {
      const text = node.innerText.trim();
      return text.substring(begin.length, text.length - end.length);
    }

    static prepareInput(el: Element) {
      el.innerHTML = el.innerHTML.replace(
        keywordRegex,
        `<${tagName} class="${className}">\uFEFF<span contenteditable="false">${begin}$1${end}</span>\uFEFF</${tagName}>`,
      );
    }

    static prepareOutput(el: Element) {
      for (const child of Array.from(el.getElementsByTagName(this.tagName))) {
        if (child.classList.contains(this.className)) {
          const keyword = keywordRegex.exec(child.innerHTML);
          keywordRegex.lastIndex = 0;

          if (keyword) {
            child.parentNode.replaceChild(
              el.ownerDocument.createTextNode(begin + keyword[1] + end),
              child,
            );
          }
        }
      }
    }
  }

  return TagBlot;
}
