import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class PasswordResetTokenInfoModel {
  @Required()
  public firstname: string;

  @Required()
  public lastname: string;

  @Required()
  public oneTime: boolean;
}
