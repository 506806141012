import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Manufacturer } from "./manufacturer.model";

@DataConversion()
export class OAuth2Client {
  @Required()
  public identifier: string;
  @Required()
  public name: string;
  @Required()
  public secret: string;
  @Optional()
  public active: boolean;
  @Optional()
  public manufacturer: Manufacturer;
}
