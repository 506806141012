import { Injectable } from "@angular/core";
import { HttpAccess, HttpAccessInterface } from "@incert/incert-core";
import { DataConverter, ObjectOrArrayType } from "@incert/data-conversion";
import { GMSUrlResolver } from "./gms-url-resolver.service";
import { ApiHttpError } from "./api-http.error";

@Injectable()
export class ApiHttpAccess implements HttpAccessInterface {
  protected apiUrl: string;

  public constructor(
    protected httpAccess: HttpAccess,
    protected gmsUrlResolver: GMSUrlResolver,
  ) {
    this.apiUrl = gmsUrlResolver.getApiUrl();
  }

  async get(path: string): Promise<any>;
  async get<T extends any>(
    path: string,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
  async get<T extends any>(path: any, type?: any) {
    try {
      const response = await this.httpAccess.get(this.apiUrl + path);
      return await this.processResponse(response, type);
    } catch (e) {
      if (e.error?.error) {
        return await this.processResponse(e.error);
      } else {
        throw e;
      }
    }
  }

  async post(path: string, payload: any): Promise<any>;
  async post<T extends any>(
    path: string,
    payload: any,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
  async post<T extends any>(path: any, payload: any, type?: any) {
    try {
      const response = await this.httpAccess.post(this.apiUrl + path, payload);
      return await this.processResponse(response, type);
    } catch (e) {
      if (e.error?.error) {
        return await this.processResponse(e.error);
      } else {
        throw e;
      }
    }
  }

  async delete(path: string): Promise<any>;
  async delete<T extends any>(
    path: string,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
  async delete<T extends any>(path: any, type?: any) {
    try {
      const response = await this.httpAccess.delete(this.apiUrl + path);
      return await this.processResponse(response, type);
    } catch (e) {
      if (e.error?.error) {
        return await this.processResponse(e.error);
      } else {
        throw e;
      }
    }
  }

  async put(path: string, payload: any): Promise<any>;
  async put<T extends any>(
    path: string,
    payload: any,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
  async put<T extends any>(path: any, payload: any, type?: any) {
    try {
      const response = await this.httpAccess.put(this.apiUrl + path, payload);
      return await this.processResponse(response, type);
    } catch (e) {
      if (e.error?.error) {
        return await this.processResponse(e.error);
      } else {
        throw e;
      }
    }
  }

  private async processResponse<T>(response, type?: ObjectOrArrayType<T>) {
    if (typeof response === "object") {
      if (response["error"] && response["message"]) {
        throw new ApiHttpError(response);
      }
      if (type) {
        return await DataConverter.convert(type, response);
      }
    }

    return response;
  }
}
