import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { Manufacturer } from "./manufacturer.model";

@DataConversion()
export class AccessGroupModel {
  @Required()
  public id: number;
  @Optional()
  public name: string;
  @Required()
  public accessGroup: string;
  @Optional()
  public parent?: number;
  @Optional()
  @ArrayType(() =>
    import("./manufacturer.model").then((mod) => mod.Manufacturer),
  )
  public manufacturers: Manufacturer[];
  @Optional()
  @ArrayType(() =>
    import("./access-group.model").then((mod) => mod.AccessGroupModel),
  )
  public children: AccessGroupModel[];
}
