import { Component, OnInit } from "@angular/core";
import { AccountSystemGroup } from "../account-system-group";
import {
  ArrayDataSource,
  createFilterDefinition,
  DataTableConfig,
  TextFilterComponent,
} from "@incert/incert-gui";
import { AccountSystemManager } from "../../account-system/account-system-manager.service";

@Component({
  selector: "incert-account-system-group-info",
  templateUrl: "./account-system-group-info.component.html",
  styleUrls: ["./account-system-group-info.component.css"],
})
export class AccountSystemGroupInfoComponent implements OnInit {
  accountSystems: any[] = [];
  systemGroup: AccountSystemGroup;
  public dtConfig: DataTableConfig<any>;
  public arrayDataSource: ArrayDataSource<any>;

  constructor(private accountSystemManager: AccountSystemManager) {}

  public async ngOnInit() {
    this.accountSystems =
      await this.accountSystemManager.getAccountSystemsByGroup(
        this.systemGroup,
      );
    await this.createDtConfig(this.accountSystems);
  }

  async createDtConfig(accountSystems) {
    this.arrayDataSource = new ArrayDataSource(accountSystems);
    this.dtConfig = {
      mode: "pagination",
      data: this.arrayDataSource,
      rows: 20,
      filterArea: {
        filters: [
          {
            header: "Name",
            property: (v) => v.get("name"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Kürzel",
            property: (v) => v.get("short"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
        ],
      },
      columns: [
        {
          header: "Name",
          property: (v) => v.get("name"),
          sort: true,
        },
        {
          header: "Kürzel",
          property: (v) => v.get("short"),
          sort: true,
        },
      ],
    };
  }
}
