import { DataConversion, Required } from "@incert/data-conversion";
import { Language } from "./language.model";
import { Optional } from "@angular/core";

@DataConversion()
export class CategoryDescription {
  @Required()
  name: string;

  @Optional()
  language: Language;
}
