import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { StatusListEntryModel } from "../models";
import { ApiHttpAccess } from "./http/api-http-access.service";

@Injectable({ providedIn: "root" })
export class OrderStatusService {
  private _orderStatusEntries$ = new ReplaySubject<StatusListEntryModel[]>(1);
  private _orderStatusListEntriesInit = false;

  public constructor(private httpAccess: ApiHttpAccess) {}

  public refreshOrderStatusListEntries() {
    this.httpAccess
      .get<StatusListEntryModel[]>("orders/orderStatus", StatusListEntryModel)
      .then((v) =>
        this._orderStatusEntries$.next(v.filter((status) => status.typ === 1)),
      );
  }

  public get orderStatusEntries$() {
    if (!this._orderStatusListEntriesInit) {
      this.refreshOrderStatusListEntries();
      this._orderStatusListEntriesInit = true;
    }
    return this._orderStatusEntries$.asObservable();
  }
}
