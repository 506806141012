import { DataConversion, Required, Optional } from "@incert/data-conversion";

@DataConversion()
export class CustomerStatus {
  @Required()
  customerStatusId: number;
  @Optional()
  languageId: number;
  @Required()
  customerStatusName: string;
  @Optional()
  customerStatusAdminLogin: number;
}
