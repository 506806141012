import { Injectable } from "@angular/core";
import { ApiHttpAccess } from "../http/api-http-access.service";
import { RouteHistoryModel } from "../../models";

@Injectable({
  providedIn: "root",
})
export class RouteHistoryService {
  constructor(private apiHttpAccess: ApiHttpAccess) {}

  async find(id: number) {
    return (
      (
        await this.apiHttpAccess.get<RouteHistoryModel[]>(
          `routing/routeHistory?q=eq(id,${id})`,
          RouteHistoryModel,
        )
      )[0] ?? null
    );
  }

  async upsert(routeHistoryModel: RouteHistoryModel) {
    await this.apiHttpAccess.put("routing/routeHistory", routeHistoryModel);
  }

  async delete(id: number) {
    await this.apiHttpAccess.delete(`routing/routeHistory/${id}`);
  }
}
