import { Component, Input, OnInit } from "@angular/core";
import {
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  RqlDataSource,
  SelectFilterComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { AccountSystem } from "../../../core/models/account-system.interface";
import { HttpClient } from "@angular/common/http";
import { AccountSystemManager } from "../../account-system/account-system-manager.service";
import {
  AccountSystemPropertyValueRow,
  ConfigProperty,
} from "../key-value-panel/key-value.interface";
import { APIService } from "../../../core/api.service";
import { SelectItem } from "primeng/api";
import { SystemValueManagerService } from "./system-value.manager.service";
import { OverlayService, OverlaySize } from "@incert/incert-core";
import { SystemValueInfoComponent } from "./system-value-info/system-value-info.component";

@Component({
  selector: "incert-internal-tools-system-value",
  templateUrl: "./system-value.component.html",
  styleUrls: ["./system-value.component.scss"],
})
export class SystemValueComponent implements OnInit {
  @Input() public selectedAccountSystemShort: string;
  @Input() public accountSystems: Array<AccountSystem>;
  @Input() public property: string;

  public activeSystem: AccountSystem;
  public dataTableConfig: DataTableConfig<AccountSystemPropertyValueRow>;
  public rqlDataSource: RqlDataSource<AccountSystemPropertyValueRow>;
  public propertySI: Array<SelectItem> = [];
  public accountSystemManager: AccountSystemManager;
  public searchString: string;
  public initialized: boolean;

  constructor(
    private accountSystemConfigManager: SystemValueManagerService,
    private apiService: APIService,
    private httpClient: HttpClient,
    private overlayService: OverlayService,
  ) {}

  async ngOnInit() {
    await this.initialize();
  }

  private async initialize() {
    const properties = await this.fetchProperties();

    this.createPropertiesSI(properties);
    this.createDataTableConfig();

    if (this.selectedAccountSystemShort) {
      this.activeSystem = this.createActiveAccountSystem();
    }

    this.initialized = true;
  }

  private createActiveAccountSystem() {
    return this.accountSystems.find(
      (a) => a.short === this.selectedAccountSystemShort,
    );
  }

  private createPropertiesSI(properties: Array<ConfigProperty>) {
    this.propertySI = properties.map((prop) => ({
      label: prop.name,
      value: prop.name,
    }));
  }

  public async fetchProperties() {
    try {
      return await this.accountSystemConfigManager.getProperties();
    } catch (e) {
      console.error("Fehler beim Laden von Properties");
    }
  }

  private createDataTableConfig() {
    this.rqlDataSource = new RqlDataSource<AccountSystemPropertyValueRow>(
      this.apiService.baseUrl + "system-value",
      this.httpClient,
    );
    this.dataTableConfig = {
      data: this.rqlDataSource,
      mode: "pagination",
      rows: 25,
      filterArea: {
        filters: [
          {
            header: "Name",
            property: (v) => v.get("accountSystem", "name"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Kürzel",
            property: (v) => v.get("accountSystem", "short"),
            filter: createFilterDefinition(TextFilterComponent, {
              defaultValue: this.selectedAccountSystemShort,
            }),
          },
          {
            header: "Eigenschaft",
            property: (v) => v.get("property", "name"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: this.propertySI,
              defaultValue: this.property,
            }),
          },
          {
            header: "Wert",
            property: (v) => v.get("propertyValue", "value"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
        ],
      },
      columns: [
        {
          header: "Name",
          property: (v) => v.get("accountSystem", "name"),
        },
        {
          header: "Kürzel",
          property: (v) => v.get("accountSystem", "short"),
        },
        {
          header: "Eigenschaft",
          property: (v) => v.get("property", "name"),
        },
        {
          header: "Wert",
          property: (v) => v.get("propertyValue", "value"),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              icon: "info",
              label: "Legende",
              onClick: () => this.overlaySystemValueInfo(),
            },
          ],
        }),
      ],
    };
  }

  private async overlaySystemValueInfo() {
    await this.overlayService.show<SystemValueInfoComponent>({
      type: SystemValueInfoComponent,
      header: "Legende",
      size: OverlaySize.small,
      displayAsSidebar: true,
      actions: [
        { label: "Schließen", displayAsLink: true, action: () => true },
      ],
    });
  }
}
