export function cloneQuillRegistry(registry: object) {
  const properties = Object.getOwnPropertyDescriptors(registry);

  // Clone properties one level deep
  for (const key in properties) {
    if (typeof properties[key].value === "object") {
      properties[key].value = Object.create(
        Object.getPrototypeOf(properties[key].value),
        Object.getOwnPropertyDescriptors(properties[key].value),
      );
    }
  }

  return Object.create(Object.getPrototypeOf(registry), properties);
}
