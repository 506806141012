import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { OrderStatus } from "./order-status.model";
import { OrderTotal } from "./order-total.model";
import { Language } from "./language.model";
import { ReceptionStaff } from "./reception-staff.model";
import { Customer } from "./customer.model";
import { OrderPickUpInfo } from "./order-pick-up-info.model";
import { OrderType } from "./order-type.model";
import { PaymentMethodLang } from "./payment-method-lang.model";
import { OrderProduct } from "./order-product.model";

@DataConversion()
export class Order {
  @Required()
  id: number;

  @Optional()
  orderNr: string;

  @Optional()
  paymentClass: string;

  @Optional()
  datePurchased: Date;

  @Optional()
  @ArrayType(() =>
    import("./order-status.model").then((mod) => mod.OrderStatus),
  )
  orderStatus: OrderStatus[];

  @Optional()
  @ArrayType(() =>
    import("./order-status.model").then((mod) => mod.OrderStatus),
  )
  shippingStatus: OrderStatus[];

  @Optional()
  shippingMethod: string;

  @Optional()
  @ArrayType(() => import("./order-total.model").then((mod) => mod.OrderTotal))
  orderTotals: OrderTotal[];

  @Optional()
  @ArrayType(() => import("./order-type.model").then((mod) => mod.OrderType))
  orderTypes: OrderType[];

  @Optional()
  @ArrayType(() =>
    import("./order-product.model").then((mod) => mod.OrderProduct),
  )
  orderProducts: OrderProduct[];

  @Optional()
  staff: ReceptionStaff;

  @Optional()
  currencyValue: number;

  @Optional()
  language: string;

  @Optional()
  customerTitle: string;
  @Optional()
  customerName: string;
  @Optional()
  customerFirstName: string;
  @Optional()
  customerLastName: string;
  @Optional()
  customerGender: string;
  @Optional()
  customerTelephone: string;
  @Optional()
  customerEmailAddress: string;
  @Optional()
  customerStreetAddress: string;
  @Optional()
  customerPostcode: string;
  @Optional()
  customerCity: string;
  @Optional()
  customerCountry: string;
  @Optional()
  customerCompany: string;
  @Optional()
  customerVatId: string;
  @Optional()
  customerIp: string;

  @Optional()
  deliveryTitle: string;
  @Optional()
  deliveryName: string;
  @Optional()
  deliveryFirstName: string;
  @Optional()
  deliveryLastName: string;
  @Optional()
  deliveryGender: string;
  @Optional()
  deliveryStreetAddress: string;
  @Optional()
  deliveryPostcode: string;
  @Optional()
  deliveryCity: string;
  @Optional()
  deliveryCountry: string;
  @Optional()
  deliveryCompany: string;

  @Optional()
  billingTitle: string;
  @Optional()
  billingName: string;
  @Optional()
  billingFirstName: string;
  @Optional()
  billingLastName: string;
  @Optional()
  billingGender: string;
  @Optional()
  billingStreetAddress: string;
  @Optional()
  billingPostcode: string;
  @Optional()
  billingCity: string;
  @Optional()
  billingCountry: string;
  @Optional()
  billingCompany: string;

  @Optional()
  billingDate: Date;

  @Optional()
  comments: string;

  @Optional()
  customer: Customer;

  @Optional()
  orderPickUpInfo: OrderPickUpInfo;

  @Optional()
  paymentMethodLang: PaymentMethodLang;

  @Optional()
  paymentMethod: string;
}
