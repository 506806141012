import { ArrayType, DataConversion, Required } from "@incert/data-conversion";
import { Language } from "../../models";

@DataConversion()
export class EnvironmentResponse {
  @Required()
  public storeName: string;

  @ArrayType(() =>
    import("../../models/language.model").then((mod) => mod.Language),
  )
  @Required()
  public languages: Language[];

  @Required()
  public currentLanguage: Language;
}
