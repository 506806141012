import { Component, OnInit } from "@angular/core";
import {
  LoadingService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { InfosService } from "./infos.service";
import { TextFileDisplayComponent } from "@incert/incert-gui";

@Component({
  selector: "incert-internal-tools-infos",
  templateUrl: "./infos.component.html",
  styleUrls: ["./infos.component.css"],
})
export class InfosComponent implements OnInit {
  constructor(
    private loadingService: LoadingService,
    private overlayService: OverlayService,
    private infosService: InfosService,
  ) {}

  categories = [];

  async ngOnInit() {
    this.categories = await this.infosService.getAllLibraryEntries();
  }

  overlay(header: string, url: string, textType: string) {
    this.overlayService.show<TextFileDisplayComponent>({
      type: TextFileDisplayComponent,
      displayAsSidebar: true,
      size: OverlaySize.full,
      header: header,
      init: async (component) => {
        component.textRaw = await this.infosService.getGitFile(url);
        component.textType = textType;
        component.header = header;
        component.assignType();
      },
      actions: [
        {
          action: (c) => {
            return c.downloadFile();
          },
          label: "Herunterladen",
        },
        {
          action: (c) => true,
          label: "Schließen",
          displayAsLink: true,
        },
      ],
    });
  }
}
