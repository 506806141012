import { Injectable } from "@angular/core";
import { ApiHttpAccess } from "@incert/gms-core";
import { PersistenceService } from "@incert/incert-gui";

@Injectable({
  providedIn: "root",
})
export class DataTablePersistenceService implements PersistenceService {
  public constructor(private httpAccess: ApiHttpAccess) {}

  async getDataTablePersistence(dataTableId: string) {
    let persistence = await this.httpAccess.get(
      "dataTable/persistence/" + dataTableId,
    );

    if (Array.isArray(persistence) && persistence.length < 1) {
      persistence = {};
    }
    return persistence;
  }

  async updateDataTablePersistence(persistence: any) {
    await this.httpAccess.post("dataTable/persistence", persistence);
  }
}
