import { Type } from "@angular/core";
import { WidgetSize } from "./widget-size.interface";
import { WidgetConfiguration } from "../widget-configuration/widget-configuration.interface";

export const widgetPropertiesKey = "widgetProperties";
export type SimpleWidgetConfigurationProperty = Type<WidgetConfiguration>;

export interface DetailWidgetConfigurationProperty<T> {
  configuration: Type<T>;
  property: Partial<T>;
}

export function isDetailWidgetConfigurationProperty(
  property: WidgetConfigurationProperty<any>,
): property is DetailWidgetConfigurationProperty<any> {
  return (
    (property as DetailWidgetConfigurationProperty<any>).property !== undefined
  );
}

export type WidgetConfigurationProperty<T> =
  | SimpleWidgetConfigurationProperty
  | DetailWidgetConfigurationProperty<T>;

export interface WidgetProperties<T> {
  id: string;
  title: string;
  type?: string;
  defaultWidgetSize?: WidgetSize;
  minWidgetSize?: WidgetSize;
  maxWidgetSize?: WidgetSize;
  configurations?: WidgetConfigurationProperty<T>[];
  disablePrint?: boolean;
  isEtrackerWidget?: boolean;
}
