import {
  ArrayType,
  DataConversion,
  DateConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { CustomerStatus } from "./customer-status";
import { AddressBookModel } from "./address-book.model";
import { EventProductAssignment } from "./event-product-assignment.model";
import { EventWaitingList } from "./event-waiting-list.model";

@DataConversion()
export class Customer {
  @Required()
  id: number;
  @Required()
  firstName: string;
  @Required()
  lastName: string;
  @Required()
  gender: string;
  @Required()
  dob: string;
  @Required()
  email: string;
  @Required()
  telephone: string;
  @Required()
  language: string;
  @Optional()
  defaultAddressBookId: number;

  @Optional()
  customerStatus: CustomerStatus;

  @Optional()
  defaultAddress: AddressBookModel;

  @Optional()
  @ArrayType(() =>
    import("./event-product-assignment.model").then(
      (mod) => mod.EventProductAssignment,
    ),
  )
  eventProductAssignments: EventProductAssignment[];

  @Optional()
  @ArrayType(() =>
    import("./event-waiting-list.model").then((mod) => mod.EventWaitingList),
  )
  eventWaitingLists: EventWaitingList[];
}
