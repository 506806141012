import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Injectable()
export class DashboardFilterService {
  private _state = {};
  private _filterChange$: ReplaySubject<any>;
  private timeoutRef: any;

  public get state() {
    return { ...this._state };
  }

  public get filterChange$() {
    return this._filterChange$.asObservable();
  }

  public constructor() {
    this._filterChange$ = new ReplaySubject();
  }

  public updateState(state: any) {
    this._state = {
      ...this._state,
      ...state,
    };
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
    this.timeoutRef = setTimeout(() => {
      this._filterChange$.next(this._state);
    }, 400);
  }

  subscribeOnFilterChange(
    DashboardFilterComponent: any,
    param2: (dashboardFilter) => Promise<void>,
  ) {}
}
