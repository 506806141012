import {
  ArrayType,
  DataConversion,
  DateConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { EventDate } from "./index";

@DataConversion()
export class EventSequence {
  @Required()
  id: number;

  @Optional()
  externalId: number | string;

  @Required()
  name: string;

  @Optional()
  description: string;

  @Optional()
  @DateConversion()
  start: Date;

  @Optional()
  duration: number;

  @Optional()
  saleStart: Date;

  @Optional()
  saleEnd: Date;

  @Optional()
  categoryId: number;

  @Optional()
  location: string;

  @Optional()
  address: string;

  @Optional()
  quota: number;

  @Optional()
  waitingListEnabled: boolean;

  @Optional()
  productSeasonId: number;

  @Optional()
  notSaleableOnline: boolean;

  @Optional()
  @ArrayType(() => import("./event-date.model").then((mod) => mod.EventDate))
  eventDates: EventDate[];

  @Optional()
  image?: string;

  public getEnd() {
    let endTime = new Date(this.start).getTime();
    endTime = endTime + this.duration * 1000;
    return new Date(endTime);
  }
}
