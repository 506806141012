import { DataConversion, Optional } from "@incert/data-conversion";
import { FrontendSchemaConfig } from "@incert/gms/src/lib/frontend/shared/models/frontend-schema-config.model";

@DataConversion()
export class FrontendTheme {
  @Optional() app: string;
  @Optional() themeId = "";
  @Optional() releaseVersion = "";
  @Optional() css: any = null;
  @Optional() rawConfig: FrontendSchemaConfig | null = null;
  @Optional() componentConfig: any = null;
  @Optional() pageConfig: any = null;
  @Optional() globalConfig: any = null;
}
