import { DataConversion, Required, Optional } from "@incert/data-conversion";
import { Language } from "./language.model";

@DataConversion()
export class ProductOption {
  @Required()
  id: number;

  @Optional()
  language?: Language;

  @Required()
  name: string;

  @Optional()
  sortOrder: string;

  @Required()
  countSubElements: number;
}
