import { Component, OnInit } from "@angular/core";
import { NotificationManagerService } from "./notification-manager.service";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  DateFilterComponent,
  SelectFilterComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { Notification, NotificationPermission } from "./notification.interface";
import {
  ConfirmService,
  LoadingService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { AddNotificationComponent } from "./add-notification/add-notification.component";
import { AccountSystem } from "../../../core/models/account-system.interface";
import { AccountSystemManager } from "../../account-system/account-system-manager.service";
import { AuthManager } from "../../../core/auth";
import { Utils } from "../../../core/utils";
import { SelectItem } from "primeng/api";
import { DiscrEnum } from "../../../core/enums/discr.enum";
import { InstallationStatusEnum } from "../../../core/enums/installation-status.enum";
import { AccountSystemGroupManager } from "../../account-system-group/account-system-group-manager.service";
import { AccountSystemGroup } from "../../account-system-group/account-system-group";

@Component({
  selector: "incert-internal-tools-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
  public notifications: Array<Notification>;
  public notificationPermissions: Array<NotificationPermission>;
  public notificationConfig: DataTableConfig<Notification>;
  public notificationBehaviorsSI: Array<SelectItem>;
  public notificationCategoriesSI: Array<SelectItem>;
  public accountSystems: Array<AccountSystem>;
  public arrayDataSource: ArrayDataSource<Notification>;
  public searchString: string;
  accountSystemGroups: AccountSystemGroup[];

  constructor(
    private notificationManager: NotificationManagerService,
    private accountSystemManager: AccountSystemManager,
    private loadingService: LoadingService,
    private overlayService: OverlayService,
    private authManager: AuthManager,
    private confirmService: ConfirmService,
    private accountSystemGroupManager: AccountSystemGroupManager,
  ) {}

  async ngOnInit() {
    await this.initialize();
    this.accountSystemGroups =
      await this.accountSystemGroupManager.getAccountSystemGroups();
  }

  private async initialize() {
    await this.loadingService.load(async () => {
      this.notifications = await this.notificationManager.getNotifications();
      this.notificationCategoriesSI =
        await this.notificationManager.getNotificationCategories();
      this.notificationPermissions =
        await this.notificationManager.getNotificationPermissions();
      this.notificationBehaviorsSI =
        await this.notificationManager.getNotificationBehaviors();
      this.accountSystems =
        await this.accountSystemManager.getAccountSystemsGeneral(
          "?discr[]=gms&discr[]=" +
            DiscrEnum.ecom +
            "&installationStatus[]=" +
            InstallationStatusEnum.active +
            "&installationStatus[]=" +
            InstallationStatusEnum.productive,
        );
      this.createDtTable();
    });
  }

  private createDtTable() {
    for (const notification of this.notifications) {
      notification.translationsStr = notification.translations
        .map((v) => v.language)
        .join(", ");
      notification.permissionsStr = notification.permissions
        .map((v) => v.name)
        .join(", ");
    }
    this.arrayDataSource = new ArrayDataSource<Notification>(
      this.notifications,
    );
    this.notificationConfig = {
      data: this.arrayDataSource,
      rows: 30,
      columnEdit: true,
      mode: "pagination",
      filterArea: {
        filters: [
          {
            header: "Titel",
            property: (v) => v.get("translations", 0, "title"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Kategorie",
            property: (v) => v.get("category"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: this.notificationCategoriesSI,
            }),
          },
          {
            header: "Verhalten",
            property: (v) => v.get("behavior"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: this.notificationBehaviorsSI,
            }),
          },
          {
            header: "Aktiv ab",
            property: (v) => v.get("startDate"),
            filter: createFilterDefinition(DateFilterComponent, {
              type: "between",
            }),
          },
          {
            header: "Endet am",
            property: (v) => v.get("endDate"),
            filter: createFilterDefinition(DateFilterComponent, {
              type: "between",
            }),
          },
        ],
      },
      columns: [
        {
          header: "Titel",
          property: (v) => v.get("translations", 0, "title"),
        },
        {
          header: "Beschreibung",
          property: (v) => v.get("translations", 0, "text"),
          hidden: true,
        },
        {
          header: "Kategorie",
          property: (v) => v.get("category"),
          sort: true,
        },
        {
          header: "Verhalten",
          property: (v) => v.get("behavior"),
          sort: true,
        },
        {
          header: "Aktiv ab",
          property: (v) => v.get("startDate"),
          transform: (v) => (v ? Utils.getFormattedDateTime(v) : ""),
          sort: true,
        },
        {
          header: "Endet am",
          property: (v) => v.get("endDate"),
          transform: (v) => (v ? Utils.getFormattedDateTime(v) : ""),
          sort: true,
        },
        {
          header: "Aktionen",
          hidden: !this.writeNotification,
          property: (v) => v.get("id"),
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "edit",
                  hidden: !this.writeNotification,
                  onClick: (row: Notification) =>
                    this.showAddNotificationOverlay(row),
                  tooltip: "Bearbeiten",
                },
                {
                  icon: "delete",
                  hidden: !this.executeNotification,
                  onClick: (row: Notification) => this.deleteNotification(row),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              icon: "plus",
              label: "Notification",
              onClick: () => this.showAddNotificationOverlay(),
              onShow: () => this.writeNotification,
            },
          ],
        }),
      ],
    };
  }

  private async deleteNotification(notification: Notification) {
    const response = await this.confirmService.confirmError(
      "",
      "Notification löschen",
    );
    if (response) {
      await this.notificationManager.deleteNotification(notification.id);
      await this.ngOnInit();
    }
  }

  public async showAddNotificationOverlay(notification?: Notification) {
    await this.overlayService.show<AddNotificationComponent>({
      type: AddNotificationComponent,
      header: notification
        ? "Notification bearbeiten"
        : "Notification erstellen",
      displayAsSidebar: true,
      init: (c) => {
        if (notification) {
          c.notification = notification;
        }
        c.notificationPermissions = this.notificationPermissions;
        c.notificationBehaviors = this.notificationBehaviorsSI;
        c.notificationCategoriesSI = this.notificationCategoriesSI;
        c.accountSystemGroups = this.accountSystemGroups;
      },
      size: OverlaySize.large,
      actions: [
        {
          buttonType: "primary",
          label: "Speichern",
          action: async (component) => {
            const res = await component.upsertNotification();
            if (res) {
              await this.initialize();
              return true;
            }
            return false;
          },
        },
        {
          displayAsLink: true,
          label: "Abbrechen",
          action: () => true,
        },
      ],
    });
  }

  public get writeNotification(): boolean {
    return this.authManager.hasPermission("writeNotification");
  }

  public get executeNotification(): boolean {
    return this.authManager.hasPermission("executeNotification");
  }
}
