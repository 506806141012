import { dashboardI18n } from "./dashboard-i18n.const";
import { InjectionToken } from "@angular/core";

export type DashboardI18n = typeof dashboardI18n;
export const DASHBOARD_I18N = new InjectionToken<DashboardI18n>(
  "DASHBOARD_I18N",
  {
    providedIn: "root",
    factory() {
      return dashboardI18n;
    },
  },
);
