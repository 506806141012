import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Language } from "./language.model";

@DataConversion()
export class OpeningTimeDescription {
  @Optional()
  description: string;

  @Required()
  name: string;

  @Required()
  language: Language;
}
