import { Injectable } from "@angular/core";
import { I18nLoaderInterface } from "@incert/i18n";
import { HttpAccess } from "@incert/incert-core";
import { EnvironmentService } from "../services";

@Injectable({
  providedIn: "root",
})
export class GMSI18nLoader implements I18nLoaderInterface {
  public constructor(
    private httpAccess: HttpAccess,
    private environmentService: EnvironmentService,
  ) {}

  async load(path: string, language: string) {
    return await this.httpAccess.get(
      "https://resources.myincert.com/i18n/" +
        path +
        "/" +
        language +
        ".json?override=" +
        this.environmentService.environment.partId,
    );
  }
}
