import { Required, DataConversion } from "@incert/data-conversion";

@DataConversion()
export class SimpleCurrencyPrice {
  @Required()
  currencyId: number;

  @Required()
  price: number;
}
