<form [formGroup]="formGroup">
  <div class="grid">
    <form-field label="Kürzel" class="col-6">
      <input [formControlName]="nameofForm('short')" type='text' placeholder="Kürzel">
    </form-field>
    <div class="col-6" [formGroup]="resourceType">
      <form-field label="Typ">
        <p-dropdown
          [formControlName]="nameofFormCategory('type')"
          [options]="typeSI"
          (onChange)="changeResourceType()"
          placeholder="Typ"
          appendTo="body">
        </p-dropdown>
      </form-field>
    </div>
  </div>

  <h1>Daten</h1>
  <div *ngFor="let resourceData of resourceDataArray.controls; let i = index" formArrayName="resourceDataArray">
    <div class="box-entry separator-bottom" [formGroupName]="i">
      <div class="box-entry-content">
        <div class="box-entry-left">
          <form-field label="Sprache">
            <p-dropdown
              [options]="languageSI"
              placeholder="Sprache wählen"
              appendTo="body"
              formControlName="language">
            </p-dropdown>
          </form-field>
          <div class="grid">
            <form-field class="col-4" label="Bezeichnung">
              <input [formControlName]="nameofFormData('name')" type='text' placeholder="Bezeichnung">
            </form-field>
            <form-field class="col-4" label="Alt">
              <input [formControlName]="nameofFormData('alt')" type='text' placeholder="Alt">
            </form-field>
            <form-field class="col-4" label="Copyright">
              <input [formControlName]="nameofFormData('copyright')" type='text' placeholder="Copyright">
            </form-field>
          </div>
          <form-field label="Inhalt">
            <ng-container *ngIf="resourceTypeGroupCategory === 'file'">
              <p-inputSwitch [formControlName]="nameofFormData('groupCategoryOverwrite')" [trueValue]="'text'" [falseValue]="''"></p-inputSwitch>
              <ng-container *ngIf="resourceData?.value?.data && (resourceTypeGroupCategoryOverwrite(i) === '')">
                <icon-button fullWidth="true" buttonType="secondary" [label]="resourceData.value.data"
                             (click)="openResource(resourceData.value.data)"></icon-button>
              </ng-container>
              <ng-container *ngIf="!resourceData?.value?.data && (resourceTypeGroupCategoryOverwrite(i) === '')">
                <inc-file-upload
                  resetStyles="true"
                  [maxNumberOfFiles]="1"
                  [fileType]="fileType"
                  [formControlName]="nameofFormData('fileData')">
                </inc-file-upload>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="resourceTypeGroupCategory === 'text' || (resourceTypeGroupCategoryOverwrite(i) === 'text')">
              <input [formControlName]="nameofFormData('data')" type='text' placeholder="Inhalt">
            </ng-container>
          </form-field>
        </div>
        <div class="box-entry-right">
          <icon-button iconOnly='true' iconType="close" (click)="deleteResourceData(i)"
                       displayIconButtonAsButton="true">
          </icon-button>
        </div>
      </div>
    </div>
  </div>
  <div class="align-right">
    <icon-button iconType="plus" (click)="addResourceData()" label="Übersetzung"></icon-button>
  </div>
</form>
