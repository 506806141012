/* class decorator */
import {
  WidgetProperties,
  widgetPropertiesKey,
} from "./interface/widget-properties.interface";
import { Type } from "@angular/core";

export function Widget<T>(widgetProperties: WidgetProperties<T>) {
  /* tslint:disable:only-arrow-functions */
  return function (target: any) {
    target[widgetPropertiesKey] = widgetProperties;
    return target;
  };
}

export function getWidgetProperties(
  component: Type<any>,
): WidgetProperties<any> {
  if (widgetPropertiesKey in component) {
    return component[widgetPropertiesKey] as WidgetProperties<any>;
  }
}
