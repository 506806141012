import { Injectable } from "@angular/core";
import { WidgetProperties } from "@incert/dashboard";
import { WidgetImageResolverInterface } from "./widget-image-resolver.interface";

@Injectable({
  providedIn: "root",
})
export class DefaultWidgetImageResolverService
  implements WidgetImageResolverInterface
{
  resolveImage(id: string, name: string, properties: WidgetProperties<any>) {
    return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==";
  }
}
