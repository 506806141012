import { Injectable } from "@angular/core";
import { ApiHttpAccess } from "../services";
import { parseRqlQuery, RQLQuery } from "@incert/incert-core";

export interface EventFilterModel {
  eventDateId: number;
  eventDateName: number;
  eventDateStart: string;
  productId: number;
  price: number;
  productName: string;
  available: number;
  categoryName1: string;
  categoryId1: number;
  categoryName2: string;
  categoryId2: string;
}

@Injectable({
  providedIn: "root",
})
export class EventDaoService {
  public constructor(private httpAccess: ApiHttpAccess) {}

  async filter(query: RQLQuery<EventFilterModel>): Promise<any> {
    let url = "event/filter";
    if (query) {
      url += "?q=" + parseRqlQuery(query);
    }
    return this.httpAccess.get(url);
  }
}
