import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { I18nService } from "@incert/i18n";
import { loadScript, OverlayService, resolveAsset } from "@incert/incert-core";
import { SourceViewOverlayComponent } from "./source-view-overlay/source-view-overlay.component";
import {
  matchMsTitle,
  matchMsWordList,
  maybeMatchMsWordList,
  validateQuillEditorValue,
} from "./quill-editor.function";
import { OverlayPanel } from "primeng/overlaypanel";
import { createQuillTagBlot } from "./create-quill-tag-blot.function";
import { cloneQuillRegistry } from "./clone-quill-registry.function";
import { QuillOptions } from "quill";
import {
  transformQuillInput,
  transformQuillOutput,
} from "./quill-editor-transformer.function";
declare const Quill: any;
declare const quillBetterTable: any;

export interface QuillEditorMediaLibraryOpen {
  openMediaLibrary(): Promise<number>;
}

@Component({
  selector: "incert-quill-editor",
  templateUrl: "./quill-editor.component.html",
  styleUrls: ["./quill-editor.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillEditorComponent),
      multi: true,
    },
  ],
})
export class QuillEditorComponent
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  @Input() config: QuillOptions = null;
  @Input() formControlName: string | undefined;
  @Input() height: string | undefined;
  @Input() mediaLibrary: QuillEditorMediaLibraryOpen | undefined;

  @ViewChild("editor") editor: ElementRef;
  @ViewChild("toolbar") toolbar: ElementRef;
  @ViewChild("op") op: OverlayPanel;

  tableModule: any;
  quill: any | null = null;

  private _value: string;
  private _onChange = (value: any) => {};
  private _onTouch = (value: any) => {};

  i18nLoaded = false;
  quillLoaded = false;

  addTableRows = Array.from({ length: 10 }).map((v, i) => i);
  addTableColumns = Array.from({ length: 10 }).map((v, i) => i);
  addTableState: { row: number; column: number } = {
    row: 0,
    column: 0,
  };

  private _attemptsToInitialize = 0;
  static readonly MAX_INIT_TIMEOUT = 3000;
  static readonly INIT_TIMEOUT = 50;

  constructor(
    public i18n: I18nService,
    public overlayService: OverlayService,
  ) {
    // todo: refactor
    this.i18n.load("gms/frontend");
    this.i18n.load("gms/frontend/mediaLibrary");
  }

  async ngOnInit() {
    await this.i18n.loading;
    this.i18nLoaded = true;
  }

  async ngAfterViewInit() {
    await loadScript(resolveAsset("assets/quill/quill.js"));
    await loadScript(resolveAsset("assets/quill/quill.snow.css"));
    await loadScript(
      resolveAsset("assets/quill-better-table/quill-better-table.js"),
    );
    await loadScript(
      resolveAsset("assets/quill-better-table/quill-better-table.css"),
    );
    await loadScript(
      resolveAsset("assets/quill-better-table/quill-better-table.css"),
    );
    Quill.register(
      {
        "modules/better-table": quillBetterTable,
      },
      true,
    );
    this.addLinkSanitizer();
    this.quillLoaded = true;
    this.initQuillEditor();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  writeValue(value: any): void {
    const str = transformQuillInput(
      validateQuillEditorValue(typeof value === "string" ? value : ""),
    );
    this._value = str;
    this.setQuillHTML(str);
  }

  handleClickAddTable(i, ii) {
    this.tableModule.insertTable(i + 1, ii + 1);
    this.op.hide();
  }

  updateAddTableState(i, ii) {
    this.addTableState = { row: i, column: ii };
  }

  viewSource() {
    const html = this.quill.root.innerHTML;
    this.overlayService.show<SourceViewOverlayComponent>({
      type: SourceViewOverlayComponent,
      header: this.i18n.instant("gms.frontend.overlay.sourceCodeEditor.header"),
      init: (comp: SourceViewOverlayComponent) => {
        comp.source = transformQuillOutput(validateQuillEditorValue(html));
      },
      actions: [
        {
          action: (comp) => {
            this.writeValue(comp.source);
            return true;
          },
          label: this.i18n.instant("core.action.save"),
        },
      ],
    });
  }

  async selectFile() {
    if (!this.mediaLibrary) {
      return;
    }

    const result = await this.mediaLibrary.openMediaLibrary();
    if (result) {
      const range = this.quill.getSelection(true);
      this.quill.insertEmbed(
        range.index,
        "tag",
        `#file|${result}#`,
        Quill.sources.USER,
      );
      this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
    }
  }

  setQuillHTML(value: string) {
    if (this.quill) {
      const delta = this.quill.clipboard.convert({ html: value });
      this.quill.setContents(delta, "silent");
    }
  }

  initQuillEditor(): void {
    if (
      this.editor &&
      this.editor.nativeElement &&
      this.quill === null &&
      this.toolbar
    ) {
      if (this.config === null) {
        this.config = this.createDefaultConfig(this.toolbar.nativeElement);
      }

      const registry = cloneQuillRegistry(
        this.config.registry ?? Quill.DEFAULTS.registry,
      );

      registry.register(
        createQuillTagBlot(Quill, { begin: "{", end: "}", tagName: "div" }),
      );

      const config = { ...this.config };
      config.registry = registry;

      this.quill = new Quill(this.editor.nativeElement, config);
      this.tableModule = this.quill.getModule("better-table");

      // Add rules to Quill to convert MS-Word texts by copy pasting
      // Rules to convert ordered and unordered lists
      this.quill.clipboard.addMatcher(
        "p.MsoListParagraphCxSpFirst",
        matchMsWordList,
      );
      this.quill.clipboard.addMatcher(
        "p.MsoListParagraphCxSpMiddle",
        matchMsWordList,
      );
      this.quill.clipboard.addMatcher(
        "p.MsoListParagraphCxSpLast",
        matchMsWordList,
      );
      this.quill.clipboard.addMatcher("p.MsoListParagraph", matchMsWordList);
      this.quill.clipboard.addMatcher("p.msolistparagraph", matchMsWordList);
      this.quill.clipboard.addMatcher("p.MsoNormal", maybeMatchMsWordList);
      // Rule to convert Title
      this.quill.clipboard.addMatcher("p.MsoTitle", matchMsTitle);
      // set initial value
      if (this._value) {
        this.setQuillHTML(this._value);
      }
      this.quill.on("editor-change", (texAndSelection, ...args) => {
        this._onChange(
          transformQuillOutput(
            validateQuillEditorValue(this.quill.root.innerHTML),
          ),
        );
      });
      this._onChange(
        transformQuillOutput(
          validateQuillEditorValue(this.quill.root.innerHTML),
        ),
      );
    } else {
      if (
        QuillEditorComponent.MAX_INIT_TIMEOUT >
        this._attemptsToInitialize * QuillEditorComponent.INIT_TIMEOUT
      ) {
        this._attemptsToInitialize++;
        setTimeout(() => {
          this.initQuillEditor();
        }, QuillEditorComponent.INIT_TIMEOUT);
      }
    }
  }

  private addLinkSanitizer() {
    const Link = Quill.import("formats/link");
    if (Link.isCustom) {
      return;
    }

    class CustomLinkSanitizer extends Link {
      public static isCustom = true;

      static sanitize(url) {
        const sanitizedUrl = super.sanitize(url);

        if (!sanitizedUrl || sanitizedUrl === "about:blank") {
          return sanitizedUrl;
        }

        if (sanitizedUrl.startsWith("/")) {
          return sanitizedUrl;
        }

        if (QuillEditorComponent.tryParseURL(sanitizedUrl)) {
          return sanitizedUrl;
        }

        if (QuillEditorComponent.tryParseURL("https://" + sanitizedUrl)) {
          return "https://" + sanitizedUrl;
        }

        return sanitizedUrl;
      }
    }

    Quill.register(CustomLinkSanitizer, true);
  }

  private static tryParseURL(url) {
    try {
      return new URL(url);
    } catch (e) {
      return null;
    }
  }

  private createDefaultConfig = (toolbar): QuillOptions => {
    return {
      theme: "snow",
      modules: {
        table: false, // disable table module
        "better-table": {
          operationMenu: {
            items: {
              insertColumnRight: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.insertColumnRight",
                ),
              },
              insertColumnLeft: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.insertColumnLeft",
                ),
              },
              insertRowUp: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.insertRowUp",
                ),
              },
              insertRowDown: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.insertRowDown",
                ),
              },
              mergeCells: {
                text: this.i18n.instant("gms.frontend.quillOptions.mergeCells"),
              },
              unmergeCells: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.unmergeCells",
                ),
              },
              deleteColumn: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.deleteColumn",
                ),
              },
              deleteRow: {
                text: this.i18n.instant("gms.frontend.quillOptions.deleteRow"),
              },
              deleteTable: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.deleteTable",
                ),
              },
            },
            color: {
              colors: ["#fff", "red", "rgb(0, 0, 0)"], // colors in operationMenu
              text: "Background Colors", // subtitle
            },
          },
        },
        keyboard: {
          bindings: quillBetterTable.keyboardBindings,
        },
        toolbar: toolbar ?? [
          [{ size: [false, "huge", "large", "small"] }], // custom dropdown
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [
            {
              align: [
                { align: "" },
                { align: "center" },
                { align: "right" },
                { align: "justify" },
              ],
            },
          ],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          ["clean"], // remove formatting button,
        ],
      },
    };
  };
}
