import { ReplaySubject } from "rxjs";
import { ApiHttpAccess } from "./http/api-http-access.service";
import { Injectable } from "@angular/core";
import { CountryListEntry } from "../models/country-list-entry.model";

@Injectable({ providedIn: "root" })
export class CountryService {
  private _countryListEntries$ = new ReplaySubject<CountryListEntry[]>(1);
  private _countryListEntriesInit = false;

  public constructor(private httpAccess: ApiHttpAccess) {}

  public refreshCountryListEntries() {
    this.httpAccess
      .get<CountryListEntry[]>("countries/active", CountryListEntry)
      .then((v) => this._countryListEntries$.next(v));
  }

  public get countryListEntries$() {
    if (!this._countryListEntriesInit) {
      this.refreshCountryListEntries();
      this._countryListEntriesInit = true;
    }
    return this._countryListEntries$.asObservable();
  }
}
