import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class ZendeskArticleModel {
  @Required()
  public title: string;

  @Required()
  public url: string;

  @Required()
  public body: string;
}
