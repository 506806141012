import { Injectable } from "@angular/core";
import { firstValueFrom, ReplaySubject } from "rxjs";
import { ApiHttpAccess, EventSequence } from "@incert/gms-core";
import { SelectItem } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class EventSequenceService {
  private _eventSequence$?: ReplaySubject<EventSequence[]>;

  constructor(private apiHttpAccess: ApiHttpAccess) {}

  get eventSequence$() {
    if (!this._eventSequence$) {
      this._eventSequence$ = new ReplaySubject<EventSequence[]>(1);
      this.apiHttpAccess
        .get<EventSequence[]>("event/eventSequenceList", EventSequence)
        .then((eventSequence) => this._eventSequence$.next(eventSequence));
    }
    return this._eventSequence$.asObservable();
  }

  public async getEventSequenceSI(): Promise<SelectItem[]> {
    return (await firstValueFrom(this.eventSequence$)).map((es) => {
      return {
        label: es.name,
        value: es.id,
      };
    });
  }
}
