import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { LoginCustomerNameEntry } from "../models";
import { ApiHttpAccess } from "./http/api-http-access.service";

@Injectable({ providedIn: "root" })
export class LoginCustomerNameService {
  private _loginCustomerNameEntries$ = new ReplaySubject<
    LoginCustomerNameEntry[]
  >(1);
  private _loginCustomerNameEntriesInit = false;

  public constructor(private httpAccess: ApiHttpAccess) {}

  public refreshLoginCustomerNameEntries() {
    this.httpAccess
      .get<
        LoginCustomerNameEntry[]
      >("admin/permissionManagement/customer", LoginCustomerNameEntry)
      .then((v) => this._loginCustomerNameEntries$.next(v));
  }

  public get loginCustomerNameEntries$() {
    if (!this._loginCustomerNameEntriesInit) {
      this.refreshLoginCustomerNameEntries();
      this._loginCustomerNameEntriesInit = true;
    }
    return this._loginCustomerNameEntries$.asObservable();
  }
}
