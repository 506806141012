import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { ProductDescription } from "./product-description.model";
import { ProductImageAltText } from "./product-image-alt-text.model";
import { Manufacturer } from "./manufacturer.model";
import { TaxClass } from "./tax-class.model";
import { ProductImages } from "./product-images.model";

@DataConversion()
export class Product {
  @Required()
  id: number;

  @Optional()
  articleMaxPrice: number;

  @Optional()
  articleMinPrice: number;

  @Optional()
  thumbnailImageLink: string;

  @Optional()
  price: number;

  @Optional()
  @ArrayType(() =>
    import("./product-description.model").then((mod) => mod.ProductDescription),
  )
  productDescriptions: ProductDescription[];

  @Optional()
  @ArrayType(() =>
    import("./product-images.model").then((mod) => mod.ProductImages),
  )
  productImages: ProductImages[];

  @Optional()
  @ArrayType(() =>
    import("./product-image-alt-text.model").then(
      (mod) => mod.ProductImageAltText,
    ),
  )
  productImageAltTexts: ProductImageAltText[];

  @Optional()
  type: string;

  @Optional()
  manufacturer: Manufacturer;

  @Optional()
  status: number;

  @Optional()
  taxClass: TaxClass;

  @Optional()
  category: string;

  @Optional()
  startpage: boolean;

  @Optional()
  startpageSort: number;

  @Optional()
  checkStock: boolean;

  @Optional()
  quantity: boolean;

  @Optional()
  daysValidThru: number;

  @Optional()
  displayBuyNowBtn: boolean;

  // public getProductDescriptionByLanguageId(languageId: number) {
  //     return this.productDescriptions.find(v => v.language.id == languageId);
  // }
}
