import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { MetaTagDescription } from "./metatag-description.model";
import { Category } from "./category.model";

@DataConversion()
export class MetaTag {
  @Optional()
  id: number;

  @Optional()
  name: string;

  @Optional()
  isActive: boolean;

  @Optional()
  subShop: Category;

  @Optional()
  dateAdded: Date;

  @Optional()
  lastModified: Date;

  @Optional()
  isSystemValue: boolean;

  @Optional()
  @ArrayType(() =>
    import("./metatag-description.model").then((mod) => mod.MetaTagDescription),
  )
  metaTagDescriptions: MetaTagDescription[];
}
