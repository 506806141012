import { DataConversion, Optional, Required } from "@incert/data-conversion";

@DataConversion()
export class Tag {
  @Required()
  id: number;

  @Optional()
  name: string;
}
