import { Injectable } from "@angular/core";
import {
  ApiHttpAccess,
  EmailResponse,
  VoucherDownloadHistoryModel,
} from "@incert/gms-core";
import { OrderEmailHistoryModel } from "../models/order-email-history.model";

@Injectable({ providedIn: "root" })
export class OrderEmailService {
  constructor(private apiHttpAccess: ApiHttpAccess) {}

  async getOrderEmailHistoryForOrder(
    orderId: number,
  ): Promise<OrderEmailHistoryModel[]> {
    return await this.apiHttpAccess.get<OrderEmailHistoryModel[]>(
      `order/${orderId}/orderMailHistory`,
      OrderEmailHistoryModel,
    );
  }

  async resendOrderEmailHistory(
    id: number,
    emailAddress?: string,
  ): Promise<EmailResponse> {
    return await this.apiHttpAccess.post(`orderMailHistory/${id}/resend`, {
      emailAddress,
    });
  }

  async getVoucherDownloadHistory(orderId: number) {
    return await this.apiHttpAccess.get<VoucherDownloadHistoryModel[]>(
      `order/${orderId}/downloadHistory`,
      VoucherDownloadHistoryModel,
    );
  }

  async deleteOrderEmailHistory(id: number) {
    await this.apiHttpAccess.delete(`orderMailHistory/${id}`);
  }
}
