import { ArrayType, DataConversion, Optional } from "@incert/data-conversion";
import { AccessGroupModel } from "./access-group.model";
import { AccessLevelModel } from "./access-level.model";

@DataConversion()
export class Manufacturer {
  @Optional()
  id: number;
  @Optional()
  public name: string;
  @Optional()
  public shopId: string;
  @Optional()
  public publicApiKey: string;
  @Optional()
  @ArrayType(() =>
    import("./access-group.model").then((mod) => mod.AccessGroupModel),
  )
  public accessGroups: AccessGroupModel[];
  @Optional()
  @ArrayType(() =>
    import("./access-level.model").then((mod) => mod.AccessLevelModel),
  )
  public accessLevels: AccessLevelModel[];
}
