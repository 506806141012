import { Component, OnInit } from "@angular/core";
import { createNameof, NotificationService } from "@incert/incert-core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { markAsDirty, modelToForm } from "@incert/incert-gui";
import {
  AccountSystemFilter,
  AccountSystemGroup,
} from "../account-system-group";
import { AccountSystemManager } from "../../account-system/account-system-manager.service";
import { AccountSystemGroupManager } from "../account-system-group-manager.service";
import { SelectItem } from "primeng/api";

@Component({
  selector: "incert-account-system-group-upsert",
  templateUrl: "./account-system-group-upsert.component.html",
  styleUrls: ["./account-system-group-upsert.component.css"],
})
export class AccountSystemGroupUpsertComponent implements OnInit {
  nameofForm = createNameof<AccountSystemGroup>();
  formGroup: UntypedFormGroup;
  accountSystemSI: SelectItem[] = [];
  accountSystemFilters: AccountSystemFilter[] = [];
  systemGroup: AccountSystemGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private accountSystemManager: AccountSystemManager,
    private accountSystemGroupManager: AccountSystemGroupManager,
    private notificationService: NotificationService,
  ) {
    this.formGroup = this.fb.group(
      modelToForm<AccountSystemGroup>({
        id: [""],
        name: ["", [Validators.required]],
        accountSystemFilter: [null],
        accountSystems: [null, [Validators.required]],
      }),
    );
  }

  async ngOnInit() {
    if (this.systemGroup) {
      this.formGroup.patchValue(this.systemGroup);
      this.formGroup
        .get(this.nameofForm("accountSystemFilter"))
        .patchValue(this.systemGroup?.accountSystemFilter?.id);
      await this.getAccountSystems();
      this.formGroup
        .get(this.nameofForm("accountSystems"))
        .patchValue(
          (
            await this.accountSystemManager.getAccountSystemsByGroup(
              this.systemGroup,
            )
          ).map((v) => v.id),
        );
    }
  }

  async upsertSystemGroup() {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      const accountSystemGroup: AccountSystemGroup = this.formGroup.value;
      accountSystemGroup.accountSystemsCustom = [];
      if (this.formGroup.get(this.nameofForm("accountSystemFilter")).value) {
        for (const accountSystem of this.accountSystemSI) {
          if (
            !this.formGroup
              .get(this.nameofForm("accountSystems"))
              .value.includes(accountSystem.value)
          ) {
            accountSystemGroup.accountSystemsCustom.push({
              id: accountSystem.value,
            });
          }
        }
      } else {
        accountSystemGroup.accountSystemsCustom = this.formGroup
          .get(this.nameofForm("accountSystems"))
          .value.map((v: string) => {
            return {
              id: v,
            };
          });
      }
      accountSystemGroup.accountSystemFilter = {
        id: this.formGroup.get(this.nameofForm("accountSystemFilter")).value,
      };
      if (
        await this.accountSystemGroupManager.upsertAccountSystemGroup(
          accountSystemGroup,
        )
      ) {
        this.notificationService.notifySuccess(
          this.systemGroup
            ? "Kontaktgruppe bearbeitet"
            : "Kontaktgruppe hinzugefügt",
        );
        return true;
      }
      this.notificationService.notifyError(
        this.systemGroup
          ? "Kontaktgruppe konnte nicht bearbeitet werden"
          : "Kontaktgruppe konnte nicht hinzugefügt werden",
      );
    }
    return false;
  }

  async changeFilter() {
    await this.getAccountSystems();
    await this.resetAccountSystems();
  }

  async getAccountSystems() {
    this.accountSystemSI =
      await this.accountSystemManager.getAccountSystemsByFilterSI(
        this.formGroup.get(this.nameofForm("accountSystemFilter")).value,
      );
  }

  async resetAccountSystems() {
    this.formGroup
      .get(this.nameofForm("accountSystems"))
      .patchValue(this.accountSystemSI.map((v) => v.value));
  }
}
