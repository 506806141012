import { Injectable } from "@angular/core";
import { ApiHttpAccess } from "./http/api-http-access.service";
import { CurrencyPrice, OptionValueCurrencyPrice } from "../models";
import { SimpleCurrencyPrice } from "../models/simple-currency-price.model";

@Injectable({ providedIn: "root" })
export class MultiCurrencyService {
  public constructor(private httpAccess: ApiHttpAccess) {}

  async getPrices(
    productId: number,
    attributeId?: number | undefined,
  ): Promise<SimpleCurrencyPrice[]> {
    let apiUrl = "currencyPrice/" + productId;
    if (attributeId) {
      apiUrl += "/" + attributeId;
    }

    const prices = await this.httpAccess.get<CurrencyPrice[]>(
      apiUrl,
      CurrencyPrice,
    );
    return prices.map((c) => ({
      currencyId: c.currency.id,
      price: c.price,
    }));
  }

  async setPrices(
    productId: number,
    attributeId: number | undefined,
    prices: SimpleCurrencyPrice[],
  ) {
    let apiUrl = "currencyPrice/" + productId;
    if (attributeId) {
      apiUrl += "/" + attributeId;
    }

    await this.httpAccess.post(apiUrl, prices);
  }

  async getOptionValuePrices(
    optionValueId: number,
  ): Promise<SimpleCurrencyPrice[]> {
    const prices = await this.httpAccess.get<OptionValueCurrencyPrice[]>(
      `optionValueCurrencyPrice/${optionValueId}`,
      OptionValueCurrencyPrice,
    );
    return prices.map((c) => ({
      currencyId: c.currency.id,
      price: c.defaultPrice,
    }));
  }

  async setOptionValuePrices(
    optionValueId: number,
    prices: SimpleCurrencyPrice[],
  ) {
    await this.httpAccess.post(
      `optionValueCurrencyPrice/${optionValueId}`,
      prices,
    );
  }
}
