import { Component, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  Notification,
  NotificationPermission,
  NotificationTranslation,
} from "../notification.interface";
import { NotificationManagerService } from "../notification-manager.service";
import {
  createNameof,
  LoadingService,
  NotificationService,
} from "@incert/incert-core";
import { SelectItem } from "primeng/api";
import { markAsDirty, modelToForm } from "@incert/incert-gui";
import { Constants } from "../../../../core/constants";
import { AccountSystemGroup } from "../../../account-system-group/account-system-group";

@Component({
  selector: "incert-internal-tools-add-new-notification",
  templateUrl: "./add-notification.component.html",
  styleUrls: ["./add-notification.component.scss"],
})
export class AddNotificationComponent implements OnInit {
  notificationCategoriesSI: Array<SelectItem>;
  notificationPermissions: Array<NotificationPermission>;
  notificationBehaviors: Array<SelectItem>;
  languageSI: Array<SelectItem>;
  notification: Notification;
  formGroup: UntypedFormGroup;
  accountSystemGroups: AccountSystemGroup[] = [];
  nameofFormTranslation = createNameof<NotificationTranslation>();

  constructor(
    private notificationManager: NotificationManagerService,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private fb: UntypedFormBuilder,
  ) {
    this.createFormGroup();
    this.addTranslation();
  }

  async ngOnInit() {
    if (this.notification) {
      for (let i = 0; i < this.notification.translations.length - 1; i++) {
        this.addTranslation();
      }
      this.formGroup.patchValue(this.notification);
      this.formGroup
        .get("startDate")
        .patchValue(
          this.notification.startDate
            ? new Date(this.notification.startDate)
            : null,
        );
      this.formGroup
        .get("endDate")
        .patchValue(
          this.notification.endDate
            ? new Date(this.notification.endDate)
            : null,
        );
      this.formGroup
        .get("accountSystemGroup")
        .patchValue(this.notification.accountSystemGroup.id);
    }
    this.languageSI = Constants.languageOptions();
  }

  private createFormGroup() {
    this.formGroup = this.fb.group(
      modelToForm<Notification>({
        id: this.fb.control(0),
        category: this.fb.control("", Validators.required),
        behavior: this.fb.control("", Validators.required),
        accountSystemGroup: this.fb.control({}, Validators.required),
        translations: this.fb.array([], Validators.required),
        endDate: this.fb.control("", Validators.required),
        uri: this.fb.control(""),
        startDate: this.fb.control(""),
        permissions: this.fb.control(""),
      }),
    );
  }

  addTranslation() {
    this.translations.push(
      this.fb.group(
        modelToForm<NotificationTranslation>({
          language: this.fb.control(null, Validators.required),
          title: this.fb.control("", Validators.required),
          text: new UntypedFormControl(""),
          cta: new UntypedFormControl(""),
        }),
      ),
    );
  }

  deleteTranslation(index: number) {
    if (this.translations.length === 1) {
      this.notificationService.notifyInfo(
        "Mindestens eine Übersetzung muss angegeben werden",
        "Löschen nicht möglich",
      );
    } else {
      try {
        this.translations.removeAt(index);
      } catch (e) {
        this.notificationService.notifyError(e);
      }
    }
  }

  async upsertNotification() {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      await this.loadingService.load(async () => {
        try {
          await this.notificationManager.upsertNotification(
            this.formGroup.getRawValue(),
          );
          this.notificationService.notifySuccess(
            "",
            "Benachrichtigung aktualisiert!",
          );
        } catch (e) {
          this.notificationService.notifyError(
            "Fehler bei der Aktualisierung!",
          );
        }
      });
      return true;
    }
    return false;
  }

  get translations() {
    return this.formGroup.get("translations") as UntypedFormArray;
  }
}
