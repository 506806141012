import { ReplaySubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiHttpAccess, ProductTypeModel } from "@incert/gms-core";

@Injectable({ providedIn: "root" })
export class ProductTypeService {
  private _productTypeEntries$ = new ReplaySubject<ProductTypeModel[]>(1);
  private _productTypeEntriesInit = false;

  public constructor(private httpAccess: ApiHttpAccess) {}

  public refreshProductTypeEntries() {
    this.httpAccess
      .get<ProductTypeModel[]>("productType", ProductTypeModel)
      .then((v) => this._productTypeEntries$.next(v));
  }

  public get productTypeEntries$() {
    if (!this._productTypeEntriesInit) {
      this.refreshProductTypeEntries();
      this._productTypeEntriesInit = true;
    }
    return this._productTypeEntries$.asObservable();
  }
}
