import { DataConversion, Optional, Required } from "@incert/data-conversion";

@DataConversion()
export class OrderProductOverviewEntryModel {
  @Required()
  orderId: number;

  @Required()
  productName: string;

  @Required()
  quantity: number;

  @Required()
  orderDate: Date;

  @Optional()
  productCode: string;

  @Required()
  value: number;

  @Required()
  totalValue: number;

  @Required()
  currentValue: number;

  @Required()
  status: number;

  @Required()
  orderProductId: number;
}
