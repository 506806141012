import { ArrayType, DataConversion, Optional } from "@incert/data-conversion";
import { Order } from "./order.model";
import { OrderProductAttribute } from "./order-product-attributes.model";
import { CustomerVoucher } from "./customer-voucher.model";

@DataConversion()
export class OrderProduct {
  @Optional()
  id: number;

  @Optional()
  order: Order;

  @Optional()
  name: string;

  @Optional()
  productModel: string;

  @Optional()
  @ArrayType(() =>
    import("./order-product-attributes.model").then(
      (mod) => mod.OrderProductAttribute,
    ),
  )
  orderProductAttributes: OrderProductAttribute[];

  @Optional()
  @ArrayType(() =>
    import("./customer-voucher.model").then((mod) => mod.CustomerVoucher),
  )
  customerVouchers: CustomerVoucher[];

  @Optional()
  price: number;

  @Optional()
  discountMade: number;

  @Optional()
  finalPrice: number;

  @Optional()
  tax: number;

  @Optional()
  allowTax: boolean;

  @Optional()
  quantity: number;

  @Optional()
  uprid: string;
}
