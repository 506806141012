import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccessManagerComponent } from "./access-manager.component";
import { AccordionModule } from "primeng/accordion";
import { I18nModule } from "@incert/i18n";
import { PanelModule } from "primeng/panel";
import { ButtonModule } from "primeng/button";
import { InputSwitchModule } from "primeng/inputswitch";
import { IconButtonModule, IncertTagModule } from "@incert/incert-gui";

@NgModule({
  imports: [
    CommonModule,
    AccordionModule,
    I18nModule,
    PanelModule,
    ButtonModule,
    InputSwitchModule,
    IncertTagModule,
    IconButtonModule,
  ],
  exports: [AccessManagerComponent],
  declarations: [AccessManagerComponent],
})
export class AccessManagerModule {}
