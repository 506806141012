import { Component, EventEmitter, Inject } from "@angular/core";
import {
  DataSourceFilter,
  DataTableFilterComponent,
  FILTER_CONFIG,
  FilterConfig,
} from "@incert/incert-gui";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { DataTableFilterService } from "../data-table-filter-service";
import { SelectItem } from "primeng/api";

interface Config extends FilterConfig {
  displayMode?: "dropdown" | "inputSwitch";
}

@Component({
  selector: "boolean-filter",
  templateUrl: "./boolean-filter.component.html",
  styleUrls: ["./boolean-filter.component.css"],
})
export class BooleanFilterComponent implements DataTableFilterComponent {
  config: Config = {
    displayMode: "dropdown",
  };
  change = new EventEmitter<DataSourceFilter>();
  formGroup: UntypedFormGroup;
  options: SelectItem[] = [
    { label: "True", value: "true" },
    { label: "False", value: "false" },
  ];
  floatingLabel = false;
  prevValue = null;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(FILTER_CONFIG) config: Config,
    private dataTableFilterService: DataTableFilterService,
  ) {
    Object.assign(this.config, config);
    this.formGroup = this.fb.group({
      filter: [],
    });
  }

  //filter updates when called; method can get called, sending value as parameter
  async getFilter() {
    if (this.config.method) {
      await this.config.method(this.filter.value);
    }
    return this.buildFilter(this.filter.value);
  }

  setValue(val) {
    this.filter.patchValue(val);
  }

  setFloatingLabel(val) {
    this.floatingLabel = val;
  }

  reset(): void {
    this.filter.reset();
  }

  buildFilter(v): DataSourceFilter | null {
    if (v === null) {
      return this.dataTableFilterService.getDataTableFilter(this.config, null);
    }
    return this.dataTableFilterService.getDataTableFilter(this.config, v);
  }

  filterExplicit() {
    if (this.prevValue !== this.filter.value) {
      this.change.emit(this.buildFilter(this.filter.value));
    }
    this.prevValue = this.filter.value;
  }

  get filter() {
    return this.formGroup.get("filter") as UntypedFormControl;
  }
}
