import { Injectable } from "@angular/core";
import { ApiHttpAccess, Manufacturer } from "@incert/gms-core";
import { DrawWinnersRequestModel } from "../models/draw-winners-request.model";

@Injectable({
  providedIn: "root",
})
export class CampaignService {
  constructor(private apiHttpAccess: ApiHttpAccess) {}

  async getCampaignManufacturers(campId: number) {
    return this.apiHttpAccess.get<Manufacturer[]>(
      `campaigns/${campId}/manufacturers`,
      Manufacturer,
    );
  }

  async drawWinners(
    campId: number,
    body: DrawWinnersRequestModel,
  ): Promise<string[]> {
    return this.apiHttpAccess.post(`campaigns/${campId}/drawWinners`, body);
  }

  async getCoupon(campId: number, id: number) {
    return (
      await this.apiHttpAccess.get(`campaigns/${campId}/coupons?q=eq(id,${id})`)
    )[0];
  }

  async updateCoupon(campId: number, id: number, data: any) {
    await this.apiHttpAccess.put(`campaigns/${campId}/coupons/${id}`, data);
  }
}
