import { DataConversion, Required } from "@incert/data-conversion";
import { Optional } from "@angular/core";

@DataConversion()
export class CodeRedemptionRestrictionModel {
  @Required()
  id: number;
  @Required()
  code: string;
  @Required()
  target: "cat" | "man";
  @Required()
  targetId: number;
  @Required()
  productType: number;
  @Required()
  inherit: boolean;
  @Required()
  allow: boolean;
  @Optional()
  action: string;
}
