import { DataConversion, Required, Optional } from "@incert/data-conversion";

@DataConversion()
export class LoginCustomerNameEntry {
  @Required()
  adminAccessId: string;

  @Required()
  customerId: number;

  @Required()
  isUserRole: boolean;

  @Required()
  name: string;

  @Optional()
  assignedUserRoleId: number;

  @Required()
  userRoleId: number;
}
