import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Manufacturer } from "./manufacturer.model";
import { VoucherHistoryLockType, VoucherLockMode } from "../enums";

@DataConversion()
export class LockedVoucher {
  @Required()
  public voucherCode: string;
  @Optional()
  public lockMode: keyof VoucherLockMode;
  @Optional()
  public lockedBy: Manufacturer;
  @Optional()
  public lockedFor: Manufacturer;
  @Optional()
  public lockedUntil: Date;
  @Optional()
  public comment: string;
  @Optional()
  public created: string;
  @Optional()
  public lockedForName: string;
  @Optional()
  public lockedByName: string;
  @Optional()
  public lockType: keyof VoucherHistoryLockType;
  @Optional()
  public predefinedComment: string;
}
