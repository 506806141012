import { Injectable } from "@angular/core";
import { HttpAccess } from "@incert/incert-core";
import { GMSUrlResolver } from "./gms-url-resolver.service";
import { ApiHttpAccess } from "./api-http-access.service";

@Injectable()
export class BackendApiHttpAccess extends ApiHttpAccess {
  public constructor(
    protected httpAccess: HttpAccess,
    protected gmsUrlResolver: GMSUrlResolver,
  ) {
    super(httpAccess, gmsUrlResolver);
    this.apiUrl = gmsUrlResolver.getBackendApiUrl();
  }
}
