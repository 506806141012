import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { ApiHttpAccess, Tag } from "@incert/gms-core";

@Injectable({ providedIn: "root" })
export class TagService {
  private _tags = new ReplaySubject<Tag[]>(1);
  private _tagsInit = false;

  public constructor(private httpAccess: ApiHttpAccess) {}

  public refreshTags() {
    this.httpAccess.get<Tag[]>("tags", Tag).then((v) => this._tags.next(v));
  }

  public get tags$() {
    if (!this._tagsInit) {
      this.refreshTags();
      this._tagsInit = true;
    }
    return this._tags.asObservable();
  }
}
