import {
  Component,
  ComponentRef,
  Input,
  ViewContainerRef,
} from "@angular/core";
import { DataTableConfig } from "./shared/interfaces/data-table-config.interface";
import { DataTableInnerComponent } from "./data-table-inner/data-table-inner.component";
import { ColumnDefinition } from "./shared/interfaces/column-definition.interface";

@Component({
  selector: "data-table",
  template: "",
})
export class DataTableComponent {
  public selection: any;
  public ref: ComponentRef<DataTableInnerComponent>;
  @Input()
  public set config(value: DataTableConfig<any>) {
    if (value) {
      this.vcr.clear();
      const ref = this.vcr.createComponent(DataTableInnerComponent);
      ref.instance.config = value;
      this.ref = ref;
    }
  }

  constructor(private vcr: ViewContainerRef) {}

  public get expandedRows() {
    return Object.keys(this.ref.instance.expandedRows);
  }

  public async refresh() {
    this.ref.instance.refresh();
  }

  public async updateColumns(columns: ColumnDefinition<any>[]) {
    await this.ref.instance.updateColumns(columns);
  }
}
