import { DashboardPersistorInterface } from "./persistor/dashboard-persistor.interface";
import { DashboardWidgetFilterInterface } from "./widget-filter/dashboard-widget-filter.interface";
import { Type } from "@angular/core";
import { TitleResolverInterface } from "./title-resolver/title-resolver.interface";
import { WidgetProperties } from "@incert/dashboard/src";
import { WidgetImageResolverInterface } from "@incert/dashboard/src/lib/shared/widget-image-resolver/widget-image-resolver.interface";

export interface CategoryToWidget {
  [name: string]: CategoryToWidget | Type<any>[];
}
export interface CategoryToWidgetProperties {
  [name: string]: CategoryToWidgetProperties | WidgetProperties<any>[];
}
export interface DashboardConfig {
  hideMissing?: boolean;
  id: string;
  widgets: Type<any>[] | CategoryToWidget;
  persistor?: Type<DashboardPersistorInterface>;
  widgetFilter?: Type<DashboardWidgetFilterInterface>;
  titleResolver?: Type<TitleResolverInterface>;
  invalidWidgetPlaceholderComponent?: Type<any>;
  imageResolver?: Type<WidgetImageResolverInterface>;
}
