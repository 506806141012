import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { ShoppingCartRuleModel } from "./shopping-cart-rule.model";
import { ShoppingCartRuleAssignmentTargetModel } from "./shopping-cart-rule-assignment-target.model";
import { ShoppingCartRuleComparisonOperator } from "../enums/shopping-cart-rule-comparison-operator.enum";
import { ShoppingCartRuleLogicalOperator } from "../enums/shopping-cart-rule-logical-operator.enum";
import { ShoppingCartRuleTargetDescriptor } from "../enums/shopping-cart-rule-target-descriptor.enum";

@DataConversion()
export class ShoppingCartRuleAssignmentModel {
  @Required()
  public id: number;

  @Optional()
  public rule: ShoppingCartRuleModel;

  @Required()
  public quantity: number;

  @Required()
  public comparisonOperator: ShoppingCartRuleComparisonOperator;

  @Required()
  public logicalOperator: ShoppingCartRuleLogicalOperator;

  public compareTargetId: number;

  public compareTargetDescriptor: ShoppingCartRuleTargetDescriptor;

  public compareTargetRecursive: number;

  @Optional()
  @ArrayType(() =>
    import("./shopping-cart-rule-assignment-target.model").then(
      (mod) => mod.ShoppingCartRuleAssignmentTargetModel,
    ),
  )
  public targets: ShoppingCartRuleAssignmentTargetModel[];
}
