import { Inject, Injectable } from "@angular/core";
import { GmsWsService } from "./gms-ws.service";
import { Environment } from "../models";
import { ENVIRONMENT } from "./environment.token";

@Injectable({ providedIn: "root" })
export class GmsResourceLockService {
  private _lockedResources: any = {};

  constructor(
    private wsService: GmsWsService,
    @Inject(ENVIRONMENT) private environment: Environment,
  ) {
    wsService
      .observe("getLockedResources")
      .subscribe((v) => (this._lockedResources = v));
    wsService.send({ action: "getLockedResources" });
  }

  lockResource(type: string, id: string | number) {
    id = id.toString();
    return new ResourceLock(
      this.wsService,
      type,
      id,
      this.environment.customer.id,
      this,
    );
  }

  isResourceLocked(type: string, id: string | number): boolean {
    id = id.toString();
    if (!(type in this._lockedResources)) {
      return false;
    }
    if (!(id in this._lockedResources[type])) {
      return false;
    }
    return (
      this._lockedResources[type][id].toString() !==
      this.environment.customer.id.toString()
    );
  }

  getLockedResourceCustomerId(
    type: string,
    id: string | number,
  ): number | undefined {
    id = id.toString();
    if (this.isResourceLocked(type, id)) {
      return this._lockedResources[type][id];
    }
    return undefined;
  }
}

export class ResourceLock {
  private readonly LOCK_INTERVAL = 5000;
  private readonly _intervalHandle;

  constructor(
    wsService: GmsWsService,
    private type: string,
    private id: number | string,
    private customerId: number,
    private resourceLockService: GmsResourceLockService,
  ) {
    const lockResource = () => {
      wsService.send({
        action: "lockResource",
        data: {
          id: id,
          type: type,
          customerId: customerId,
        },
      });
    };
    this._intervalHandle = setInterval(lockResource, this.LOCK_INTERVAL);
    lockResource();
  }

  unlock() {
    clearInterval(this._intervalHandle);
  }
  isResourceLocked() {
    return this.resourceLockService.isResourceLocked(this.type, this.id);
  }
  getLockedResourceCustomerId() {
    return this.resourceLockService.getLockedResourceCustomerId(
      this.type,
      this.id,
    );
  }
}
