import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IconButtonModule } from "../icon-button/icon-button.module";
import { FormlyArrayTypeComponent } from "./formly-array-type/formly-array-type.component";
import { FormlyObjectTypeComponent } from "./formly-object-type/formly-object-type.component";
import { FormlyBooleanNullableTypeComponent } from "./formly-boolean-nullable-type/formly-boolean-nullable-type.component";
import { FormlyStringTypeComponent } from "./formly-string-type/formly-string-type.component";
import { FormlyIntegerTypeComponent } from "./formly-integer-type/formly-integer-type.component";
import { FormlySelectTypeComponent } from "./formly-select-type/formly-select-type.component";
import { FormExtensionModule } from "../form-extension";
import { I18nModule } from "@incert/i18n";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";

@NgModule({
  imports: [
    CommonModule,
    IconButtonModule,
    FormExtensionModule,
    I18nModule,
    DropdownModule,
    InputTextModule,
    ReactiveFormsModule,
    FormlyModule,
  ],
  declarations: [
    FormlyArrayTypeComponent,
    FormlyBooleanNullableTypeComponent,
    FormlyIntegerTypeComponent,
    FormlyObjectTypeComponent,
    FormlySelectTypeComponent,
    FormlyStringTypeComponent,
  ],
  exports: [
    FormlyArrayTypeComponent,
    FormlyBooleanNullableTypeComponent,
    FormlyIntegerTypeComponent,
    FormlyObjectTypeComponent,
    FormlySelectTypeComponent,
    FormlyStringTypeComponent,
  ],
})
export class FormlyCustomTypeModule {}
