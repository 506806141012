<form-field *ngIf="config.displayMode === 'dropdown'" [label]="config.header" [floatingLabel]="floatingLabel" [clearSeparatorBottom]="floatingLabel">
  <p-dropdown
    [formControl]="filter"
    [options]="options"
    (onChange)="filterExplicit()"
    [showClear]="true"
    [placeholder]="floatingLabel ? ' ' : config.header"
    [incTooltip]="config.tooltip"
    [tooltipPosition]="'bottom'"
    [filter]="config.filter"
    appendTo="body">
  </p-dropdown>
</form-field>
<form-field *ngIf="config.displayMode === 'inputSwitch'">
  <div class="inputswitch-with-text filter-switch">
    <span>{{config.header}}</span>
    <p-inputSwitch
      [formControl]="filter"
      (onChange)="filterExplicit()"
      [incTooltip]="config.tooltip"
      [tooltipPosition]="'bottom'">
    </p-inputSwitch>
  </div>
</form-field>
