import { HttpClient } from "@angular/common/http";
import {
  DataSource,
  DataSourceFilter,
  DataSourceLoadParameter,
  isDataSourceFilterBinaryExpression,
  isDataSourceFilterLogicalExpression,
} from "../shared/interfaces/datasource.interface";
import {
  CombinedRQLQuery,
  createRqlQuery,
  LogicalConnectableOperator,
  mergeRqlQuery,
  parseRqlQuery,
  RQLQuery,
} from "@incert/incert-core";
import {
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  tap,
} from "rxjs";

export class RqlDataSource<T> implements DataSource<T> {
  public data: any[] = [];
  public total: number;

  private _query: RQLQuery<any>;
  private _url: string;

  private _param: DataSourceLoadParameter = {};
  private lastCount?: number;
  private lastCountFilter: string | null = null;
  private _refreshDatasource$ = new Subject();
  private _pagedData$ = new Subject<any[]>();
  private _total$ = new Subject<number>();
  private subscription?: Subscription;

  public get query() {
    return this._query;
  }

  get url() {
    return this._url;
  }

  set url(value) {
    this._url = value;
    this.lastCountFilter = null;
  }

  get param(): DataSourceLoadParameter {
    return this._param;
  }

  public constructor(
    url,
    private httpClient: HttpClient,
    private _defaultQuery?: RQLQuery<T>,
  ) {
    this._url = url;
  }

  get refreshDatasource$(): Observable<any> {
    return this._refreshDatasource$;
  }

  // TODO: Properly type pagedData$
  get pagedData$(): Observable<any[]> {
    return this._pagedData$;
  }

  get total$(): Observable<number> {
    return this._total$;
  }

  async export(): Promise<any[]> {
    let query = createRqlQuery<any>({});
    this.appendSort(query);
    this.appendFilter(query);

    query = this.applyDefaultFilter(query);
    const separator = this.url.indexOf("?") === -1 ? "?" : "&";
    const exportUrl = this.url + separator + "q=" + parseRqlQuery(query);

    return (await firstValueFrom(
      this.httpClient.get(exportUrl, {
        responseType: "json",
        withCredentials: true,
      }),
    )) as any[];
  }

  public async load(param: DataSourceLoadParameter) {
    this.subscription?.unsubscribe();

    this._param = param;
    let query = createRqlQuery({});
    this.appendSort(query);
    this.appendFilter(query);
    query = this.applyDefaultFilter(query);
    this.appendLimit(query);
    this._query = query;

    const separator = this.url.indexOf("?") === -1 ? "?" : "&";
    const url = this.url + separator + "q=" + parseRqlQuery(query);

    const data = firstValueFrom(
      this.httpClient.get(url, { withCredentials: true }),
    );

    this.subscription = from(data)
      .pipe(
        tap((v) => this._pagedData$.next(<any[]>v)),
        switchMap(() => {
          const countQuery = { ...query };
          countQuery.limit = undefined;
          const currentFilter = JSON.stringify(countQuery);

          if (this.lastCountFilter === currentFilter) {
            return of({ count: this.lastCount, filter: currentFilter });
          }

          const countUrl =
            this.url + separator + "q=" + parseRqlQuery(countQuery);

          return this.httpClient
            .get(countUrl + "count()", {
              withCredentials: true,
            })
            .pipe(
              map((v) => ({
                count: parseInt(<string>v, 10),
                filter: currentFilter,
              })),
            );
        }),
        tap(({ count, filter }) => {
          this.total = count;
          this.lastCount = count;
          this.lastCountFilter = filter;
          this._total$.next(count);
        }),
      )
      .subscribe();

    return <any[]>await data;
  }

  private appendLimit(query: CombinedRQLQuery<any>) {
    if (this._param.limit) {
      query.limit = {
        count: this._param.limit,
        start: this._param.offset ? this._param.offset : 0,
      };
    }
  }

  private appendSort(query: CombinedRQLQuery<any>) {
    if (this._param.sorts) {
      for (const sort of this._param.sorts) {
        query.sort.push({ field: sort.property, order: sort.sortOrder });
      }
    }
  }

  private appendFilter(query: CombinedRQLQuery<any>) {
    if (this._param.filters) {
      for (const filter of this._param.filters) {
        const op = this.convertFilter(filter);
        if (op) {
          query.where.push(op);
        }
      }
    }
  }

  private convertFilter(
    filter: DataSourceFilter,
  ): LogicalConnectableOperator<any> | null {
    if (isDataSourceFilterBinaryExpression(filter)) {
      if (filter.value !== null) {
        switch (filter.type) {
          case "eq":
            return { type: "eq", field: filter.property, value: filter.value };
          case "contains":
            return {
              type: "like",
              field: filter.property,
              value: "*" + filter.value + "*",
            };
          case "gt":
            return { type: "gt", field: filter.property, value: filter.value };
          case "lt":
            return { type: "lt", field: filter.property, value: filter.value };
          case "ge":
            return { type: "ge", field: filter.property, value: filter.value };
          case "le":
            return { type: "le", field: filter.property, value: filter.value };
          case "startsWith":
            return {
              type: "like",
              field: filter.property,
              value: filter.value + "*",
            };
          case "in":
            return { type: "in", field: filter.property, value: filter.value };
          case "inLike":
            return {
              type: "in",
              field: filter.property,
              value: filter.value,
              asNumber: true,
            };
          case "between":
            if (!filter.value[0] && !filter.value[1]) {
              return null;
            }

            const conditions = [];
            if (filter.value[0]) {
              conditions.push({
                type: "ge",
                field: filter.property,
                value: filter.value[0],
              });
            }
            if (filter.value[1]) {
              conditions.push({
                type: "le",
                field: filter.property,
                value: filter.value[1],
              });
            }
            return conditions.length > 1
              ? {
                  type: "and",
                  operators: conditions,
                }
              : conditions[0];
        }
      }

      return null;
    } else if (isDataSourceFilterLogicalExpression(filter)) {
      const operators = filter.filters
        .map((filter) => this.convertFilter(filter))
        .filter((filter) => filter);

      if (operators.length === 0) {
        return null;
      } else if (operators.length === 1) {
        return operators[0];
      } else {
        return {
          type: filter.type,
          operators,
        };
      }
    }
  }

  private applyDefaultFilter(query: RQLQuery<any>): RQLQuery<any> {
    if (this._defaultQuery) {
      const defaultQuery = { ...this._defaultQuery };
      if (query.sort && query.sort.length) {
        delete defaultQuery.sort;
      }
      return mergeRqlQuery(query, defaultQuery);
    }
    return query;
  }

  set defaultQuery(value: RQLQuery<T>) {
    this._defaultQuery = value;
    this._refreshDatasource$.next(this);
  }
}
