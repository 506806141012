import { Injector } from "@angular/core";
import { DatePipe } from "@angular/common";
import { I18nService } from "@incert/i18n";
import { FormExtensionI18n } from "@incert/incert-gui";

export function gmsFormExtensionI18nFactory(
  injector: Injector,
): FormExtensionI18n {
  const translate = injector.get(I18nService);
  const datePipe = injector.get(DatePipe);

  const iso8601ToDatePipe = (format: string) => {
    format = format.replace(/Y/g, "y");
    format = format.replace(/D/g, "d");
    format = format.replace(/h/g, "H");
    return format;
  };

  const formatDate = (date: Date) => {
    return datePipe.transform(
      date,
      iso8601ToDatePipe(translate.instant("core.dateTime.dateFormat")),
    );
  };

  const formatTime = (date: Date) => {
    return datePipe.transform(
      date,
      iso8601ToDatePipe(translate.instant("core.dateTime.timeFormat")),
    );
  };

  return {
    default: (data) => translate.instant("core.validation.default"),
    required: (data) => translate.instant("core.validation.required"),
    email: (data) => translate.instant("core.validation.email"),
    numberEnsure: (data) => translate.instant("core.validation.numberEnsure"),
    numberNoDecimals: (data) =>
      translate.instant("core.validation.numberNoDecimals"),
    numberMaxDecimals: (data) =>
      translate.instant("core.validation.numberMaxDecimals", {
        decimals: data.decimals,
      }),
    numberBetween: (data) =>
      translate.instant("core.validation.numberBetween", {
        min: data.min,
        max: data.max,
      }),
    numberMin: (data) =>
      translate.instant("core.validation.numberMin", { min: data.min }),
    numberMax: (data) =>
      translate.instant("core.validation.numberMax", { max: data.max }),
    dateMin: (data) =>
      translate.instant("core.validation.dateMin", {
        min: formatDate(data.min),
      }),
    dateMax: (data) =>
      translate.instant("core.validation.dateMax", {
        max: formatDate(data.max),
      }),
    dateBetween: (data) =>
      translate.instant("core.validation.dateBetween", {
        max: formatDate(data.max),
        min: formatDate(data.min),
      }),
    dateEnsure: (data) => translate.instant("core.validation.dateEnsure"),
    ensureList: (data) => translate.instant("core.validation.ensureList"),
    dateGreaterThan: (data) =>
      translate.instant("core.validation.dateGreaterThan", {
        date: formatDate(data.date),
      }),
    dateLessThan: (data) =>
      translate.instant("core.validation.dateLessThan", {
        date: formatDate(data.date),
      }),
    dateGreaterThanOrEqual: (data) =>
      translate.instant("core.validation.dateGreaterThanOrEqual", {
        date: formatDate(data.date),
      }),
    dateLessThanOrEqual: (data) =>
      translate.instant("core.validation.dateLessThanOrEqual", {
        date: formatDate(data.date),
      }),
    numberGreaterThan: (data) =>
      translate.instant("core.validation.numberGreaterThan", {
        number: data.number,
      }),
    numberLessThan: (data) =>
      translate.instant("core.validation.numberLessThan", {
        number: data.number,
      }),
    numberGreaterThanOrEqual: (data) =>
      translate.instant("core.validation.numberGreaterThanOrEqual", {
        number: data.number,
      }),
    numberLessThanOrEqual: (data) =>
      translate.instant("core.validation.numberLessThanOrEqual", {
        number: data.number,
      }),
    timeGreaterThan: (data) =>
      translate.instant("core.validation.timeGreaterThan", {
        time: formatTime(data.time),
      }),
    timeLessThan: (data) =>
      translate.instant("core.validation.timeLessThan", {
        time: formatTime(data.time),
      }),
    timeGreaterThanOrEqual: (data) =>
      translate.instant("core.validation.timeGreaterThanOrEqual", {
        time: formatTime(data.time),
      }),
    timeLessThanOrEqual: (data) =>
      translate.instant("core.validation.timeLessThanOrEqual", {
        time: formatTime(data.time),
      }),
    passwordEqual: (data) => translate.instant("core.validation.passwordEqual"),
    jsonEnsure: (data) => translate.instant("core.validation.jsonEnsure"),
    invalidUrl: (data) => translate.instant("core.validation.invalidUrl"),
    protocolNotAllowed: (data) =>
      translate.instant("core.validation.protocolNotAllowed", {
        protocols: data.protocolsString,
      }),
    maxChars: (data) =>
      translate.instant("core.validation.maxChars", {
        maxChars: data.maxChars,
        exceeding: data.exceeding,
      }),
    noExistingVAT: (data) => translate.instant("core.validation.noExistingVAT"),
  };
}
