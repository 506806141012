import { Injectable } from "@angular/core";
import { ApiHttpAccess } from "./http/api-http-access.service";
import { SocialMediaSettingModel, SocialMediaType } from "../models";

@Injectable({
  providedIn: "root",
})
export class SocialMediaService {
  constructor(private apiHttpAccess: ApiHttpAccess) {}

  async getSocialTypes(): Promise<SocialMediaType[]> {
    return await this.apiHttpAccess.get<SocialMediaType[]>(
      "socialMediaSettings/types",
      SocialMediaType,
    );
  }

  async findSocial(id: number): Promise<SocialMediaSettingModel | null> {
    const result = await this.apiHttpAccess.get<SocialMediaSettingModel[]>(
      `socialMediaSettings/list?q=in(id,(${id}))`,
      SocialMediaSettingModel,
    );
    return result.length === 1 ? result[0] : null;
  }

  async upsertSocial(social: SocialMediaSettingModel) {
    await this.upsertSocials([social]);
  }

  async upsertSocials(socials: SocialMediaSettingModel[]) {
    await this.apiHttpAccess.post("socialMediaSettings/save", socials);
  }

  async deleteSocial(id: number) {
    await this.apiHttpAccess.delete("socialMediaSettings/delete/" + id);
  }
}
