import { Observable, ReplaySubject } from "rxjs";
import { CategoryIdsToProductIds, CategoryListEntry } from "../models";
import { Injectable } from "@angular/core";
import { ApiHttpAccess } from "./http/api-http-access.service";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  private _categoryListEntries$ = new ReplaySubject<CategoryListEntry[]>(1);
  private _categoryListEntriesInit = false;

  private _categoryIdsToProductIds$ =
    new ReplaySubject<CategoryIdsToProductIds>(1);
  private _categoryIdsToProductIdsInit = false;

  private _subShops$ = new ReplaySubject<CategoryListEntry[]>(1);
  private _subShopsInit = false;

  private _eventCategories$ = new ReplaySubject<CategoryListEntry[]>(1);
  private _eventCategoriesInit = false;

  public constructor(private httpAccess: ApiHttpAccess) {}

  get categoryListEntries$(): Observable<CategoryListEntry[]> {
    if (!this._categoryListEntriesInit) {
      this.refreshCategoryListEntries();
      this._categoryListEntriesInit = true;
    }
    return this._categoryListEntries$.asObservable();
  }
  refreshCategoryListEntries() {
    this.httpAccess
      .get<CategoryListEntry[]>("categoryList", CategoryListEntry)
      .then((v) => this._categoryListEntries$.next(v));
  }

  get categoryIdsToProductIds$(): Observable<CategoryIdsToProductIds> {
    if (!this._categoryIdsToProductIdsInit) {
      this.refreshCategoryIdsToProductIds();
      this._categoryIdsToProductIdsInit = true;
    }
    return this._categoryIdsToProductIds$.asObservable();
  }

  refreshCategoryIdsToProductIds() {
    (
      this.httpAccess.get(
        "categoryIdToProductIds",
      ) as Promise<CategoryIdsToProductIds>
    ).then((v) => this._categoryIdsToProductIds$.next(v));
  }

  get subShops$(): Observable<CategoryListEntry[]> {
    if (!this._subShopsInit) {
      this.refreshSubShops();
      this._subShopsInit = true;
    }
    return this._subShops$.asObservable();
  }
  refreshSubShops() {
    this.httpAccess
      .get<
        CategoryListEntry[]
      >("categoryList?subShopsOnly=1", CategoryListEntry)
      .then((v) => this._subShops$.next(v));
  }

  get eventCategories$(): Observable<CategoryListEntry[]> {
    if (!this._eventCategoriesInit) {
      this.refreshEventCategories();
      this._eventCategoriesInit = true;
    }
    return this._eventCategories$.asObservable();
  }
  refreshEventCategories() {
    this.httpAccess
      .get<
        CategoryListEntry[]
      >("categoryList?eventCatsOnly=1", CategoryListEntry)
      .then((v) => this._eventCategories$.next(v));
  }
}
