import { DataConversion, Required, Optional } from "@incert/data-conversion";
import { Language } from "./language.model";

@DataConversion()
export class GenderDescription {
  @Required()
  name: string;

  @Optional()
  language: Language;

  @Optional()
  gender: string;
}
