import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class CustomerVoucher {
  @Optional()
  id: number;

  @Optional()
  orderId: number;

  @Optional()
  productId: number;

  @Optional()
  ordersProductsId: number;

  @Optional()
  customerId: number;

  @Optional()
  voucherCode: string;

  @Optional()
  curVoucherStatusId: number;

  @Optional()
  textFor: string;

  @Optional()
  textforP: string;

  @Optional()
  textMessage: string;

  @Optional()
  textMessageP: string;

  @Optional()
  amount: number;

  @Optional()
  curVoucherValue: number;

  @Optional()
  created: string;

  @Optional()
  delivered: number;

  @Optional()
  validthru: string;

  @Optional()
  voucherPeriodFrom: string;

  @Optional()
  voucherPeriodTo: string;

  @Optional()
  categoryName: string;

  @Optional()
  couponId: string;

  @Optional()
  ordersTypes: number;

  @Optional()
  manId: number;

  @Optional()
  altImg: string;

  @Optional()
  advancedPersonalisation: boolean;

  @Optional()
  winCampId: number;
}
