import {
  AfterViewInit,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from "@angular/core";
import { WidgetConfiguration } from "./widget-configuration.interface";

@Component({
  template: "",
})
export class WidgetConfigurationHostComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  private _componentRefs: ComponentRef<WidgetConfiguration>[] = [];

  public set componentRefs(v: ComponentRef<WidgetConfiguration>[]) {
    if (v) {
      this._componentRefs = v;
      for (const ref of this._componentRefs) {
        this.viewContainerRef.insert(ref.hostView);
      }
    }
  }

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}
}
