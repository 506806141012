import { Injectable } from "@angular/core";
import { firstValueFrom, ReplaySubject } from "rxjs";
import { CustomerStatus } from "../models";
import { ApiHttpAccess } from "@incert/gms-core";
import { SelectItem } from "primeng/api";

@Injectable({ providedIn: "root" })
export class CustomerStatusService {
  private _customerStatus$?: ReplaySubject<CustomerStatus[]>;

  constructor(private apiHttpAccess: ApiHttpAccess) {}

  get customerStatus$() {
    if (!this._customerStatus$) {
      this._customerStatus$ = new ReplaySubject<CustomerStatus[]>(1);
      this.apiHttpAccess
        .get<
          CustomerStatus[]
        >("customerStatus?q=sort(%2BcustomerStatusId)", CustomerStatus)
        .then((customerStatus) => this._customerStatus$.next(customerStatus));
    }
    return this._customerStatus$.asObservable();
  }

  public async getCustomerStatusSI(): Promise<SelectItem[]> {
    return (await firstValueFrom(this.customerStatus$)).map((cs) => {
      return {
        label: cs.customerStatusName,
        value: cs.customerStatusId,
      };
    });
  }
}
