import { TitleResolverInterface } from "./title-resolver.interface";
import { Injectable } from "@angular/core";
import { WidgetProperties } from "@incert/dashboard";

@Injectable({
  providedIn: "root",
})
export class DefaultTitleResolverService implements TitleResolverInterface {
  resolveTitle(id: string, name: string, properties: WidgetProperties<any>) {
    return { title: name };
  }
}
