import { Inject, Injectable } from "@angular/core";
import { I18nService } from "@incert/i18n";
import { EnvironmentService } from "./services";
import {
  FORM_LANGUAGES,
  FormLanguage,
  LanguageFormBuilder,
} from "@incert/incert-gui";
import {
  GMS_ADMIN_LFB,
  GMS_COMBINED_LFB,
  GMS_FRONTEND_LFB,
} from "./services/lfb.token";

@Injectable()
export class GMSCoreInitializer {
  public constructor(
    private environmentService: EnvironmentService,
    private i18n: I18nService,
    @Inject(FORM_LANGUAGES) private formLanguages: FormLanguage[],
    @Inject(GMS_ADMIN_LFB) private adminLfb: LanguageFormBuilder,
    @Inject(GMS_FRONTEND_LFB) private frontendLfb: LanguageFormBuilder,
    @Inject(GMS_COMBINED_LFB) private combinedLfb: LanguageFormBuilder,
  ) {}
  async load() {
    await this.environmentService.load();
    const adminLanguage = this.getAdminLanguage();
    if (
      adminLanguage.code !==
      this.environmentService.environment.currentLanguage.code
    ) {
      await this.environmentService.setLanguage(adminLanguage.id);
      await this.environmentService.load();
      await this.i18n.setLanguage(adminLanguage.code);
    } else {
      await this.i18n.setLanguage(
        this.environmentService.environment.currentLanguage.code,
      );
    }

    await this.i18n.load("core");
    for (const language of this.environmentService.environment.languages) {
      const lang = { id: language.id.toString(), name: language.name };
      if (language.active || language.maintainable) {
        this.frontendLfb.languages.push(lang);
      }
      if (language.adminActive) {
        this.adminLfb.languages.push(lang);
      }
      this.combinedLfb.languages.push(lang);
      this.formLanguages.push({
        id: language.id.toString(),
        name: language.name,
      });
    }
  }

  private getAdminLanguage() {
    const adminLanguages = this.environmentService.environment.languages.filter(
      (l) => l.adminActive,
    );
    const currentLanguage = this.environmentService.environment.currentLanguage;

    if (
      adminLanguages.length &&
      !adminLanguages.find((l) => l.code === currentLanguage.code)
    ) {
      const preferredLanguages = navigator.languages || [];

      for (const preferredLanguage of preferredLanguages) {
        const foundLanguage = adminLanguages.find((l) =>
          preferredLanguage.startsWith(l.code),
        );
        if (foundLanguage) {
          return foundLanguage;
        }
      }

      return adminLanguages[0];
    }

    return currentLanguage;
  }
}
