import { inject, Injector } from "@angular/core";
import { I18nService } from "@incert/i18n";
import { DashboardI18n } from "@incert/dashboard";

export function gmsDashboardI18nFactory(): DashboardI18n {
  const i18n = inject(I18nService);
  return {
    filter: "Filter",
    save: i18n.instant("core.action.save"),
    resetFilter: "",
    printDashboard: "",
    addNewWidget: "",
    setGlobalFilter: "",
  };
}
