import {
  ArrayType,
  DataConversion,
  Required,
  Optional,
} from "@incert/data-conversion";
import { Customer } from "./customer.model";
import { EventReservationProduct } from "./event-reservation-product.model";
import { EventReservationHistory } from "./event-reservation-history.model";

@DataConversion()
export class EventReservation {
  constructor() {
    this.dateCreated = new Date();
    this.imported = false;
    this.archived = false;
  }

  @Optional()
  id: number;

  @Required()
  reservationNr: string;

  @Optional()
  customer: Customer;

  @Optional()
  comment: string;

  @Required()
  dateCreated: Date;

  @Optional()
  purchaseExpirationDate: Date;

  @Required()
  imported: boolean;

  @Required()
  archived: boolean;

  @Optional()
  reservationFeeMandatory: boolean;

  @Required()
  @ArrayType(() =>
    import("./event-reservation-product.model").then(
      (mod) => mod.EventReservationProduct,
    ),
  )
  reservationProducts: EventReservationProduct[];

  @Optional()
  @ArrayType(() =>
    import("./event-reservation-history.model").then(
      (mod) => mod.EventReservationHistory,
    ),
  )
  reservationHistory: EventReservationHistory[];

  @Optional()
  participantStates: string[];
}
