import { I18nService } from "@incert/i18n";

export interface WidgetDescriptionEntry {
  title: string;
  info?: string;
  multiple?: boolean;
}
export interface WidgetConfigurationDescription {
  getWidgetConfigurationDescription(
    state: any,
    i18n: I18nService,
  ): WidgetDescriptionEntry | null;
}

export function isWidgetConfigurationDescription(
  object: any,
): object is WidgetConfigurationDescription {
  return (
    (object as WidgetConfigurationDescription)
      .getWidgetConfigurationDescription !== undefined
  );
}
