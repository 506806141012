import { ReplaySubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiHttpAccess, Manufacturer } from "@incert/gms-core";

@Injectable({ providedIn: "root" })
export class ManufacturerService {
  private _manufacturerEntries$ = new ReplaySubject<Manufacturer[]>(1);
  private _manufacturerEntriesInit = false;

  public constructor(private httpAccess: ApiHttpAccess) {}

  public refreshManufacturerEntries() {
    this.httpAccess
      .get<Manufacturer[]>("manufacturer", Manufacturer)
      .then((v) => this._manufacturerEntries$.next(v));
  }

  public get manufacturerEntries$() {
    if (!this._manufacturerEntriesInit) {
      this.refreshManufacturerEntries();
      this._manufacturerEntriesInit = true;
    }
    return this._manufacturerEntries$.asObservable();
  }
}
