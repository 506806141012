import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class Currency {
  @Required()
  id: number;

  @Required()
  title: string;

  @Required()
  code: string;

  @Required()
  symbolLeft: string;
  @Required()
  symbolRight: string;
  @Required()
  decimalPoint: string;
  @Required()
  thousandsPoint: string;
  @Required()
  decimalPlaces: string;
}
