import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NumberFilterComponent } from "./number-filter.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SpinnerModule } from "primeng/spinner";
import { SliderModule } from "primeng/slider";
import { InputTextModule } from "primeng/inputtext";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { FormExtensionModule } from "../../form-extension";
import { ButtonModule } from "primeng/button";
import { IconButtonModule } from "../../icon-button/icon-button.module";
import { I18nModule } from "@incert/i18n";
import { TooltipModule } from "../../tooltip/tooltip.module";

@NgModule({
  declarations: [NumberFilterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SpinnerModule,
    SliderModule,
    InputTextModule,
    OverlayPanelModule,
    FormExtensionModule,
    FormsModule,
    ButtonModule,
    IconButtonModule,
    I18nModule,
    TooltipModule,
  ],
})
export class NumberFilterModule {}
