import { ModuleMenuEntry } from "./module-menu-entry.model";
import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";

@DataConversion()
export class Menu {
  @Optional()
  @ArrayType(() =>
    import("./module-menu-entry.model").then((mod) => mod.ModuleMenuEntry),
  )
  moduleMenuEntries: ModuleMenuEntry[];
}
