import { ModuleGroupMenuEntry } from "./module-group-menu-entry.model";
import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";

@DataConversion()
export class ModuleMenuEntry {
  @Required()
  title: string;

  @Optional()
  @ArrayType(() =>
    import("./module-group-menu-entry.model").then(
      (mod) => mod.ModuleGroupMenuEntry,
    ),
  )
  moduleGroupMenuEntries: ModuleGroupMenuEntry[];

  @Optional()
  icon: string;

  @Optional()
  routerLink: string;
}
