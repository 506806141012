import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "../environment.token";
import { Environment } from "../../models/environment.model";

@Injectable()
export class GMSUrlResolver {
  public testSystemUrl = "https://003.dev-myincert.com";
  public mediaLibraryUrl = "https://incert-resources.com";
  public localResourceUrl = "http://localhost:3200";
  readonly LOCAL_STORAGE_KEY = "testSystem";

  public getApiUrl(): string {
    return this.getUrl() + "api/";
  }

  public getFontServiceUrl(resource?: string): string {
    if (resource && typeof resource === "string") {
      return (
        this.mediaLibraryUrl +
        "/fonts/" +
        this.environment.partId +
        "/" +
        resource
      );
    }
    return this.mediaLibraryUrl + "/fonts/" + this.environment.partId;
  }

  public getGoogleFontServiceUrl(resource?: string): string {
    if (resource && typeof resource === "string") {
      return (
        this.mediaLibraryUrl +
        "/googleFonts/" +
        this.environment.partId +
        "/css2?" +
        resource +
        "&display=swap"
      );
    }
    return "";
  }

  public postFontServiceUrl(resource?: string): string {
    if (resource && typeof resource === "string") {
      return this.mediaLibraryUrl + "/fonts/" + resource;
    } else {
      return this.mediaLibraryUrl + "/fonts/";
    }
  }

  public getBackendApiUrl(): string {
    return this.getUrl() + "admin/api/";
  }

  public getTemplateUrl() {
    return this.getUrl() + "templates/" + this.environment.partId + "/";
  }

  public getAdminUrl() {
    return this.getUrl() + "admin/";
  }

  public getInvoiceArchiveUrl() {
    return this.getApiUrl() + "invoiceArchive/download/";
  }

  public getLanguageIconUrl(language) {
    return this.getUrl() + "lang/" + language + "/admin/images/icon.gif";
  }

  public constructor(@Inject(ENVIRONMENT) public environment: Environment) {}

  public getMediaLibraryUrl(resource: string) {
    if (resource && typeof resource === "string") {
      if (resource.includes("http")) {
        // legacy url
        return resource;
      }
      return (
        this.mediaLibraryUrl + "/" + this.environment.partId + "/" + resource
      );
    }
    return "";
  }

  public getUrl() {
    const location = window.location.href;
    let url = window.location.origin;

    if (
      location.includes("localhost") ||
      location.includes("127.0.0.1") ||
      location.includes("bs-local") ||
      location.includes("gms-backend.incert.at")
    ) {
      if (window.localStorage.getItem(this.LOCAL_STORAGE_KEY)) {
        url = window.localStorage.getItem(this.LOCAL_STORAGE_KEY);
      } else {
        url = this.testSystemUrl;
      }
    } else {
      if (location.includes("/admin")) {
        url = location.split("/admin")[0];
      }
    }

    return url + "/";
  }
}
