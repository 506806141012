import { DataConversion, Optional, Required } from "@incert/data-conversion";

@DataConversion()
export class OrderOverviewEntry {
  @Required()
  orderId: number;
  @Required()
  orderType: number;
  @Required()
  deviceType: string;
  @Required()
  customerName: string;
  @Required()
  customerEmail: string;
  @Required()
  datePurchased: Date;
  @Required()
  payment: string;
  @Required()
  value: number;
  @Required()
  discount: number;
  @Required()
  type: number;
  @Optional()
  status: number;
  @Optional()
  shippingStatus: number;
}
