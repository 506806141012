import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class TaxRateModel {
  @Required()
  id: number;

  @Required()
  priority: number;

  @Required()
  rate: string;

  @Required()
  description: string;

  @Required()
  isActive: boolean;
}
