import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { DateConversion } from "@incert/data-conversion";
import { AccessProfileModel } from "./access-profile.model";
import { Manufacturer } from "./manufacturer.model";

export class InterfaceType {
  @Required()
  id: number;
  @Required()
  type: string;
  @Required()
  title: string;
  @Optional()
  description: string;
  @Optional()
  receiver: boolean;
  @Optional()
  transmitter: true;
}

@DataConversion()
export class InterfaceEntryModel {
  @Optional()
  id: number = null;
  @Optional()
  remoteShopId = "";
  @Optional()
  remoteShopUrl = "";
  @Optional()
  remoteShopName = "";
  @Optional()
  username = "";
  @Optional()
  password = "";
  @Optional()
  syncMans = 0;
  @Optional()
  interfaceType = "";
  @Optional()
  dataType = "";
  @Optional()
  interfaceVersion = "";
  @Optional()
  active = false;
  @Optional()
  logRequest = false;
  @Optional()
  additionalData: any = null;
  @Optional()
  @DateConversion()
  lastModified: Date = null;
  @Optional()
  voucherPrefix = "";
  @Optional()
  isOverwrite = "";
  @Optional()
  interfaceDescription = "";
  @Optional()
  accessProfile: AccessProfileModel = null;
  @Optional()
  priority = 0;
  @Optional()
  manufacturers: Manufacturer[] = [];
}

export class InterfaceEntryOverviewModel extends InterfaceEntryModel {
  @Optional()
  hasManufacturer? = false;
}

@DataConversion()
export class InterfaceTypeSetting {
  [k: string]: InterfaceSetting;
}

@DataConversion()
export class InterfaceSetting {
  [k: string]: {
    title: string;
    description: string;
  };
}
