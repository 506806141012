import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { EmailResponse } from "../enums/email-response.enum";
import { OrderEmailType } from "../enums/order-email-type.enum";
import { Order } from "./order.model";

@DataConversion()
export class OrderEmailHistoryModel {
  @Required()
  id: number;

  @Optional()
  type: OrderEmailType;

  @Optional()
  status: EmailResponse;

  @Required()
  order: Order;

  @Optional()
  code: string;

  @Optional()
  joinToOneKey: string;

  @Optional()
  emailAddress: string;

  @Optional()
  senderEmailAddress: string;

  @Optional()
  forwardAddress: string;

  @Optional()
  created: Date;
}
