import {
  DataConversion,
  DateConversion,
  Required,
  Optional,
} from "@incert/data-conversion";
import { EventDate } from "./event-date.model";
import { EventSequence } from "./event-sequence.model";

@DataConversion()
export class Slot {
  @Required()
  id: number;

  @Optional()
  eventSequence: EventSequence;

  @Optional()
  eventDate: EventDate;

  @Optional()
  eventDateId: number;

  @Required()
  color: string;

  @Required()
  @DateConversion()
  startTime: Date;

  @Required()
  @DateConversion()
  endTime: Date;

  @Required()
  capacity: number;

  @Required()
  capacityReserved: number;

  @Optional()
  capacityInCart: number;

  @Required()
  isSlotExpired: boolean;
}
