import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Manufacturer } from "./manufacturer.model";
import { Product } from "./product.model";

@DataConversion()
export class ImportedVoucherModel {
  @Required()
  id: number;

  @Optional()
  orderId: number;

  @Optional()
  orderNr: string;

  @Required()
  voucherCode: string;

  @Optional()
  textFor: string;

  @Required()
  created: Date;

  @Optional()
  validthru: Date;

  @Required()
  amount: number;

  @Optional()
  textMessage: string;

  @Optional()
  product: Product;

  @Optional()
  taxRate: number;

  @Optional()
  customerName: string;

  @Optional()
  currency: string;

  @Optional()
  redeemedAt: string;

  @Required()
  oStatus: number;

  @Optional()
  usedValue: number;

  @Optional()
  lastRedemptionVal: number;

  @Optional()
  comment: string;

  @Optional()
  reservNr: string;

  @Optional()
  manId: number;

  @Optional()
  payment: string;

  @Optional()
  shipping: string;

  @Optional()
  shippingCost: number;

  @Optional()
  oMan: Manufacturer;

  @Optional()
  oSuperMan: Manufacturer;
}
