import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class PasswordRuleModel {
  @Required()
  public text: string;

  @Required()
  public regex: string;
}
