import { DataConversion, Required } from "@incert/data-conversion";
import { ApiModelInterface } from "@incert/bon/bon-core/src/lib/interface/api-model.interface";

@DataConversion()
export class Reward {
  //allgemein
  @Required()
  uid: string;
  @Required()
  name: string;
  @Required()
  value: string;
  @Required()
  languageUid: number;

  //text
  @Required()
  description: string;
  @Required()
  longDescriptionHeader: string;
  @Required()
  longDescription: string;

  //sammeln seite
  @Required()
  rewardPageDescription: string;
  // @Required()
  rewardPageLink: string;
  @Required()
  rewardPageButtonText: string;
  @Required()
  rewardPageLinkTarget: string;

  //einstellungen
  @Required()
  companyRewardType: string;
  @Required()
  validFrom: string;
  // @Required()
  validTo: Date;
  @Required()
  hideOnApi: boolean;
  @Required()
  companyLevel: string;
  @Required()
  companyScoutSetting: string;
  @Required()
  companySubmission: string;
  // @Required()
  // submission: string;

  //gutschein
  @Required()
  voucherProductId: string;

  //test
  IconButton: any;

  constructor(
    //allgemein
    uid?: string,
    name?: string,
    value?: string,
    languageUid?: number,
    //text
    description?: string,
    longDescriptionHeader?: string,
    longDescription?: string,
    //sammeln seite
    rewardPageDescription?: string,
    rewardPageLink?: string,
    rewardPageButtonText?: string,
    rewardPageLinkTarget?: string,
    //settings
    companyRewardType?: string,
    voucherProductId?: string,
    validFrom?: string,
    hideOnApi?: boolean,
    companyLevel?: string,
    companyScoutSetting?: string,
    companySubmission?: string,
    // submission?: string
  ) {
    //allgemein
    this.uid = uid;
    this.name = name;
    this.value = value;
    this.languageUid = languageUid;
    //text
    this.description = description;
    this.longDescriptionHeader = longDescriptionHeader;
    this.longDescription = longDescription;

    //sammeln seite
    this.rewardPageDescription = rewardPageDescription;
    this.rewardPageLink = rewardPageLink;
    this.rewardPageButtonText = rewardPageButtonText;
    this.rewardPageLinkTarget = rewardPageLinkTarget;

    //settings
    this.companyRewardType = companyRewardType;
    this.voucherProductId = voucherProductId;
    this.validFrom = validFrom;
    this.hideOnApi = hideOnApi;
    this.companyLevel = companyLevel;
    this.companyScoutSetting = companyScoutSetting;
    this.companySubmission = companySubmission;
    // this.submission = submission;
  }
}
