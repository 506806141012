import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Language } from "./language.model";

@DataConversion()
export class EventSequenceDescription {
  @Required()
  public name: string;
  @Optional()
  public description: string;
  @Optional()
  public location: string;
  @Optional()
  public language: Language;
}
