import {
  DataConversion,
  Required,
  Optional,
  ArrayType,
} from "@incert/data-conversion";
import { EventDate } from "./event-date.model";
import { Product } from "./product.model";
import { EventReservation } from "./event-reservation.model";
import { EventReservationProductParticipant } from "./event-reservation-product-participant.model";
import { Slot } from "./slot.model";

@DataConversion()
export class EventReservationProduct {
  @Required()
  id: number;

  @Optional()
  reservation: EventReservation;

  @Required()
  eventDate: EventDate;

  @Optional()
  slot: Slot;

  @Optional()
  product: Product;

  @Required()
  quantity: number;

  @Optional()
  @ArrayType(() =>
    import("./event-reservation-product-participant.model").then(
      (mod) => mod.EventReservationProductParticipant,
    ),
  )
  participants: EventReservationProductParticipant[];

  @Optional()
  participantStates: string[];

  @Optional()
  quotaRelevantQuantity: number;

  @Optional()
  checkedInQuantity: number;
}
