import { Injectable } from "@angular/core";
import { ApiHttpAccess } from "../services";
import { Environment } from "../models/environment.model";

@Injectable()
export class EnvironmentDAO {
  public constructor(private httpAccess: ApiHttpAccess) {}

  public get() {
    return this.httpAccess.get("environment", Environment);
  }

  public postLanguage(languageCode: string) {
    return this.httpAccess.put("language/" + languageCode, {});
  }
}
