import { Injectable } from "@angular/core";
import { ApiHttpAccess } from "../http/api-http-access.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ApiHttpError } from "../http/api-http.error";
import { PasswordResetModel } from "./password-reset.model";
import { RequestPasswordResetModel } from "./request-password-reset.model";
import { PasswordRuleModel } from "./password-rule.model";
import { PasswordResetTokenInfoModel } from "./password-reset-token-info.model";

@Injectable({ providedIn: "root" })
export class PasswordResetService {
  private rules: PasswordRuleModel[] = null;

  public constructor(private apiHttpAccess: ApiHttpAccess) {}

  public async getTokenData(token: string) {
    return this.apiHttpAccess.get(
      "passwordReset/token/" + token,
      PasswordResetTokenInfoModel,
    );
  }

  /**
   * Resets the password of the current user
   * @param request the data
   */
  public async resetPassword(request: PasswordResetModel) {
    await this.apiHttpAccess.post("passwordReset/reset", request);
  }

  /**
   * Requests a password reset for the given admin user
   * @param request the data
   */
  public async requestPasswordResetAdmin(request: RequestPasswordResetModel) {
    await this.apiHttpAccess.post("passwordReset/admin", request);
  }

  public async getRules() {
    if (this.rules !== null) {
      return this.rules;
    }

    this.rules = await this.apiHttpAccess.get<PasswordRuleModel[]>(
      "passwordReset/rules",
      PasswordRuleModel,
    );
    return this.rules;
  }
}
