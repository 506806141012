import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { ShoppingCartRuleModel } from "./shopping-cart-rule.model";
import { Language } from "./language.model";

@DataConversion()
export class ShoppingCartRuleDescriptionModel {
  @Optional()
  public rule: ShoppingCartRuleModel;

  @Optional()
  public language: Language;

  @Required()
  public infoText?: string;

  @Required()
  public errorText?: string;

  @Required()
  public infoTextForce?: number;
}
