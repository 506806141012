import { inject, InjectionToken } from "@angular/core";
import { LanguageFormBuilder } from "@incert/incert-gui";
import { UntypedFormBuilder } from "@angular/forms";

export const GMS_FRONTEND_LFB = new InjectionToken("GMS_FRONTEND_LFB", {
  providedIn: "root",
  factory: () => {
    return new LanguageFormBuilder(inject(UntypedFormBuilder), []);
  },
});
export const GMS_ADMIN_LFB = new InjectionToken("GMS_ADMIN_LFB", {
  providedIn: "root",
  factory: () => {
    return new LanguageFormBuilder(inject(UntypedFormBuilder), []);
  },
});
export const GMS_COMBINED_LFB = new InjectionToken("GMS_COMBINED_LFB", {
  providedIn: "root",
  factory: () => {
    return new LanguageFormBuilder(inject(UntypedFormBuilder), []);
  },
});
