import {
  DataConversion,
  Required,
  Optional,
  ArrayType,
} from "@incert/data-conversion";
import { GenderDescription } from "./gender-description.model";

@DataConversion()
export class Gender {
  @Required()
  id: number;

  @Required()
  value: string;

  @Required()
  sortOrder: number;

  @Optional()
  name: string;

  @Optional()
  active = false;

  @Required()
  @ArrayType(() =>
    import("./gender-description.model").then((mod) => mod.GenderDescription),
  )
  descriptions: GenderDescription[];
}
