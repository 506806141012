import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  ComponentConfiguration,
  DataTableConfig,
  DataTableHoverTextComponent,
  DynamicColumn,
  isDataSource,
  isDynamicColumn,
  isPropertyColumn,
  markAsDirty,
  PropertyColumn,
} from "@incert/incert-gui";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MenuItem } from "primeng/api";
import { CsvExporter } from "../exporter/csv-exporter";
import { JsonExporter } from "../exporter/json-exporter";
import {
  download,
  getPropertyPath,
  getPropertyPathValue,
  IconType,
  NotificationService,
} from "@incert/incert-core";
import { ExporterInterface } from "../exporter/exporter.interface";
import { XlsxExporter } from "../exporter/xlsx-exporter";
import { DataTableColumnOverlayComponent } from "../data-table-column-overlay/data-table-column-overlay.component";
import { I18nService } from "@incert/i18n";
import { DataTablePersistence } from "../shared/interfaces/data-table-persistence.interface";

@Component({
  selector: "data-table-export",
  templateUrl: "./data-table-export-overlay.component.html",
  styleUrls: ["./data-table-export-overlay.component.css"],
})
export class DataTableExportOverlayComponent implements OnInit {
  @ViewChild("columnOverlayComponent")
  columnOverlayComponent: DataTableColumnOverlayComponent;
  @Input()
  label: string;
  @Input()
  data: any;
  @Input()
  public config: DataTableConfig<any>;

  public formGroup: UntypedFormGroup;
  public columns: (PropertyColumn<any> | DynamicColumn<any>)[] = [];
  public persistence: DataTablePersistence = null;
  public exportMenuItems: MenuItem[] = [
    {
      label: "csv",
      title: "CSV",
      icon: IconType.csvFile,
      command: () => this.exportLocal(new CsvExporter()),
    },
    {
      label: "json",
      title: "JSON",
      icon: IconType.json,
      command: () => this.exportLocal(new JsonExporter()),
    },
    {
      label: "excel",
      title: "Excel",
      icon: IconType.xlsFile,
      command: () => this.exportLocal(new XlsxExporter()),
    },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private i18n: I18nService,
  ) {
    this.formGroup = this.fb.group({
      exportType: new UntypedFormControl("csv", [Validators.required]),
      exportEmail: new UntypedFormControl("", [Validators.required]),
    });
  }

  async ngOnInit() {
    const columns = this.config.columns.map((x) => Object.assign({}, x));

    if (this.persistence?.config?.exportColumns?.length > 0) {
      columns.map((v) => {
        if (isPropertyColumn(v)) {
          const found = this.persistence.config.exportColumns.find(
            (property) => {
              if (
                JSON.stringify(property) ===
                JSON.stringify(getPropertyPath(v.property))
              ) {
                return true;
              }
            },
          );
          v.hidden = !found;
          this.columns.push(v);
        }
      });
    } else {
      columns.map((v) => {
        if (isPropertyColumn(v)) {
          v.hidden = false;
          this.columns.push(v);
        }
      });
    }

    if (this.config.additionalExportColumns) {
      for (const additionalCol of this.config.additionalExportColumns) {
        this.columns.push(additionalCol);
      }
    }
  }

  async exportLocal(exporter: ExporterInterface | null = null) {
    if (exporter === null) {
      exporter = new CsvExporter();
    }
    if (isDataSource(this.config.data)) {
      this.notificationService.notifyInfo(
        this.i18n.instant("core.dataTable.exportStarted"),
      );
      //Handle Persistence for Export Columns
      const columns = this.columns.filter((v) => !v.hidden);
      if (this.config.persistence) {
        this.persistence.config.exportColumns = columns.map(
          (v: PropertyColumn<any>) => getPropertyPath(v.property),
        );
        await this.config.persistence.persistenceService.updateDataTablePersistence(
          this.persistence,
        );
      }
      //Export Data
      const rawData = await this.config.data.export();
      const propertyColumns = columns;
      const headers = propertyColumns.map((col) =>
        typeof col.header === "string" ? col.header : col.header.label,
      );

      const data = rawData.map((v) =>
        propertyColumns.map((col) => {
          let val = "";
          if (isPropertyColumn(col)) {
            val = getPropertyPathValue(v, col.property);
            if (col.exportValue) {
              return col.exportValue(val, v) ?? "";
            } else if (col.transform) {
              val = col.transform(val, v);
            }
          }
          if (isDynamicColumn(col)) {
            if (col.exportValue) {
              return col.exportValue(val, v) ?? "";
            } else if (
              (col.component as ComponentConfiguration<any>)?.component ===
              DataTableHoverTextComponent
            ) {
              val = (
                col.component as ComponentConfiguration<DataTableHoverTextComponent>
              ).properties.resolveRowText(v)?.text;
            }
          }
          return val ?? "";
        }),
      );
      download(
        exporter.export(data, headers),
        "download." + exporter.extension,
        "application/text",
      );
    }
  }

  async exportEmail() {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      const request = {};
      request["email_address"] = this.formGroup.get("exportEmail");
      request["subject"] = "table_header";
      request["html"] = "table_html";
      request["txt"] = "txt";
      this.notificationService.notifySuccess(
        "Die Datei wurde erfolgreich versandt!",
      );
    }
  }
}
