import { Inject, Injectable } from "@angular/core";
import { Environment } from "../models/environment.model";
import { sleep } from "@incert/incert-core";
import { Subject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { ENVIRONMENT } from "./environment.token";

interface GmsWsAction {
  action: string;
  data?: any;
}

@Injectable({ providedIn: "root" })
export class GmsWsService {
  private _socket: WebSocket = null;
  private _error = false;
  private _msg$ = new Subject<any>();
  private _initialConnectPromise: Promise<boolean>;

  public get error() {
    return this._error;
  }

  constructor(@Inject(ENVIRONMENT) private environment: Environment) {}

  private async connect(): Promise<boolean> {
    return new Promise((resolve) => {
      //todo: websockets are only enabled for testsystems at the moment
      if (false && this.environment.partId.includes("dev")) {
        const socket = new WebSocket(
          "wss://ws.incert.at?partId=" +
            this.environment.partId +
            "&sessionId=" +
            this.environment.sessionId,
        );
        socket.onmessage = (e) => {
          try {
            this._msg$.next(JSON.parse(e.data));
          } catch (e) {
            console.error(e);
          }
        };
        socket.onopen = () => {
          this._error = false;
          this._socket = socket;
          resolve(true);
        };
        socket.onerror = async (e) => {
          console.error(e);
          if (socket.readyState !== WebSocket.CLOSED) {
            socket.close();
          }
          this._socket = null;
          this._error = true;
          await sleep(2000);
          this.connect();
          resolve(false);
        };
      } else {
        resolve(false);
      }
    });
  }

  observe(action?: string) {
    this.initialConnect();
    if (!action) {
      return this._msg$.asObservable();
    }
    return this._msg$.pipe(
      filter((v) => v.action === action),
      map((v) => v.data),
    );
  }

  send(data: GmsWsAction) {
    this.initialConnect().then((v) => {
      if (v) {
        this._socket.send(JSON.stringify(data));
      }
    });
  }

  private async initialConnect() {
    if (!this._initialConnectPromise) {
      this._initialConnectPromise = this.connect();
    }
    return await this._initialConnectPromise;
  }
}
