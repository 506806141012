import { Component, OnInit } from "@angular/core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  SelectFilterComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { Resource, ResourceType } from "./shared/resource.model";
import {
  ConfirmService,
  LoadingService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { AuthManager } from "../../../core/auth";
import { ResourceService } from "./shared/resource.service";
import { ResourceUpsertComponent } from "./resource-upsert/resource-upsert.component";

@Component({
  selector: "incert-resource",
  templateUrl: "./resource.component.html",
  styleUrls: ["./resource.component.css"],
})
export class ResourceComponent implements OnInit {
  dtConfig: DataTableConfig<Resource>;
  resources: Resource[] = [];
  resourceTypes: ResourceType[] = [];
  selectItems: any[] = [];

  constructor(
    private overlayService: OverlayService,
    private loadingService: LoadingService,
    private confirmService: ConfirmService,
    private resourceService: ResourceService,
    private authManager: AuthManager,
  ) {}

  async ngOnInit() {
    await this.loadingService.load(async () => {
      await this.init();
    });
  }

  private async init() {
    this.resources = await this.resourceService.getResources();
    this.resourceTypes = await this.resourceService.getResourceTypes();
    await this.createDtConfig();
    this.selectItems = await this.resourceService.getSelectItems();
  }

  private async createDtConfig() {
    const arrayDataSource = new ArrayDataSource(this.resources);
    this.dtConfig = {
      mode: "pagination",
      rows: 15,
      data: arrayDataSource,
      filterArea: {
        filters: [
          {
            header: "Kürzel",
            property: (v) => v.get("short"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Typ",
            property: (v) => v.get("resourceType", "type"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: this.resourceTypes.map((v) => {
                return {
                  label: v.type,
                  value: v.type,
                };
              }),
            }),
          },
          {
            header: "Gruppe",
            property: (v) => v.get("resourceType", "group"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: [
                { label: "link", value: "link" },
                { label: "image", value: "image" },
                { label: "zendeskRef", value: "zendeskRef" },
              ],
            }),
          },
        ],
      },
      columns: [
        {
          header: "Kürzel",
          property: (v) => v.get("short"),
          sort: true,
        },
        {
          header: "Typ",
          property: (v) => v.get("resourceType", "type"),
          sort: true,
        },
        {
          header: "Gruppe",
          property: (v) => v.get("resourceType", "group"),
          sort: true,
        },
        {
          header: "Aktionen",
          hidden: !this.readResource,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  hidden: !this.writeResource,
                  icon: "edit",
                  onClick: (row: Resource) => this.overlayUpsert(row),
                },
                {
                  hidden: !this.executeResource,
                  icon: "delete",
                  onClick: (row: Resource) => this.delete(row),
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              hidden: !this.writeResource,
              label: "Ressource",
              icon: "plus",
              onClick: () => this.overlayUpsert(),
            },
          ],
        }),
      ],
    };
  }

  public async overlayUpsert(row: Resource = null) {
    await this.overlayService.show<ResourceUpsertComponent>({
      type: ResourceUpsertComponent,
      header: row ? "Ressource bearbeiten" : "Ressource hinzufügen",
      size: OverlaySize.large,
      displayAsSidebar: true,
      init: async (component) => {
        component.resourceId = row?.id;
        component.typeSI = this.selectItems["type"];
        component.resourceTypes = this.resourceTypes;
      },
      actions: [
        {
          buttonType: "primary",
          label: "Speichern",
          action: async (component) => {
            if (await component.upsertResource()) {
              await this.ngOnInit();
              return true;
            }
            return false;
          },
        },
        {
          label: "Abbrechen",
          action: () => true,
          displayAsLink: true,
        },
      ],
    });
  }

  public async delete(resource: Resource) {
    const response = await this.confirmService.confirmError(
      "",
      "Ressource löschen",
    );
    if (response) {
      await this.resourceService.deleteResource(resource.id);
      await this.ngOnInit();
    }
  }

  public get readResource(): boolean {
    return this.authManager.hasPermission("readResource");
  }

  public get writeResource(): boolean {
    return this.authManager.hasPermission("writeResource");
  }

  public get executeResource(): boolean {
    return this.authManager.hasPermission("executeResource");
  }
}
