import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { ApiHttpAccess } from "./http/api-http-access.service";
import { TaxClassListEntry } from "../models";
import { map } from "rxjs/operators";
import { NumberFormatPipe } from "@incert/incert-gui";

@Injectable({ providedIn: "root" })
export class TaxClassService {
  private _taxClassListEntries$ = new ReplaySubject<TaxClassListEntry[]>(1);
  private _taxClassListEntriesInit = false;

  public constructor(
    private httpAccess: ApiHttpAccess,
    private numberFormatPipe: NumberFormatPipe,
  ) {}

  public refreshTaxClassListEntries() {
    this.httpAccess
      .get<TaxClassListEntry[]>("taxClasses", TaxClassListEntry)
      .then((v) => this._taxClassListEntries$.next(v));
  }

  public get taxClassListEntries$() {
    if (!this._taxClassListEntriesInit) {
      this.refreshTaxClassListEntries();
      this._taxClassListEntriesInit = true;
    }
    return this._taxClassListEntries$.asObservable();
  }

  public get formattedTaxClassListEntries$() {
    return this.taxClassListEntries$.pipe(
      map((value) =>
        value.map((value) => {
          const taxClass = new TaxClassListEntry();
          Object.assign(taxClass, value);

          if (taxClass.isMixedTax) {
            taxClass.taxRateString = "";
          } else {
            const taxRates = taxClass.taxRates.map(
              (tr) => this.numberFormatPipe.transform(tr.rate, "1.2-2") + "%",
            );
            taxClass.taxRateString = [...new Set(taxRates)].join(", ");
          }

          return taxClass;
        }),
      ),
    );
  }
}
