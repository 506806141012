import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Language } from "./language.model";

@DataConversion()
export class OpeningTimeEntryDescription {
  @Required()
  language: Language;

  @Optional()
  description: string;
}
