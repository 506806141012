import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class TouristAttractionUserModel {
  @Optional()
  uid: number;
  @Optional()
  pid: number;
  @Optional()
  username: string;
  @Optional()
  address: string;
  @Optional()
  deleted: number;
  @Optional()
  email: string;
  @Optional()
  starttime: number;
  @Optional()
  endtime: number;
  @Optional()
  fax: string;
  @Optional()
  firstName: string;
  @Optional()
  lastName: string;
  @Optional()
  middleName: string;
  @Optional()
  name: string;
  @Optional()
  password: string;
  @Optional()
  subsidiary: number;
  @Optional()
  telephone: string;
  @Optional()
  telephoneNumber: number;
  @Optional()
  tstamp: number;
}
