import { Optional } from "@angular/core";
import { Required, DataConversion } from "@incert/data-conversion";

@DataConversion()
export class OptionValueCurrencyPrice {
  @Required()
  currency: {
    id: number;
  };

  @Optional()
  optionValueId: number;

  @Required()
  defaultPrice: number;
}
