import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { Customer } from "./customer.model";
import { EventDate } from "./event-date.model";
import { EventProductAssignmentEntry } from "./event-product-assignment-entry.model";

@DataConversion()
export class EventProductAssignment {
  @Optional()
  customer: Customer;
  @Optional()
  eventDate: EventDate;
  @Optional()
  @ArrayType(() =>
    import("./event-product-assignment.model").then(
      (mod) => mod.EventProductAssignment,
    ),
  )
  eventProductAssignmentEntries: EventProductAssignmentEntry[];
  @Required()
  id: number;
  @Optional()
  expirationDate: Date;
  @Optional()
  paymentUuid: string;
  @Required()
  status: string;
  @Required()
  hasToBuy: boolean;

  public getAssignmentEntryByProductId(productId: number) {
    return this.eventProductAssignmentEntries.find(
      (v) => v.product.id === productId,
    );
  }
}
