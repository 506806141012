import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class AccessPermissionModel {
  @Optional()
  public id: number;
  @Optional()
  public manufacturerId: number;
  @Optional()
  public allowedProductTypes: string;
  @Optional()
  public allowedProducts: string;
  @Optional()
  public allowedShippingModule: string;
  @Optional()
  public allowedPaymentModule: string;
  @Optional()
  public checkPinOnStatusRequest: boolean;
  @Optional()
  public checkPinOnRedeemRequest: boolean;
}
