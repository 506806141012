import { MockHttpAccessRequest } from "@incert/incert-core";

export function getVoucherRedeemVoucher(
  voucherCode = "TESTCODE",
): MockHttpAccessRequest {
  return {
    method: "GET",
    route: "voucherRedeem/voucher/" + voucherCode,
    resp: {
      voucher_data: {
        voucher_code: voucherCode,
        product_id: "1",
        amount: "50.0000",
        validthru: "2024-12-21",
        text_message: "TESTTESTTEST <h1>TEST </h1>",
        customer_id: "4736",
        order_id: "2460",
        voucher_created: "2022-12-22 14:28:36",
        o_status: "10",
        type: "2",
        products_name: "incert-Hotel Wertgutschein",
        orders_products_id: "3309",
        manufacturers_id: "48",
        block_different_man_redemption: "0",
        show_desc_on_redemption: "0",
        products_tax: "0.0000",
        allow_tax: "1",
        products_model: "9501",
        last_redeem_reserv_nr: null,
        event_id: "0",
        amount_left: 50,
        manufacturers_name: "Philipp Partner",
        manufacturers_shop_id: "",
        store_name: "Demo Professional Gutscheinsystem (003)",
        attributes: {
          "": {
            orders_products_id: "3309",
            orders_id: "2460",
            products_id: "1",
            products_name: "incert-Hotel Wertgutschein",
            products_price: "50.0000",
            orders_products_attributes_id: null,
            products_options: null,
            products_options_values: null,
            opa_name: null,
            options_values_price: null,
            opa_price_prefix: null,
            opa_quantity: null,
            opa_red_quantity: null,
            opa_man_id: null,
            attributes_model: null,
            redeemable: null,
            tax_class: "0",
            products_tax: "0.0000",
            show: 0,
          },
        },
        allow_cancel: true,
        is_card: false,
        order_comment: {
          memo_create_staff: [],
          memo_man_name: [],
          memo_title: [],
          memo_text: [],
          memo_date: [],
          comment: "",
          comment_date: "2022-12-22 14:28:36",
          comment_redeem_station: "Admin ",
        },
      },
      customer_data: {
        customers_name: "Marvin Hengster",
        customers_firstname: "Marvin",
        customers_lastname: "Hengster",
        customers_email_address: "marvin.hengster@incert.at",
        customers_telephone: "+4366000000000",
        customers_street_address: "Leonfeldnerstra\u00dfe 328",
        customers_city: "marvin.hengster@incert.at",
        customers_postcode: "4040",
        customers_address:
          "Leonfeldnerstra\u00dfe 328, 4040 marvin.hengster@incert.at",
        customers_dob: "0000-00-00 00:00:00",
      },
      order_data: {
        orders_id: "2460",
        orders_types: "1",
        currency: "EUR",
        date_purchased: "2022-12-22 14:28:36",
        billingdate: null,
        payment_class: "moneyorder",
        shipping_class: "selfpickup_selfpickup",
        orders_status: "1",
        language: "german",
        orders_status_name: "Offen - Vorauskasse",
        comments: "",
        rksv_status: "nicht_erforderlich",
        rksv_qr_code: "",
        staff_id: "3",
        super_man_id: "1",
        reception_staff: "Admin ",
        shop_id: "PROFESSIONAL",
      },
      _origin: 0,
      history_data: [],
      permission: {
        download_send_voucher: true,
        send_voucher: true,
        input_reservation_nr: true,
        input_redeemer_name: false,
        redeemer_name_required: false,
        reserv_nr_required: false,
        show_reception_staff: false,
        show_super_manufacturer: false,
        activate_update_entries: true,
        change_redemption_date: false,
        input_comment: true,
        change_redeem_station: true,
        show_different_redeemer_station_names: true,
        show_reedem_entries: true,
        full_redemption: true,
        partial_redemption: true,
        cancel_voucher: true,
        show_orders_details: true,
        show_redeem_history: true,
        show_customer_data: true,
        show_payment_info: true,
        update_entries: true,
        show_payment_method: false,
        show_shipping_method: false,
        input_inclusives: false,
        show_amount_left: true,
        lookup_orders: true,
        link_to_campaign: true,
        voucher_inclusives_redemption: false,
        unredeem: true,
        quick_redemption: true,
        CHANGE_REDEMPTION_DATE_LIMIT: false,
        card_range_activation: true,
        card_range_redemption: true,
        card_activate_redeem_v: true,
        edit_man_bank_data: true,
        voucher_exchange: false,
        lock_voucher: true,
        unlock_voucher: true,
        change_redeem_station_options: [
          { id: "100", text: "DEPLOYMENT_TAG_sub" },
          {
            id: "91",
            text: "Final_TEST_sub",
          },
          { id: "53", text: "Frontend API Test" },
          { id: "66", text: "Hotel London" },
          {
            id: "67",
            text: "Hotel Munich",
          },
          { id: "68", text: "Hotel Vienna" },
          { id: "89", text: "manufacturers_name_1646920175057" },
          {
            id: "51",
            text: "Philipp Testhotel",
          },
          { id: "92", text: "Test1" },
        ],
        ACTIVATE_REDEMPTION_CONFIRMATION: true,
        ORDER_ON_REDEMPTION: true,
        QUICK_ORDER_ON_REDEMPTION: false,
        QUICK_CARD_RANGE_ORDER_ON_REDEMPTION: true,
        ACTIVATE_ON_REDEMPTION: "true",
        ACTIVATE_ON_REDEMPTION_RESTRICT_TO_ASSIGNED: "true",
        redemption_confirmation_available: false,
        lockForEnabled: false,
        lockUntilEnabled: false,
        cardActivationRedeemStations: [{ id: 5, text: "Bereich Hotel" }],
        overbooking_max_increment_in_percent: 100,
        SHOW_ORDERS_COMMENT_ON_REDEMPTION: true,
        SHOW_MAN_BANK_DATA: true,
        edit_order_comment: true,
        update_redeem_entry: true,
      },
      order_status_history: [],
    },
  };
}
