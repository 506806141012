import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class TrackingSettingModel {
  @Required()
  id: number;

  @Required()
  short: string;

  @Required()
  value: string;

  @Required()
  depictionType: any;

  @Required()
  isExtended: boolean;
}
