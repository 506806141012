import {
  ArrayType,
  DataConversion,
  DateConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { EventSequence } from "./event-sequence.model";
import { EventProduct } from "./event-product.model";
import { EventDateDescription } from "./event-date-description.model";

@DataConversion()
export class EventDate {
  @Required()
  id: number;
  @Optional()
  eventSequence: EventSequence;
  @Required()
  eventSequenceId: number;
  @Required()
  name: string;
  @Required()
  description: string;
  @Required()
  state: string;
  @Required()
  @DateConversion()
  start: Date;
  @Required()
  duration: number;
  @Optional()
  saleStart: Date;
  @Optional()
  saleEnd: Date;
  @Optional()
  location: string;
  @Optional()
  address: string;
  @Optional()
  categoryId: number;
  @Optional()
  quota: number;
  @Optional()
  productSeasonId: number;
  @Required()
  waitingListEnabled: boolean;
  @Optional()
  lastUpdate: Date;
  @Required()
  notSaleableOnline: boolean;
  @Optional()
  assignedCustomersStatus: number;
  @Optional()
  available: number;
  @ArrayType(() =>
    import("./event-product.model").then((mod) => mod.EventProduct),
  )
  eventProducts: EventProduct[];
  @Optional()
  @ArrayType(() =>
    import("./event-date-description.model").then(
      (mod) => mod.EventDateDescription,
    ),
  )
  eventDateDescriptions: EventDateDescription[];

  @Optional()
  public end() {
    let endTime = new Date(this.start).getTime();
    endTime = endTime + this.duration * 1000;
    return new Date(endTime);
  }

  @Optional()
  public getDurationByEndTime(endTime: Date) {
    return (endTime.getTime() - this.start.getTime()) / 1000;
  }
}
