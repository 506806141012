import { DataConversion, Required } from "@incert/data-conversion";
import { Optional } from "@angular/core";

@DataConversion()
export class CodeRedemptionRestrictionThemeModel {
  @Required()
  id: number;

  @Optional()
  categoryName: string;

  @Required()
  categoryId: number;

  @Required()
  inherit: boolean;

  @Required()
  allow: boolean;

  @Required()
  active: boolean;
}
