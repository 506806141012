import {
  Directive,
  AfterContentChecked,
  AfterViewChecked,
} from "@angular/core";
import { AutoFocus } from "primeng/autofocus";

@Directive({
  selector: "[incAutoFocus]",
  standalone: true,
})
export class AutoFocusDirective
  extends AutoFocus
  implements AfterContentChecked, AfterViewChecked
{
  ngAfterContentChecked(): void {
    this.resetFocused();
    super.ngAfterContentChecked();
  }

  ngAfterViewChecked(): void {
    this.resetFocused();
    super.ngAfterViewChecked();
  }

  private resetFocused() {
    if (!this.autofocus && this.focused) {
      this.focused = false;
    }
  }
}
