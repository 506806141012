import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError, from } from "rxjs";
import { switchMap } from "rxjs/operators";

import { AccessTokenService } from "../access-token.service";

@Injectable()
export class DwhHttpInterceptorService implements HttpInterceptor {
  public constructor(public accessTokenService: AccessTokenService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.toString().indexOf("dwh-api") !== -1) {
      return from(this.accessTokenService.getRequestToken()).pipe(
        switchMap((token) => {
          const headers = req.headers
            .set("Authorization", "Bearer " + token)
            .append("Content-Type", "application/json");
          const requestClone = req.clone({
            headers,
          });
          return next.handle(requestClone);
        }),
      );
    }

    return next.handle(req);
  }
}
