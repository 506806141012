import { DataConversion, Required, Optional } from "@incert/data-conversion";

@DataConversion()
export class ProductOptionValue {
  @Required()
  id: number;

  @Optional()
  name: string;

  @Optional()
  description: string;

  @Optional()
  price: number;

  @Optional()
  default_stock_quantity: number;

  @Optional()
  sortOrder: number;
}
