import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardComponent } from "./dashboard.component";
import { GridsterModule } from "angular-gridster2";
import { PanelModule } from "primeng/panel";
import { ButtonModule } from "primeng/button";
import { DashboardAddWidgetComponent } from "./dashboard-add-widget/dashboard-add-widget.component";
import { ReactiveFormsModule } from "@angular/forms";
import { CardModule } from "primeng/card";
import { WidgetHostComponent } from "./widget-host/widget-host.component";
import { MessageModule } from "primeng/message";
import { FormExtensionModule, TooltipModule } from "@incert/incert-gui";
import {
  IconButtonModule,
  IncertGUIModule,
  LoadingWrapperModule,
} from "@incert/incert-gui";
import { IncertCoreModule, OverlayService } from "@incert/incert-core";
import { GoogleChartService } from "./google-charts/google-chart.service";
import { DashboardLocalStoragePersistor } from "./shared/persistor/dashboard-local-storage-persistor.service";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { DefaultInvalidWidgetPlaceholderComponent } from "./default-invalid-widget-placeholder/default-invalid-widget-placeholder.component";
import { WidgetConfigurationHostComponent } from "./shared/widget-configuration/widget-configuration-host.component";
import { I18nModule } from "@incert/i18n";
import { DropdownModule } from "primeng/dropdown";
import { SidebarModule } from "primeng/sidebar";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { InputTextModule } from "primeng/inputtext";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { AccordionModule } from "primeng/accordion";
import { DragDropModule } from "primeng/dragdrop";
import { MessagesModule } from "primeng/messages";

@NgModule({
  imports: [
    CommonModule,
    GridsterModule,
    PanelModule,
    ButtonModule,
    DropdownModule,
    ReactiveFormsModule,
    CardModule,
    SidebarModule,
    IncertGUIModule,
    IncertCoreModule,
    FormExtensionModule,
    MessageModule,
    ScrollPanelModule,
    InputTextModule,
    AutoCompleteModule,
    CalendarModule,
    AccordionModule,
    DragDropModule,
    VirtualScrollerModule,
    I18nModule,
    TooltipModule,
    LoadingWrapperModule,
    IconButtonModule,
    MessagesModule,
  ],
  declarations: [
    DashboardComponent,
    DashboardAddWidgetComponent,
    WidgetHostComponent,
    DefaultInvalidWidgetPlaceholderComponent,
    WidgetConfigurationHostComponent,
  ],
  exports: [DashboardComponent],
  providers: [
    OverlayService,
    GoogleChartService,
    DashboardLocalStoragePersistor,
  ],
})
export class DashboardModule {
  public constructor() {}
}
