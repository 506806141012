import { DataConversion, Required } from "@incert/data-conversion";
import { Optional } from "@angular/core";
import { InputConfig } from "./input-config.model";

@DataConversion()
export class ConfigurationGroup {
  @Required()
  id: number;

  @Optional()
  title: string;

  @Required()
  description: string;

  @Optional()
  visible: boolean;

  @Optional()
  parent?: ConfigurationGroup;

  @Optional()
  children?: ConfigurationGroup[];

  @Optional()
  inputConfigs?: InputConfig[] = [];
}
