import { inject, InjectionToken } from "@angular/core";
import { HttpAccess } from "@incert/incert-core";
import { HttpClient } from "@angular/common/http";

export const DWH_HTTP_ACCESS = new InjectionToken<HttpAccess>(
  "DWH_HTTP_ACCESS",
  {
    providedIn: "root",
    factory: () => {
      const httpAccess = new HttpAccess(inject(HttpClient));
      httpAccess.baseHref = "https://dwh-api.incert.at/dwh-api/";
      return httpAccess;
    },
  },
);
