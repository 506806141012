import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { ProductOption } from "./product-option.model";

@DataConversion()
export class ProductAttribute {
  @Required()
  id: number;

  @Optional()
  @ArrayType(() =>
    import("./product-option.model").then((mod) => mod.ProductOption),
  )
  productOptions: ProductOption[];

  public getProductOptionByLanguageId(languageId: number) {
    return this.productOptions.find((po) => po.language.id === languageId);
  }
}
