<div class="widget-shop">
  <div class="widget-shop-search grid">
    <div class="col-12 md:col-3"></div>
    <div class="col-12 md:col-9">
      <h2>Dashboard Center</h2>
      <form [formGroup]="form">
        <input type="text" pInputText [style]="{width:'100%'}"
                        formControlName="filter"
                        [placeholder]="'core.action.search' | i18n"
                       >

      </form>
    </div>
  </div>
  <div class="grid">
    <div class="widget-shop-categories col-12 md:col-3">
      <ul>
        <li *ngFor="let item of dashboardConfigService.widgetCategoryTree;let i = index">
          <a href="javascript:void(0);" (click)="setMainCategoryNode(i)" [ngClass]="{'active-widget-shop-category':this.mainCategoryIndex === i}">{{item.title}} ({{item.widgetAmount}})</a>
        </li>
      </ul>
    </div>
    <div class="widget-shop-items col-12 md:col-9">
      <p-scrollPanel [style]="{width: '100%', height: '100%'}">
        <ng-container *ngTemplateOutlet="subMenuSwitch;context:{$implicit:filteredTreeNode}"></ng-container>
      </p-scrollPanel>
    </div>
  </div>
</div>


<ng-template #subMenuSwitch let-node>
  <ng-container *ngTemplateOutlet="node.hasWidgets ? widgetList : subMenuList;context:{$implicit:node}"></ng-container>
</ng-template>


<ng-template #subMenuList let-node>

  <ng-container *ngFor="let item of node.children">
    <ng-container *ngIf="item.level == 1; else otherLevel">
      <h3> {{item.title}}</h3>
    </ng-container>
    <ng-template #otherLevel>
      <h4> {{item.title}}</h4>
    </ng-template>
    <ng-container *ngTemplateOutlet="subMenuSwitch;context:{$implicit:item}"></ng-container>
  </ng-container>
</ng-template>

<ng-template #widgetList let-node>
  <div class="grid">
    <div class="col-12 md:col-6 lg:col-4" *ngFor="let item of node.children">
      <div class="widget-shop-item">
        <div class="widget-shop-item-inner">
          <div class="widget-shop-item-content">
            <img src="{{item.image}}">
          </div>
          <div class="widget-shop-item-information">
          <span class="widget-shop-item-title">
                  {{item.title}}
                </span>
            <p-button [label]="'dashboard.widgets.addNewWidget' | i18n" styleClass="reset-button-to-link"
                      (click)="$event.stopPropagation();addWidget.emit(item.id);widgetAddedSuccess(item.title)"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

