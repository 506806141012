import { ApiHttpErrorModel } from "./api-http.model";

export class ApiHttpError extends Error {
  private model: ApiHttpErrorModel;

  public get code() {
    return this.model.code;
  }

  public get message() {
    return this.model.message;
  }

  public get values() {
    return this.model.values || [];
  }

  public constructor(model: ApiHttpErrorModel) {
    super(model.message);
    this.model = model;

    const actualProto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      const that = this;
      that["__proto__"] = actualProto;
    }
  }
}
