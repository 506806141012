import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Customer } from "./customer.model";
import { EventDate } from "./event-date.model";
import { Product } from "./product.model";

@DataConversion()
export class EventWaitingList {
  @Required()
  id: number;
  @Required()
  sortOrder: number;
  @Required()
  eventDate: EventDate;
  @Required()
  customer: Customer;
  @Required()
  product: Product;
  @Required()
  count: number;
  @Required()
  state: string;
  @Required()
  comment: string;
}
