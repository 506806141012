import { OpeningTimeDescription } from "./opening-time-description.model";
import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { Product } from "./product.model";

@DataConversion()
export class OpeningTime {
  @Required()
  id: number;

  @Required()
  deliveryContingent: number;

  @Required()
  @ArrayType(() =>
    import("./opening-time-description.model").then(
      (mod) => mod.OpeningTimeDescription,
    ),
  )
  openingTimeDescriptions?: OpeningTimeDescription[];

  @Optional()
  @ArrayType(() => import("./product.model").then((mod) => mod.Product))
  products: Product[];
}
