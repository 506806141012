import { Injectable } from "@angular/core";
import { ScriptLoadService } from "@incert/incert-core";

declare let google: any;
ScriptLoadService;
@Injectable()
export class GoogleChartService {
  loaded = false;
  call(callback: (google: any) => void): void {
    if (!this.loaded) {
      google.charts.load("current", {
        packages: ["corechart", "bar"],
        mapsApiKey: "AIzaSyCbA-VFrHQp3eGTrdYJTkLfm5UH14ELIuM",
      });
      google.charts.setOnLoadCallback(() => {
        callback(google);
        this.loaded = true;
      });
    } else {
      callback(google);
    }
  }
}
