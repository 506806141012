import { DataConversion, Required } from "@incert/data-conversion";
import { SocialMediaType } from "./social-media-type.model";

@DataConversion()
export class SocialMediaSettingModel {
  @Required()
  id: number;

  @Required()
  sortOrder: number;

  @Required()
  type: SocialMediaType;

  @Required()
  link: string;

  @Required()
  enabled: boolean;
}
