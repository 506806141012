import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { CustomerStatus } from "./customer-status";
import { Customer } from "./customer.model";
import { Product } from "./product.model";

@DataConversion()
export class CardOption {
  @Required()
  id: string;

  @Optional()
  initialValue: number;

  @Optional()
  maxValue: number;

  @Optional()
  minValue: number;

  @Optional()
  minChargeValue: number;

  @Optional()
  minRedeemValue: number;

  @Optional()
  initialBonusPoints: number;

  @Optional()
  masterPin: string;

  @Optional()
  productsId: number;

  @Optional()
  product: Product;

  @Optional()
  pinRequired: boolean;

  @Optional()
  isBuyable: boolean;

  @Optional()
  isChargeable: boolean;

  @Optional()
  defaultCustStatus: CustomerStatus;

  @Optional()
  defaultCust: Customer;

  @Optional()
  activateOnGeneration: boolean;

  @Optional()
  defaultCodeLength: number;

  @Optional()
  defaultPrefix: string;

  @Optional()
  defaultSuffix: string;

  @Optional()
  allowReissue: boolean;

  @Optional()
  cancelOnNewAssignment: boolean;

  @Optional()
  generateSerialCardCode: boolean;

  @Optional()
  generateCardMasterCode: boolean;

  @Optional()
  autoAssignCardCode: boolean;

  @Optional()
  cardImportAllowed: boolean;

  @Optional()
  bringInitialValueToRevenueAfterActivation: boolean;
}
