import { InjectionToken } from "@angular/core";
import { Environment } from "../models";

export const ENVIRONMENT = new InjectionToken<Partial<Environment>>(
  "GMS_ENVIRONMENT",
  {
    providedIn: "root",
    factory: () => {
      return {};
    },
  },
);
