import { MockHttpAccessRequest } from "@incert/incert-core";

export const getGmsConst: MockHttpAccessRequest = {
  route: "environment/gmsConst",
  method: "GET",
  resp: {
    productTypes: [
      {
        id: "PACKAGE_VOUCHER_TYPE_ID",
        const: "PACKAGE_VOUCHER_TYPE_ID",
        name: "Package of Services Voucher - (Complete Package of Services) ",
      },
      {
        id: "VALUE_VOUCHER_TYPE_ID",
        const: "VALUE_VOUCHER_TYPE_ID",
        name: "Value Voucher - (freely selectable Amount) ",
      },
      {
        id: "FREE_VOUCHER_TYPE_ID",
        const: "FREE_VOUCHER_TYPE_ID",
        name: "Freely Definable Voucher - (freely selectable Services/Amount) ",
      },
      {
        id: "COUPON_VOUCHER_TYPE_ID",
        const: "COUPON_VOUCHER_TYPE_ID",
        name: "Discount Voucher - (Reduction/Discount) ",
      },
      {
        id: "WIN_VOUCHER_TYPE_ID",
        const: "WIN_VOUCHER_TYPE_ID",
        name: "Lottery Scratch Ticket - (Raffle/Coupon) ",
      },
      {
        id: "VALUE_CARD_ID",
        const: "VALUE_CARD_ID",
        name: "Prepaid Card - (Rechargeable) ",
      },
      { id: "FACEBOOK_COMV_ID", const: "FACEBOOK_COMV_ID", name: null },
      {
        id: "FACEBOOK_VALV_ID",
        const: "FACEBOOK_VALV_ID",
        name: null,
      },
      { id: "FACEBOOK_COUP_ID", const: "FACEBOOK_COUP_ID", name: null },
      {
        id: "MERCHANT_PROD_ID",
        const: "MERCHANT_PROD_ID",
        name: "Material Products",
      },
      {
        id: "MARKETING_VOUCHER_TYPE_ID",
        const: "MARKETING_VOUCHER_TYPE_ID",
        name: "Promotion Voucher ",
      },
      {
        id: "CLAIM_VOUCHER_TYPE_ID",
        const: "CLAIM_VOUCHER_TYPE_ID",
        name: "Complaint Voucher",
      },
      { id: "IMPORTED_VOUCHER_ID", const: "IMPORTED_VOUCHER_ID", name: null },
      {
        id: "ALT_GS_TEMPLATE_TYPE_ID",
        const: "ALT_GS_TEMPLATE_TYPE_ID",
        name: "Alternative Voucher Template - (Partly redeemed) ",
      },
      {
        id: "VOUCHER_BOOK_TYPE_ID",
        const: "VOUCHER_BOOK_TYPE_ID",
        name: "Voucher Booklet",
      },
      {
        id: "TICKET_TYPE_ID",
        const: "TICKET_TYPE_ID",
        name: "Ticket (Events)",
      },
      {
        id: "EXT_NO_BUY_ARTICLE_ID",
        const: "EXT_NO_BUY_ARTICLE_ID",
        name: "External not buy able product",
      },
      {
        id: "PACKAGING_ARTICLE_ID",
        const: "PACKAGING_ARTICLE_ID",
        name: "Packaging article",
      },
      {
        id: "BONUSPOINT_VOUCHER_ID",
        const: "BONUSPOINT_VOUCHER_ID",
        name: "Bonuspoint/Campaign",
      },
      {
        id: "EXTERNAL_VOUCHER_TYPE_ID",
        const: "EXTERNAL_VOUCHER_TYPE_ID",
        name: "Voucher from external Shop",
      },
      {
        id: "VOUCHER_PAYMENT_TYPE_ID",
        const: "VOUCHER_PAYMENT_TYPE_ID",
        name: "payment voucher",
      },
      {
        id: "BUNDLE_ARTICLE_TYPE_ID",
        const: "BUNDLE_ARTICLE_TYPE_ID",
        name: "Bundle",
      },
      {
        id: "ABO_TYPE_ID",
        const: "ABO_TYPE_ID",
        name: "ABO article",
      },
      { id: "RKSV_POS", const: "RKSV_POS", name: null },
      {
        id: "ABO_BUNDLE_TYPE_ID",
        const: "ABO_BUNDLE_TYPE_ID",
        name: null,
      },
      { id: "ROOM_BOOKING_TYPE_ID", const: "ROOM_BOOKING_TYPE_ID", name: null },
      {
        id: "CONTENT_TYPE_ID",
        const: "CONTENT_TYPE_ID",
        name: "Content post",
      },
      {
        id: "ADVERTISING_TYPE_ID",
        const: "ADVERTISING_TYPE_ID",
        name: "Advertisement",
      },
      {
        id: "CHARGEABLE_VOUCHER_TYPE_ID",
        const: "CHARGEABLE_VOUCHER_TYPE_ID",
        name: null,
      },
      {
        id: "GIFT_PRODUCT_TYPE_ID",
        const: "GIFT_PRODUCT_TYPE_ID",
        name: "Gift product",
      },
      {
        id: "VOUCHER_EXCHANGE_TYPE_ID",
        const: "VOUCHER_EXCHANGE_TYPE_ID",
        name: "Exchange voucher",
      },
      {
        id: "BONUS_POINT_EXCHANGE_VOUCHER_TYPE_ID",
        const: "BONUS_POINT_EXCHANGE_VOUCHER_TYPE_ID",
        name: "Points exchange voucher",
      },
    ],
    orderTypes: [
      { id: 1, name: "Online Orders" },
      { id: 2, name: "Internal Draw Outs" },
      {
        id: 4,
        name: "Campaign Draw Outs",
      },
      { id: 6, name: "Marketing orders" },
      { id: 7, name: "Sub-Shop" },
      { id: 8, name: "Event" },
    ],
    paymentModules: [
      { const: "cod", name: "Nachnahme" },
      {
        const: "moneyorder",
        name: "Vorauskasse",
      },
      { const: "mpay24_select", name: "Kreditkarte (Unzer)" },
      {
        const: "cash",
        name: "Barzahlung",
      },
      { const: "lottery", name: "Kampagne" },
      {
        const: "saferpaygw",
        name: "IGNORE: SaferPay ALT",
      },
      { const: "aconto", name: "Sponsoring" },
      {
        const: "ccstation",
        name: "Terminal - CC",
      },
      { const: "ccstationAmex", name: "Terminal - Amex" },
      {
        const: "hobex",
        name: "IGNORE: LV-Hobex",
      },
      { const: "ccstationMaestro", name: "Terminal - Maestro" },
      {
        const: "pn_sofortueberweisung",
        name: "Klarna (Sofort\u00fcberweisung)",
      },
      { const: "ccstation_ec", name: "Terminal - EC" },
      {
        const: "ccstation_msc",
        name: "Terminal - Mastercard",
      },
      { const: "ccstation_visa", name: "Terminal - Visa" },
      {
        const: "ctpaygatecc",
        name: "Kreditkarte (Computop) DE",
      },
      { const: "roombooking", name: "Zimmerrechnung" },
      {
        const: "micros",
        name: "SONSTIGE: Kassensystem",
      },
      { const: "six", name: "Kreditkarte (SIX)" },
      {
        const: "card",
        name: "Member Card",
      },
      { const: "ccstationDiners", name: "Terminal - Diners" },
      {
        const: "debitor",
        name: "SONSTIGE: Debitor",
      },
      { const: "eustandardtransfer", name: "SONSTIGE: \u00dcberweisung" },
      {
        const: "hobex_tw",
        name: "IGNORE: Hobex TW",
      },
      { const: "iclear", name: "IGNORE: iClear" },
      {
        const: "invoice",
        name: "Kauf auf Rechnung",
      },
      { const: "jumio", name: "IGNORE: Jumio" },
      {
        const: "moneybookers",
        name: "IGNORE: Moneybookers",
      },
      { const: "paypal", name: "IGNORE: PayPal" },
      {
        const: "psigate",
        name: "IGNORE: PSiGate",
      },
      { const: "qenta", name: "IGNORE: QENTA" },
      {
        const: "qenta_qpay",
        name: "Qenta qpay",
      },
      { const: "uniqaeprocure", name: "IGNORE: Uniqa eProcure" },
      {
        const: "wwp_aconto",
        name: "IGNORE: WWP-Einladung",
      },
      { const: "paypalClassic", name: "IGNORE: PayPal Classic" },
      {
        const: "shop_partner",
        name: "SONSTIGE: Shop Partner",
      },
      { const: "station", name: "Kartenaufladung" },
      {
        const: "telecash",
        name: "Kreditkarte (Telecash)",
      },
      { const: "voucher", name: "Zahlungsmittel Gutschein" },
      {
        const: "vub",
        name: "KUNDE: Kreditkarte (VUB)",
      },
      { const: "payUnity", name: "IGNORE: PayUnity ALT" },
      {
        const: "raikaVPos",
        name: "KUNDE: RaikaVPos",
      },
      { const: "reservation", name: "SONSTIGE: Reservierung" },
      {
        const: "ogone",
        name: "Kreditkarte (Ogone)",
      },
      { const: "partner", name: "SONSTIGE: Partner" },
      {
        const: "payone",
        name: "IGNORE: PayOne ALT",
      },
      { const: "datatrans", name: "Kreditkarte (Datatrans)" },
      {
        const: "innopay",
        name: "KUNDE: Kreditkarte (Innopay)",
      },
      { const: "amazonPay", name: "AmazonPay" },
      {
        const: "paypalRest",
        name: "PayPal",
      },
      { const: "hobexCopyAndPay", name: "IGNORE: hobexCopyAndPay" },
      {
        const: "newSIPS",
        name: "KUNDE: Kreditkarte (NEWSIPS)",
      },
      { const: "saferPayJsonApi", name: "Kreditkarte (SaferPay)" },
      {
        const: "wirecardElastic",
        name: "IGNORE: Wirecard Elastic",
      },
      { const: "concardis", name: "Kreditkarte (Concardis)" },
      {
        const: "copyAndPay",
        name: "Kreditkarte (CopyAndPay)",
      },
      { const: "marketing", name: "SONSTIGE: Marketing" },
      {
        const: "autoCharge",
        name: "Automatische Beladung",
      },
      { const: "stripe", name: "Kreditkarte (Stripe)" },
      {
        const: "recurring",
        name: "Wiederkehrende Zahlungen",
      },
      {
        const: "wirecardElasticHosted",
        name: "IGNORE: Wirecard Elastic Hosted",
      },
      {
        const: "payoneV2",
        name: "Kreditkarte (Payone)",
      },
      { const: "kempinskiPay", name: "KUNDE: Kreditkarte (Santral34)" },
      {
        const: "adyen",
        name: "Kreditkarte (Adyen)",
      },
      { const: "qenta_q_more", name: "qenta_q_more" },
      {
        const: "computop_hosted",
        name: "computop_hosted",
      },
      { const: "datatransV2", name: "Kreditkarte (Datatrans V2)" },
      {
        const: "voucher_exchange",
        name: "Gutscheineintausch",
      },
      { const: "voucherPayment", name: "voucherPayment" },
      {
        const: "worldPay",
        name: "WorldPay",
      },
      { const: "wspay", name: "WSPay" },
      { const: "sap_cas", name: "sap_cas" },
    ],
    shippingModules: [
      { const: "selfpickup", name: "Download und E-Mail-Sofortversand" },
      {
        const: "ap",
        name: "Standard Postsendung",
      },
      { const: "certifiedmail", name: "Eingeschriebene Postsendung" },
      {
        const: "dp",
        name: "Postversand",
      },
      { const: "ems", name: "Nachnahme + EMS" },
      {
        const: "flat",
        name: "Standard Postsendung",
      },
      { const: "freeamount", name: "Selbstabholung" },
      {
        const: "emszones",
        name: "Express Mail Service (EMS)",
      },
      { const: "shippingbox", name: "Postversand als Gutscheinbox" },
      {
        const: "abo",
        name: "Aboversand",
      },
      { const: "codshipping", name: "Nachnahme Postsendung" },
      {
        const: "mixedshipping",
        name: "Mischversand",
      },
      { const: "booking", name: "IGNORE: BOOKING" },
    ],
    orderStatus: [
      {
        id: 1,
        const: "DUE_ID_MONEYORDER",
        name: "Prepayment",
        color: "ffd4aa",
      },
      {
        id: 2,
        const: "DUE_ID_COD",
        name: "Open - Cash on Delivery",
        color: "ffd4aa",
      },
      { id: 3, const: "PAYED_ID", name: "PAID", color: "aaffaa" },
      {
        id: 4,
        const: "PAYED_ID_VISA",
        name: "Paid - VISA",
        color: "d4ffaa",
      },
      {
        id: 5,
        const: "PAYED_ID_MASTERCARD",
        name: "Paid - MasterCard",
        color: "d4ffaa",
      },
      {
        id: 6,
        const: "PAYED_ID_MAESTRO",
        name: "Paid - Maestro",
        color: "d4ffaa",
      },
      { id: 7, const: "O_STORNO_ID", name: "Order cancelled", color: "ffaaaa" },
      {
        id: 8,
        const: "GS_STORNO_ID",
        name: "canceled product",
        color: "ffaaaa",
      },
      {
        id: 9,
        const: "GS_REDEEM_ID",
        name: "voucher redeemed",
        color: "56ffff",
      },
      {
        id: 10,
        const: "GS_NOT_REDEEMED_ID",
        name: "active voucher",
        color: "d4ffaa",
      },
      {
        id: 11,
        const: "GS_PARTLY_REDEEMED_ID",
        name: "voucher partial redeemed",
        color: "fafca1",
      },
      {
        id: 12,
        const: "O_WIN_ID",
        name: "campaign voucher",
        color: "eff49f",
      },
      {
        id: 13,
        const: "O_NOT_APPLIED_ID",
        name: "not applied",
        color: "ff0000",
      },
      {
        id: 14,
        const: "PAYED_ID_AMEX",
        name: "Paid - AMEX",
        color: "d4ffaa",
      },
      {
        id: 15,
        const: "GS_CANCEL_REDEMPTION_ID",
        name: "R\u00fcckeingel\u00f6st_GS",
        color: "7f003f",
      },
      {
        id: 19,
        const: "PAYED_ID_DINERS",
        name: "PAID - DINERS",
        color: "d4ffaa",
      },
      {
        id: 20,
        const: "CARD_NOT_ASSIGNED_STATUS_ID",
        name: "Not issued",
        color: "010101",
      },
      {
        id: 21,
        const: "CARD_INACTIVE_STATUS_ID",
        name: "Not activated",
        color: "aaffaa",
      },
      {
        id: 22,
        const: "CARD_ACTIVE_STATUS_ID",
        name: "Activated",
        color: "ffffaa",
      },
      {
        id: 23,
        const: "CARD_REDEEMED_STATUS_ID",
        name: "Redeemed Card",
        color: "aad4ff",
      },
      {
        id: 24,
        const: "PRODUCT_SOLD_STATUS_ID",
        name: "Sold",
        color: "e5e5e5",
      },
      {
        id: 25,
        const: "O_STORNO_PENDING_ID",
        name: "Payment Cancel Pending",
        color: "eded09",
      },
      {
        id: 26,
        const: "PAYED_ID_PAYPAL",
        name: "Paid PayPal",
        color: "aaffaa",
      },
      {
        id: 27,
        const: "PAYED_ID_SOFORT",
        name: "Bezahlt - Sofort",
        color: "aaffaa",
      },
      {
        id: 30,
        const: "",
        name: "Bezahlt - Gutschein",
        color: "ffaaff",
      },
      {
        id: 31,
        const: "TICKET_PICKED_UP_ID",
        name: "Bezahlt - Tickets abgeholt",
        color: "d4ffaa",
      },
      {
        id: 32,
        const: "TICKET_UNCLAIMED_ID",
        name: "Bezahlt - Tickets nicht abgeholt",
        color: "ffd4aa",
      },
      {
        id: 33,
        const: "TICKET_RESERVED_ID",
        name: "Offen-vorreserviert",
        color: "ffffaa",
      },
      {
        id: 34,
        const: "PENDING_ID_PAYPAL",
        name: "Pending - Paypal",
        color: "",
      },
      {
        id: 35,
        const: "REFUNDED_ID_PAYPAL",
        name: "Refunded - Paypal",
        color: "",
      },
      {
        id: 36,
        const: "",
        name: "Versendet - Vorauskasse",
        color: "aaffff",
      },
      { id: 41, const: "GS_OUT_OF_REDEMPTION_PERIOD", name: "", color: "" },
      {
        id: 48,
        const: "PICKUP_PENDING",
        name: "Pickup pending",
        color: "",
      },
      {
        id: 49,
        const: "PRODUCT_DELIVERED",
        name: "Delivered",
        color: "d4ffaa",
      },
      {
        id: 50,
        const: "DELIVERY_PENDING",
        name: "Delivery pending",
        color: "ffd4aa",
      },
      {
        id: 64,
        const: "RESERVED_QUOTA_CHANGED",
        name: "Change of quota",
        color: "cccccc",
      },
      {
        id: 73,
        const: "PAID_ID_VOUCHER_EXCHANGE",
        name: "Voucher Exchange",
        color: "aaffe8",
      },
      { id: 74, const: "OVERDUE_ID", name: "", color: "ee00ff" },
      {
        id: 75,
        const: "OVERDUE_SECOND_ID",
        name: "",
        color: "ff8300",
      },
      {
        id: 76,
        const: "DUE_ID_DELAYED",
        name: "Open - Delayed",
        color: "3a644c",
      },
      {
        id: 77,
        const: "PICKUP_PENDING",
        name: "Zur Abholung",
        color: "",
      },
      { id: 78, const: "PICKUP_READY", name: "Abholbereit", color: "" },
      {
        id: 79,
        const: "PICKUP_DONE",
        name: "Abgeholt",
        color: "",
      },
    ],
  },
};
