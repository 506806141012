<h4 *ngIf="props.label" class="clear-separator-bottom">{{ props.label }}</h4>
<div *ngFor="let field of field.fieldGroup; let i = index">
  <div class="box-entry separator-bottom">
    <formly-field [field]="field"></formly-field>
    <div *ngIf="field.props.removable !== false" class="align-right">
      <icon-button (click)="remove(i)" [iconOnly]="true" [displayIconButtonAsButton]="true"
                   iconType="close"></icon-button>
    </div>
  </div>
</div>
<icon-button (click)="add()" label="{{'core.action.add' | i18n}}" iconType="plus"></icon-button>
