import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { PasswordResetReceiverType } from "../../enums";

@DataConversion()
export class RequestPasswordResetModel {
  @Required()
  public email: string;

  @Optional()
  public preferredReceiver?: PasswordResetReceiverType;
}
