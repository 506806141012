export enum CampaignCouponStatus {
  COUPON_NOT_SENDED_ID = 1,
  COUPON_SENDED_ID = 2,
  COUPON_ACTIVATED_ID = 3,
  COUPON_REDEEMED_ID = 4,
  COUPON_REFUSED_ID = 5,
  COUPON_INVALID_ID = 6,
  COUPON_TEST_ID = 7,
  COUPON_BLANK_ID = 8,
}
