<h2>{{'gms.manageAccess.permissions' | i18n}}</h2>
  <p-panel header="{{'gms.manageAccess.accessGroups' | i18n}}" [toggleable]="true">
    <p-accordion>
      <ng-template ngFor let-accessGroup [ngForOf]="accessGroups">
        <p-accordionTab [ngClass]="{'p-accordion-tab-non-toggleable': !accessGroup.children}">
          <ng-template pTemplate="header">
            <span class="p-panel-header-label">{{accessGroup.name}}</span>
            <div class="accordion-header-buttons-right">
              <div *ngIf="getAccessGroupActiveChildren(accessGroup)">
                <incert-tag [label]="getAccessGroupActiveChildren(accessGroup)"></incert-tag>
              </div>
              <icon-button [iconOnly]="true" [displayIconButtonAsButton]="true" iconType="check" *ngIf="hasAccessGroup(accessGroup)" (click)="updateAccessGroup(accessGroup)"></icon-button>
              <icon-button [iconOnly]="true" [displayIconButtonAsButton]="true" [buttonType]="'secondary'" iconType="close" *ngIf="!hasAccessGroup(accessGroup)" (click)="updateAccessGroup(accessGroup)"></icon-button>
              <!--<p-inputSwitch
                (onChange)="updateAccessGroup(accessGroup)"
                [ngModel]="hasAccessGroup(accessGroup)">
              </p-inputSwitch>-->
            </div>
          </ng-template>
          <ng-template pTemplate="content" *ngIf="accessGroup.children">
            <p-accordion>
              <ng-template ngFor let-accessGroupChild [ngForOf]="accessGroup.children">
                <p-accordionTab [ngClass]="{'p-accordion-tab-non-toggleable': true}">
                  <ng-template pTemplate="header">
                    <span class="p-panel-header-label">{{accessGroupChild.name}}</span>
                    <div class="accordion-header-buttons-right">
                      <icon-button [iconOnly]="true" [displayIconButtonAsButton]="true" iconType="check" *ngIf="hasAccessGroup(accessGroupChild)" (click)="updateAccessGroup(accessGroupChild)"></icon-button>
                      <icon-button [iconOnly]="true" [displayIconButtonAsButton]="true" [buttonType]="'secondary'" iconType="close" *ngIf="!hasAccessGroup(accessGroupChild)" (click)="updateAccessGroup(accessGroupChild)"></icon-button>

                     <!-- <p-inputSwitch
                        [ngModel]="hasAccessGroup(accessGroupChild)"
                        (onChange)="updateAccessGroup(accessGroupChild)">
                      </p-inputSwitch>-->
                    </div>
                  </ng-template>
                  <!--<ng-template *ngIf="accessMap[accessGroupChild.id]">
                      <ng-template ngFor let-alid [ngForOf]="accessMap[accessGroupChild.id]">
                          <ng-template pTemplate="content">
                              <p-panel class="inct-sub-panel" [toggleable]="false" [collapsed]="true">
                                  <ng-template pTemplate="header">
                                      <span class="p-panel-header-label">{{accessLevels[alid].name}}</span>
                                      <p-button
                                              (click)="addAccessLevel(accessLevels[alid])"
                                              [label]="'gms.manageAccess.addAccessGroup' | i18n"
                                              *ngIf="!hasAccessLevel(accessLevels[alid])" styleClass="p-button-secondary"
                                      ></p-button>
                                      <p-button
                                              (click)="removeAccessLevel(accessLevels[alid])"
                                              [label]="'gms.manageAccess.removeAccessGroup' | i18n"
                                              *ngIf="hasAccessLevel(accessLevels[alid])"
                                      ></p-button>
                                  </ng-template>
                              </p-panel>
                          </ng-template>
                      </ng-template>
                  </ng-template>-->
                </p-accordionTab>
              </ng-template>
            </p-accordion>
          </ng-template>
          <!--<ng-template pTemplate="footer" *ngIf="accessMap[accessGroup.id]">
              <ng-template ngFor let-alid [ngForOf]="accessMap[accessGroup.id]">
                  <p-panel class="inct-sub-panel" [toggleable]="false" [collapsed]="true">
                      <ng-template pTemplate="header">
                          <span class="p-panel-header-label">{{accessLevels[alid].name}}</span>
                          <p-button
                                  (click)="addAccessLevel(accessLevels[alid])"
                                  [label]="'gms.manageAccess.addAccessGroup' | i18n"
                                  *ngIf="!hasAccessLevel(accessLevels[alid])" styleClass="p-button-secondary"
                          ></p-button>
                          <p-button
                                  (click)="removeAccessLevel(accessLevels[alid])"
                                  [label]="'gms.manageAccess.removeAccessGroup' | i18n"
                                  *ngIf="hasAccessLevel(accessLevels[alid])"
                          ></p-button>
                      </ng-template>
                  </p-panel>
              </ng-template>
          </ng-template>-->
        </p-accordionTab>
      </ng-template>
    </p-accordion>
  </p-panel>
  <p-panel header="{{'gms.manageAccess.accessLevels' | i18n}}" [toggleable]="true">
    <p-accordion>
      <ng-template ngFor let-alid [ngForOf]="accessMap[-1]">
        <p-accordionTab [toggleable]="false" [collapsed]="true" class="p-accordion-tab-non-toggleable">
          <ng-template pTemplate="header">
            <span class="p-panel-header-label">{{accessLevels[alid].name}}</span>
            <div class="accordion-header-buttons-right">
              <icon-button [iconOnly]="true" [displayIconButtonAsButton]="true" iconType="check" *ngIf="hasAccessLevel(accessLevels[alid])" (click)="updateAccessLevel(accessLevels[alid])"></icon-button>
              <icon-button [iconOnly]="true" [displayIconButtonAsButton]="true" [buttonType]="'secondary'" iconType="close" *ngIf="!hasAccessLevel(accessLevels[alid])" (click)="updateAccessLevel(accessLevels[alid])"></icon-button>

              <!--
              <p-inputSwitch
                (onChange)="updateAccessLevel(accessLevels[alid])"
                [ngModel]="hasAccessLevel(accessLevels[alid])">
              </p-inputSwitch>
              -->
            </div>
          </ng-template>
        </p-accordionTab>
      </ng-template>
    </p-accordion>
  </p-panel>
