import { DataConversion, Optional, Required } from "@incert/data-conversion";

@DataConversion()
export class Language {
  @Required()
  id: number;

  @Optional()
  name: string;

  @Optional()
  code: string;

  @Optional()
  imageUrl: string;

  @Optional()
  maintainable: boolean | number;

  @Optional()
  active: boolean | number;

  @Optional()
  adminActive: boolean | number;
}
