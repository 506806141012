import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  ArrayDataSource,
  ColumnDefinition,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableArrayComponent,
  DataTableArrayOptions,
  DataTableConfig,
  DataTableIconButtonsComponent,
  DateFilterComponent,
  isPropertyColumn,
  SelectFilterComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { MemoManager } from "./memo.manager.service";
import { Category, Memo, Tag } from "./memo.interface";
import {
  LoadingService,
  NotificationService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs";
import { User, UserManager } from "../../user";
import { AccountSystem } from "../../../core/models/account-system.interface";
import {
  CommentAreaConfig,
  DataTableCommentAreaComponent,
} from "../../../core/data-table-components/data-table-comment-area/data-table-comment-area.component";
import { IAuthManager } from "../../../core/auth";
import { AccountSystemManager } from "../../account-system/account-system-manager.service";
import { Utils } from "../../../core/utils";
import { MemoHistoryComponent } from "./memo-history/memo-history.component";
import { SelectItem } from "primeng/api";
import { MemoCategoryComponent } from "./memo-category-new/add-new-memo-category.component";
import { MemoAdd } from "./memo-add/memo-add.component";
import { DataTableTagComponent } from "../../../core/data-table-components/data-table-tag/data-table-tag.component";
import { MemoTagAdd } from "./memo-tag-new/memo-tag-add.component";

@Component({
  selector: "incert-internal-tools-memo",
  templateUrl: "./memo.component.html",
  styleUrls: ["./memo.component.scss"],
})
export class MemoComponent implements OnInit, OnDestroy {
  private querySubscr: Subscription;
  private memoSubscr: Subscription;
  private tagOptions: SelectItem[];
  private categoryOptions: SelectItem[];
  private users: Array<User>;
  public accountSystems: Array<AccountSystem> = [];
  public arrayDataSource: ArrayDataSource<any>;
  public categories: Array<Category> = [];
  public tags: Array<Tag> = [];
  public qryAccountSystemId: number;
  public qryAccountSystem: AccountSystem = null;
  public memos: Array<Memo> = [];
  public memoTableConfig: DataTableConfig<any>;
  public routeParams: Params;

  constructor(
    @Inject("AuthManager") private authManager: IAuthManager,
    private accountSystemManager: AccountSystemManager,
    private notificationService: NotificationService,
    private memoManager: MemoManager,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private overlayService: OverlayService,
    private userManager: UserManager,
  ) {
    this.querySubscr = this.activatedRoute.queryParams.subscribe((params) => {
      this.qryAccountSystemId = params["acs"];
    });
    this.memoSubscr = this.memoManager.dataChanged.subscribe(() =>
      this.initialize(),
    );
  }

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe((routeParams) => {
      this.routeParams = routeParams;
    });

    await this.initialize();
  }

  public async addMemo() {
    await this.overlayService.show<MemoAdd>({
      type: MemoAdd,
      header: "Wissensnotiz hinzufügen",
      size: OverlaySize.large,
      displayAsSidebar: true,
      init: (component) => {
        component.accountSystems = this.accountSystems;
        component.users = this.users;
        component.tags = this.tags;
        component.categories = this.categories;
      },
      actions: [
        {
          buttonType: "primary",
          label: "Speichern",
          action: (component) => component.onSaveNewMemo(),
        },
        {
          displayAsLink: true,
          label: "Abbrechen",
          action: () => {
            return true;
          },
        },
      ],
    });
  }

  public async editMemo(memo: Memo) {
    await this.overlayService.show<MemoAdd>({
      type: MemoAdd,
      header: "Wissensnotiz bearbeiten",
      size: OverlaySize.large,
      displayAsSidebar: true,
      init: (component: MemoAdd) => {
        component.accountSystems = this.accountSystems;
        component.users = this.users;
        component.tags = this.tags;
        component.categories = this.categories;
        component.onEditMemo(memo);
      },
      actions: [
        {
          buttonType: "primary",
          label: "Speichern",
          action: (component) => {
            return component.onSaveEditedMemo();
          },
        },
        {
          displayAsLink: true,
          label: "Abbrechen",
          action: () => true,
        },
      ],
    });
  }

  public async editTag() {
    await this.overlayService.show<MemoTagAdd>({
      header: "Tags",
      type: MemoTagAdd,
      size: OverlaySize.medium,
      displayAsSidebar: true,
      actions: [
        {
          displayAsLink: true,
          label: "Schließen",
          action: () => true,
        },
      ],
    });
  }

  public async editCategory() {
    await this.overlayService.show<MemoCategoryComponent>({
      header: "Kategorien",
      type: MemoCategoryComponent,
      size: OverlaySize.medium,
      displayAsSidebar: true,
      actions: [
        {
          displayAsLink: true,
          label: "Schließen",
          action: () => {
            return true;
          },
        },
      ],
    });
  }

  public async showHistory(memo: Memo) {
    await this.overlayService.show<MemoHistoryComponent>({
      type: MemoHistoryComponent,
      header: "Verlauf Wissensnotiz",
      size: OverlaySize.full,
      displayAsSidebar: true,
      init: (component) => {
        component.memoId = memo.id;
      },
      actions: [
        {
          displayAsLink: true,
          label: "Schließen",
          action: () => true,
        },
      ],
    });
  }

  public async deleteMemo(memo: Memo) {
    await this.memoManager.deleteMemo(memo.id);
    await this.initialize();
  }

  public async getUsers() {
    try {
      this.users = await this.userManager.getUsers();
    } catch (e) {
      this.notificationService.notifyError(
        "Benutzer konnten nicht geladen werden",
        "Fehler: Benutzer laden",
      );
    }
  }

  private async initialize() {
    await this.loadingService.load(async () => {
      await Promise.all([
        this.getAllMemos(),
        this.getMemoCategoryOptions(),
        this.getAccountSystems(),
      ]).then(() => {
        this.filterMemos();
        this.initializeDataTable();
      });
    });

    await this.getUsers();
    await this.getMemoTagOptions();
  }

  private async initializeDataTable() {
    if (this.memos.length) {
      this.createDataTable();
    }
  }

  private async getAccountSystems() {
    try {
      this.accountSystems =
        await this.accountSystemManager.getStandaloneAccountSystems();

      if (this.qryAccountSystemId) {
        this.qryAccountSystem = this.accountSystems.find(
          (a) => a.id === +this.qryAccountSystemId,
        );
      }
    } catch (e) {
      this.notificationService.notifyWarning(
        "Could not fetch Account Systems",
        "Fehler: Kundensystemabfrage",
      );
    }
  }

  private async getMemoTagOptions() {
    this.tags = await this.memoManager.getMemoTags();
    this.tagOptions = Utils.mapCustomValueToSelectItem(
      this.tags,
      "name",
      "name",
    );
  }

  private async getMemoCategoryOptions() {
    try {
      this.categories = await this.memoManager.getMemoCategories();
      this.categoryOptions = Utils.mapCustomValueToSelectItem(
        this.categories,
        "name",
        "name",
      );
    } catch (e) {
      this.notificationService.notifyInfo(
        "Wissensnotizkategorien konnten nicht geladen werden",
        "Fehler: Wissenskategorien",
      );
    }
  }

  private async getAllMemos() {
    try {
      this.memos = await this.memoManager.getMemos();
    } catch (e) {
      this.notificationService.notifyError(
        "Laden der Wissensnotizen fehlgeschlagen",
        "Wissensnotizen konnten nicht geladen werden",
      );
    }
  }

  private createDataTable() {
    for (const memo of this.memos) {
      memo.accountSystemsStr = memo.accountSystems.map((v) => v.name).join();
      memo.speakToStr = memo.speakTo
        .map((v) => v.lastName + " " + v.firstName)
        .join(",");
      memo.tagsStr = memo.tags.map((v) => v.name).join(",");
    }

    this.arrayDataSource = new ArrayDataSource(this.memos);
    this.memoTableConfig = {
      data: this.arrayDataSource,
      mode: "pagination",
      rows: 12,
      filterArea: {
        filters: [
          {
            header: "Titel",
            property: (v) => v.get("title"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Kundensystem",
            property: (v) => v.get("accountSystem"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("accountSystemsStr"),
              defaultValue: this.routeParams["accountSystem"],
            }),
          },
          {
            header: "Wissenskategorie",
            property: (v) => v.get("category", "name"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: this.categoryOptions,
            }),
          },
          {
            header: "Tags",
            property: (v) => v.get("tags"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("tagsStr"),
            }),
          },
          {
            header: "Milestone",
            property: (v) => v.get("milestone"),
            filter: createFilterDefinition(DateFilterComponent, {
              type: "between",
            }),
          },
          {
            header: "Ablaufdatum",
            property: (v) => v.get("validUntil"),
            filter: createFilterDefinition(DateFilterComponent, {
              type: "between",
            }),
          },
          {
            header: "Zuletzt geändert",
            property: (v) => v.get("changedAt"),
            filter: createFilterDefinition(DateFilterComponent, {
              type: "between",
            }),
          },
          {
            header: "Ansprechpartner",
            property: (v) => v.get("speakTo"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("speakToStr"),
            }),
          },
          {
            header: "Kommentar",
            property: (v) => v.get("comment"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
        ],
      },
      columns: [
        {
          header: "Titel",
          class: () => "nowrap",
          property: (v) => v.get("title"),
          sort: true,
        },
        {
          header: "Kundensystem",
          property: (v) => v.get("accountSystem"),
          component: createComponentConfiguration(DataTableArrayComponent, {
            setArrayData: (row: Memo): DataTableArrayOptions<AccountSystem> => {
              return {
                arrayData: row.accountSystems,
                displayProperty: "name",
              };
            },
          }),
        },
        {
          header: "Kategorie",
          property: (v) => v.get("category", "name"),
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: Memo) => {
              return [
                {
                  name: row.category.name.toUpperCase(),
                  bgColor: row.category.bgColor,
                  fgColor: row.category.fgColor,
                },
              ];
            },
          }),
        },
        {
          header: "Tags",
          property: (v) => v.get("tags"),
          class: () => "dt-width-150",
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: Memo) => {
              return row.tags;
            },
          }),
        },
        {
          header: "Milestone",
          align: "center",
          property: (v) => v.get("milestone"),
          transform: (v) => Utils.showDateAt(v),
          sort: true,
        },
        {
          header: "Ablaufdatum",
          align: "center",
          property: (v) => v.get("validUntil"),
          transform: (v) => Utils.showDateAt(v),
          sort: true,
        },
        {
          header: "Zuletzt geändert",
          align: "center",
          property: (v) => v.get("changedAt"),
          transform: (v) => Utils.showDateAt(v),
          sort: true,
        },
        {
          header: "Ansprechpartner",
          property: (v) => v.get("speakTo"),
          component: createComponentConfiguration(DataTableArrayComponent, {
            setArrayData: (row: Memo): DataTableArrayOptions<User> => {
              return {
                arrayData: row.speakTo,
                displayProperty: ["lastName", "firstName"],
                maxItems: 3,
              };
            },
          }),
        },
        {
          header: "Kommentar",
          property: (v) => v.get("comment"),
          component: createComponentConfiguration(
            DataTableCommentAreaComponent,
            {
              setTextAreaData: (row: Memo): CommentAreaConfig<Memo> => {
                return {
                  header: "Kommentar",
                  maxLengthWrap: 192,
                  text: row.comment,
                  createdBy: row.createdBy,
                  createdAt: row.createdAt,
                };
              },
            },
          ),
        },
        {
          header: "Aktionen",
          property: (v) => v.get("id"),
          hidden: !this.writeMemo,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "edit",
                  hidden: !this.writeMemo,
                  onClick: (memo: Memo) => this.editMemo(memo),
                  tooltip: "Bearbeiten",
                },
                {
                  icon: "history",
                  hidden: !this.writeMemo,
                  onClick: (memo: Memo) => this.showHistory(memo),
                  tooltip: "Verlauf",
                },
                {
                  icon: "delete",
                  hidden: !this.executeMemo,
                  onClick: (memo: Memo) => this.deleteMemo(memo),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              hidden: !this.executeMemoAdditional,
              icon: "edit",
              label: "Tags",
              onClick: () => this.editTag(),
            },
            {
              hidden: !this.executeMemoAdditional,
              icon: "edit",
              label: "Kategorien",
              onClick: () => this.editCategory(),
            },
            {
              hidden: !this.writeMemo,
              icon: "plus",
              label: "Wissensnotiz",
              onClick: () => this.addMemo(),
            },
          ],
        }),
      ],
    };

    this.memoTableConfig.columns.map((column: ColumnDefinition<any>) => {
      if (isPropertyColumn(column)) {
        column.color = (row: AccountSystem) => {
          if (Utils.isInPast(row["validUntil"])) {
            return "#ffeeee";
          } else if (row["pinned"]) {
            return "#d8f5d8";
          } else {
            return null;
          }
        };
      }
    });
  }

  private filterMemos() {
    if (this.qryAccountSystemId) {
      this.memos = this.memos.filter((memo) =>
        memo.accountSystems.find((acs) => acs.id === +this.qryAccountSystemId),
      );
    }
  }

  public get writeMemo(): boolean {
    return this.authManager.hasPermission("writeMemo");
  }

  public get executeMemo(): boolean {
    return this.authManager.hasPermission("executeMemo");
  }

  public get executeMemoAdditional(): boolean {
    return this.authManager.hasPermission("executeMemoAdditional");
  }

  ngOnDestroy() {
    this.querySubscr.unsubscribe();
    this.memoSubscr.unsubscribe();
  }
}
