import { WidgetProperties } from "../interface/widget-properties.interface";

export interface WidgetTitle {
  title: string;
  tooltip?: string;
}

export interface TitleResolverInterface {
  resolveTitle(
    id: string,
    name: string,
    properties: WidgetProperties<any>,
  ): WidgetTitle;
}
