import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class TrackingEventModel {
  @Required()
  id: number;

  @Required()
  short: string;

  @Required()
  enabled: number;
}
