import { ArrayType, DataConversion, Required } from "@incert/data-conversion";
import { Customer } from "./customer.model";
import { Product } from "./product.model";
import { Language } from "./language.model";
import { ReviewImage } from "./review-image.model";

@DataConversion()
export class Review {
  @Required()
  id: number;

  @Required()
  customer: Customer;

  @Required()
  alias: string;

  @Required()
  product: Product;

  @Required()
  language: Language;

  @Required()
  title: string;

  @Required()
  text: string;

  @Required()
  rating: number;

  @Required()
  status: string;

  @Required()
  highlighted: boolean;

  @Required()
  rewarded: boolean;

  @Required()
  hash: string;

  @Required()
  experienced: Date;

  @Required()
  created: Date;

  @Required()
  @ArrayType(() =>
    import("./review-image.model").then((mod) => mod.ReviewImage),
  )
  images: ReviewImage[];
}
