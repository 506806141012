import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { CategoryDescription } from "./category-description.model";

@DataConversion()
export class Category {
  @Required()
  id: number;

  @Optional()
  @ArrayType(() => new Promise((resolve) => resolve(Category)))
  childCategories: Category[];

  @Optional()
  imageLink: string;

  @Optional()
  status: boolean;

  @Optional()
  @ArrayType(() =>
    import("./category-description.model").then(
      (mod) => mod.CategoryDescription,
    ),
  )
  categoryDescriptions: CategoryDescription[];
}
