import {
  APP_INITIALIZER,
  Injector,
  ModuleWithProviders,
  NgModule,
} from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { GMSUrlResolver } from "./services/http/gms-url-resolver.service";
import { ApiHttpAccess } from "./services/http/api-http-access.service";
import { LoginService } from "./services/login/login.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { GMSAuthHttpInterceptor } from "./services/auth/gms-auth-http-intercepter.service";
import { GMSCoreInitializer } from "./gms-core.initializer";
import { IncertCoreModule } from "@incert/incert-core";
import { AccessTokenService, DwhHttpInterceptorService } from "./services";
import { EnvironmentDAO } from "./daos/environment.dao.service";
import { EnvironmentService } from "./services/environment.service";
import { GMSAuthGuard } from "./services/auth/gms-auth-guard.service";
import { GMSInverseAuthGuard } from "./services/auth/gms-inverse-auth-guard.service";
import { INCERT_GUI_I18N, NumberFormatPipeModule } from "@incert/incert-gui";
import { gmsIncertGuiI18nFactory } from "./gms-incert-gui-i18n.factory";
import { DASHBOARD_I18N } from "@incert/dashboard";
import { gmsDashboardI18nFactory } from "./gms-dashboard-i18n.factory";
import { FORM_EXTENSION_I18N } from "@incert/incert-gui";
import { gmsFormExtensionI18nFactory } from "./gms-form-extension-i18n.factory";
import { BackendApiHttpAccess } from "./services/http/backend-api-http-access.service";
import { BonAuthHttpInterceptor } from "./services/http/bon-auth-http-interceptor";

export function loadInitializer(gmsCoreInitializer: GMSCoreInitializer) {
  return () => gmsCoreInitializer.load();
}

@NgModule({
  imports: [CommonModule, IncertCoreModule],
  exports: [],
})
export class GMSCoreModule {
  public constructor() {}

  static forRoot(): ModuleWithProviders<GMSCoreModule> {
    return {
      ngModule: GMSCoreModule,
      providers: [
        GMSUrlResolver,
        ApiHttpAccess,
        BackendApiHttpAccess,
        LoginService,
        EnvironmentDAO,
        EnvironmentService,
        GMSAuthGuard,
        GMSInverseAuthGuard,
        GMSAuthHttpInterceptor,
        AccessTokenService,
        DatePipe,
        NumberFormatPipeModule,
        BonAuthHttpInterceptor,
        {
          provide: HTTP_INTERCEPTORS,
          useExisting: BonAuthHttpInterceptor,
          multi: true,
        },
        DwhHttpInterceptorService,
        {
          provide: HTTP_INTERCEPTORS,
          useExisting: DwhHttpInterceptorService,
          deps: [Injector],
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useExisting: GMSAuthHttpInterceptor,
          multi: true,
        },
        GMSCoreInitializer,
        {
          provide: APP_INITIALIZER,
          useFactory: loadInitializer,
          deps: [GMSCoreInitializer],
          multi: true,
        },
        {
          provide: INCERT_GUI_I18N,
          useFactory: gmsIncertGuiI18nFactory,
          deps: [Injector],
        },
        {
          provide: FORM_EXTENSION_I18N,
          useFactory: gmsFormExtensionI18nFactory,
          deps: [Injector],
        },
        {
          provide: DASHBOARD_I18N,
          useFactory: gmsDashboardI18nFactory,
        },
      ],
    };
  }
}
