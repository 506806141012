import { Injectable } from "@angular/core";
import { Notification, NotificationPermission } from "./notification.interface";
import { APIService } from "../../../core/api.service";
import { HttpService } from "../../../core";
import { SelectItem } from "primeng/api";
import { NotificationService } from "@incert/incert-core";

@Injectable({
  providedIn: "root",
})
export class NotificationManagerService {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
    private notificationService: NotificationService,
  ) {}

  public async getNotifications(): Promise<Array<Notification>> {
    return await this.httpService.get<Array<Notification>>(
      this.settingsService.baseUrl + "notification/internal",
    );
  }

  public async getNotificationCategories(): Promise<Array<SelectItem>> {
    try {
      return await this.httpService.get<SelectItem[]>(
        this.settingsService.baseUrl + "notification/category",
      );
    } catch (e) {
      this.notificationService.notifyError(
        "Kategorien konnten nicht geladen werden",
        "Fehler: Kategorien",
      );
    }
  }

  public async getNotificationBehaviors() {
    try {
      return await this.httpService.get<SelectItem[]>(
        this.settingsService.baseUrl + "notification/behavior",
      );
    } catch (e) {
      this.notificationService.notifyError(
        "Behaviors konnten nicht geladen werden",
        "Fehler: Behaviors",
      );
    }
  }

  public async getNotificationPermissions(): Promise<
    Array<NotificationPermission>
  > {
    try {
      return await this.httpService.get<Array<NotificationPermission>>(
        this.settingsService.baseUrl + "notification/permission",
      );
    } catch (e) {
      this.notificationService.notifyError(
        "Rechte konnten nicht geladen werden",
        "Fehler: Rechte",
      );
    }
  }

  public async upsertNotification(
    notification: Notification,
  ): Promise<Notification> {
    return await this.httpService.post<Notification>(
      this.settingsService.baseUrl + "notification",
      notification,
    );
  }

  public async deleteNotification(id: number): Promise<Notification> {
    return await this.httpService.delete<Notification>(
      this.settingsService.baseUrl + "notification/" + id,
    );
  }
}
