import { Component, OnInit } from "@angular/core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import {
  ConfirmService,
  LoadingService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { AccessProfileAddComponent } from "./access-profile-add/access-profile-add.component";
import { AccessProfileManager } from "./access-profile.manager.service";
import { AccessProfile } from "./access-profile.model";
import { AccessProfileDetailComponent } from "./access-profile-detail/access-profile-detail.component";
import { AccessProfileLegacyAddComponent } from "./access-profile-legacy-add/access-profile-legacy-add.component";
import { AuthManager } from "../../../core/auth";
import { DataTableTagComponent } from "../../../core/data-table-components/data-table-tag/data-table-tag.component";

@Component({
  selector: "incert-internal-tools-access-profile",
  templateUrl: "./access-profile.component.html",
})
export class AccessProfileComponent implements OnInit {
  constructor(
    private overlayService: OverlayService,
    public accessProfileManager: AccessProfileManager,
    private loadingService: LoadingService,
    private confirmService: ConfirmService,
    private authManager: AuthManager,
  ) {}

  public accessProfiles: AccessProfile[];
  public accessProfilesLegacy: AccessProfile[];
  dtConfig: DataTableConfig<any>;
  dtConfigLegacy: DataTableConfig<any>;
  interfaceTypes: any;

  async ngOnInit() {
    await this.loadingService.load(async () => {
      this.accessProfiles = await this.accessProfileManager.getAccessProfiles();
      this.accessProfilesLegacy =
        await this.accessProfileManager.getAccessProfilesLegacy();
      this.interfaceTypes = await this.accessProfileManager.getInterfaceTypes();
      await this.createDtConfig();
      await this.createDtConfigLegacy();
    });
    return true;
  }

  private async createDtConfig() {
    const arrayDataSource = new ArrayDataSource(this.accessProfiles);
    this.dtConfig = {
      data: arrayDataSource,
      rows: 50,
      filterArea: {
        filters: [
          {
            header: "Bezeichnung",
            property: (v) => v.get("title"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Beschreibung",
            property: (v) => v.get("description"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
        ],
      },
      columns: [
        {
          header: "Bezeichnung",
          property: (v) => v.get("title"),
          sort: true,
        },
        {
          header: "Beschreibung",
          property: (v) => v.get("description"),
          sort: true,
        },
        {
          header: "Access Groups",
          property: (v) => v.get("accessGroups"),
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: AccessProfile) => {
              return [
                {
                  name: row.accessGroups.length.toString(),
                  bgColor: "#009711",
                },
              ];
            },
            pointer: true,
            onClick: (row) => this.overlayAccessProfileDetail(row, "group"),
          }),
          class: () => "column-small-center",
        },
        {
          header: "Access Levels",
          property: (v) => v.get("accessLevels"),
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: AccessProfile) => {
              return [
                {
                  name: row.accessLevels.length.toString(),
                  bgColor: "#009711",
                },
              ];
            },
            pointer: true,
            onClick: (row) => this.overlayAccessProfileDetail(row, "level"),
          }),
          class: () => "column-small-center",
        },
        {
          header: "Aktionen",
          hidden: !this.writeAccessProfile,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  hidden: !this.writeAccessProfile,
                  icon: "edit",
                  onClick: async (row: AccessProfile) =>
                    this.accessProfileOverlay(row.title, row),
                  tooltip: "Bearbeiten",
                },
                {
                  hidden: !this.executeAccessProfile,
                  icon: "delete",
                  onClick: async (row: AccessProfile) =>
                    this.deleteAccessProfile(row),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              hidden: !this.writeAccessProfile,
              icon: "reload",
              label: "Sync",
              onClick: () => this.accessProfileManager.syncAccessProfile(),
            },
            {
              hidden: !this.writeAccessProfile,
              icon: "plus",
              label: "Profil",
              onClick: () => this.accessProfileOverlay("Profil hinzufügen"),
            },
          ],
        }),
      ],
    };
  }

  private async createDtConfigLegacy() {
    const arrayDataSource = new ArrayDataSource(this.accessProfilesLegacy);
    this.dtConfigLegacy = {
      data: arrayDataSource,
      rows: 50,
      filterArea: {
        filters: [
          {
            header: "Bezeichnung",
            property: (v) => v.get("title"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Beschreibung",
            property: (v) => v.get("description"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Interface Typ",
            property: (v) => v.get("interfaceType"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
        ],
      },
      columns: [
        {
          header: "Bezeichnung",
          property: (v) => v.get("title"),
          sort: true,
        },
        {
          header: "Beschreibung",
          property: (v) => v.get("description"),
          sort: true,
        },
        {
          header: "Interface Typ",
          property: (v) => v.get("interfaceType"),
          transform: (v) => this.interfaceTypes.find((t) => t.type === v).type,
          sort: true,
        },
        {
          header: "Aktionen",
          hidden: !this.writeAccessProfile,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  hidden: !this.writeAccessProfile,
                  icon: "edit",
                  onClick: async (row: AccessProfile) =>
                    this.accessProfileLegacyOverlay(row.title, row),
                  tooltip: "Bearbeiten",
                },
                {
                  hidden: !this.executeAccessProfile,
                  icon: "delete",
                  onClick: async (row: AccessProfile) =>
                    this.deleteAccessProfile(row),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              hidden: !this.writeAccessProfile,
              icon: "reload",
              label: "Sync",
              onClick: () => this.accessProfileManager.syncAccessProfile(),
            },
            {
              hidden: !this.writeAccessProfile,
              icon: "plus",
              label: "Profil",
              onClick: () =>
                this.accessProfileLegacyOverlay("Profil hinzufügen", true),
            },
          ],
        }),
      ],
    };
  }

  public async accessProfileOverlay(header: string, row?: any) {
    await this.overlayService.show<AccessProfileAddComponent>({
      size: OverlaySize.large,
      header: header.includes("hinzufügen") ? header : header + " bearbeiten",
      type: AccessProfileAddComponent,
      displayAsSidebar: true,
      init: (component) => {
        component.accessProfile = row;
        component.accessProfileComp = this;
      },
      actions: [
        {
          label: "Speichern",
          action: async (t) => await t.insertAccessProfile(),
        },
        {
          label: "Abbrechen",
          action: () => true,
          displayAsLink: true,
        },
      ],
    });
  }

  public async accessProfileLegacyOverlay(header: string, row?: any) {
    await this.overlayService.show<AccessProfileLegacyAddComponent>({
      size: OverlaySize.large,
      header: header,
      type: AccessProfileLegacyAddComponent,
      displayAsSidebar: true,
      init: (component) => {
        component.accessProfile = row;
        component.accessProfileComp = this;
        component.interfaceTypes = this.interfaceTypes;
      },
      actions: [
        {
          label: "Abbrechen",
          action: () => true,
          displayAsLink: true,
        },
        {
          label: "Speichern",
          action: async (t) => await t.insertAccessProfile(),
        },
      ],
    });
  }

  async deleteAccessProfile(accessProfile: AccessProfile) {
    const response = await this.confirmService.confirmError(
      "",
      "Profil löschen",
    );
    if (response) {
      await this.accessProfileManager.deleteAccessProfile(accessProfile);
      await this.ngOnInit();
    }
  }

  public get writeAccessProfile(): boolean {
    return this.authManager.hasPermission("writeAccessProfile");
  }

  public get executeAccessProfile(): boolean {
    return this.authManager.hasPermission("executeAccessProfile");
  }

  private async overlayAccessProfileDetail(
    row: AccessProfile,
    type: "group" | "level",
  ) {
    await this.overlayService.show<AccessProfileDetailComponent>({
      type: AccessProfileDetailComponent,
      header: "Detailansicht",
      size: OverlaySize.medium,
      displayAsSidebar: true,
      init: (component) => {
        component.type = type;
        component.accessProfile = row;
      },
      actions: [
        { label: "Schließen", displayAsLink: true, action: () => true },
      ],
    });
  }
}
