import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { TaxRateModel } from "./tax-rate.model";

@DataConversion()
export class TaxClassListEntry {
  @Required()
  id: number;

  @Required()
  title: string;

  @Required()
  description: string;

  @Required()
  isMixedTax: boolean;

  @Required()
  isHiddenOnInvoice: boolean;

  @Optional()
  @ArrayType(() => import("./tax-rate.model").then((mod) => mod.TaxRateModel))
  taxRates?: TaxRateModel[];

  @Optional()
  taxRateString?: string;
}
