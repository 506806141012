import { inject, InjectionToken } from "@angular/core";
import { RqlHttpAccess } from "@incert/incert-core";
import { HttpClient } from "@angular/common/http";
import { GMSUrlResolver } from "./http/gms-url-resolver.service";

export const GMS_RQL_HTTP_ACCESS = new InjectionToken("GMS_RQL_HTTP_ACCESS", {
  providedIn: "root",
  factory: () => {
    const rqlHttpAccess = new RqlHttpAccess(inject(HttpClient));
    rqlHttpAccess.baseHref = inject(GMSUrlResolver).getApiUrl();
    rqlHttpAccess.queryKey = "q";
    return rqlHttpAccess;
  },
});
