import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class Country {
  @Required()
  id: number;

  @Required()
  name: string;
}
