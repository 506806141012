import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class PaymentConfiguration {
  @Optional()
  paymentId = "";

  @Optional()
  title = "";

  @Optional()
  accountId = "";

  @Optional()
  departmentCode = "";

  @Optional()
  productsDc = "";
}
