import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { EventEmitter, Injectable } from "@angular/core";
import { EnvironmentService } from "../environment.service";

@Injectable()
export class GMSInverseAuthGuard implements CanActivate {
  public onInvalidAccess = new EventEmitter();

  public constructor(private environmentService: EnvironmentService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      this.environmentService.environment !== null &&
      this.environmentService.environment.adminLogin === true
    ) {
      this.onInvalidAccess.emit();
      return false;
    }
    return true;
  }
}
