import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class CustomerCard {
  @Optional()
  id: number;

  @Optional()
  colorStatus: string;

  @Optional()
  status: string;

  @Optional()
  masterCode: string;

  @Optional()
  cardCode: string;

  @Optional()
  productsName: string;

  @Optional()
  productsId: number;

  @Optional()
  owner: string;

  @Optional()
  currentValue: number;

  @Optional()
  currency: string;

  @Optional()
  man_orderer: string;

  @Optional()
  man_validator: string;

  @Optional()
  createDate: Date;

  @Optional()
  lastActivated: Date;

  @Optional()
  email: string;

  @Optional()
  fontColorStatus: string;

  @Optional()
  bonusPoints: number;
}
