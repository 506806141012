import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { GMSUrlResolver } from "../http/gms-url-resolver.service";
import { catchError } from "rxjs/operators";

@Injectable()
export class GMSAuthHttpInterceptor implements HttpInterceptor {
  public onError = new EventEmitter<HttpErrorResponse>();

  public constructor(private urlResolver: GMSUrlResolver) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.toString().indexOf(this.urlResolver.getUrl()) !== -1) {
      req = req.clone({
        withCredentials: true,
        headers: req.headers.append("X-NG-INC", "true"),
      });
    }
    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.onError.emit(err);
          return throwError(err);
        }
      }),
    );
  }
}
