import {
  ArrayType,
  DataConversion,
  DateConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { ShoppingCartRuleDescriptionModel } from "./shopping-cart-rule-description.model";
import { ShoppingCartRuleAssignmentModel } from "./shopping-cart-rule-assignment.model";
import { ShoppingCartRuleCallbackModel } from "./shopping-cart-rule-callback.model";

@DataConversion()
export class ShoppingCartRuleModel {
  @Required()
  public id: number;

  @Required()
  public name: string;

  @Required()
  public status: number;

  @Required()
  public target?: number;

  @Required()
  @DateConversion()
  public created: Date;

  @Required()
  @DateConversion()
  public lastModified: Date;

  @Required()
  @DateConversion()
  public activeFrom: Date;

  @Required()
  @DateConversion()
  public activeTo: Date;

  @Optional()
  @ArrayType(() =>
    import("./shopping-cart-rule-description.model").then(
      (mod) => mod.ShoppingCartRuleDescriptionModel,
    ),
  )
  public descriptions: ShoppingCartRuleDescriptionModel[];

  @Optional()
  @ArrayType(() =>
    import("./shopping-cart-rule-assignment.model").then(
      (mod) => mod.ShoppingCartRuleAssignmentModel,
    ),
  )
  public assignments: ShoppingCartRuleAssignmentModel[];

  @Optional()
  @ArrayType(() =>
    import("./shopping-cart-rule-callback.model").then(
      (mod) => mod.ShoppingCartRuleCallbackModel,
    ),
  )
  public callbacks: ShoppingCartRuleCallbackModel[];
}
