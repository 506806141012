import { DataConversion, Optional } from "@incert/data-conversion";
import { OrderProduct } from "./order-product.model";

@DataConversion()
export class CardHistory {
  @Optional()
  id: number;

  @Optional()
  historyDate: Date;

  @Optional()
  changedValue: number;

  @Optional()
  prefix: string;

  @Optional()
  comment: string;

  @Optional()
  orderProduct: OrderProduct;

  @Optional()
  reservNr: string;

  @Optional()
  originText: string;
}
