import { Injectable } from "@angular/core";
import { ApiHttpAccess } from "../http/api-http-access.service";
import { Subject } from "rxjs";
import { ApiHttpError } from "../http/api-http.error";
import { HttpErrorResponse } from "@angular/common/http";
import { EnvironmentService } from "../environment.service";
import { HttpAccess } from "@incert/incert-core";

@Injectable()
export class LoginService {
  public onLoginChange = new Subject();

  public constructor(
    private apiHttpAccess: ApiHttpAccess,
    private httpAccess: HttpAccess,
    private environmentService: EnvironmentService,
  ) {}

  /**
   * Login into GMS-System
   * @param username
   * @param password
   * @param backendNew
   */
  public async login(username: string, password: string, backendNew: boolean) {
    try {
      await this.apiHttpAccess.post("login", {
        username: username,
        password: password,
        backendNew: backendNew,
      });

      this.onLoginChange.next(true);
    } catch (e) {
      this.onLoginChange.next(false);

      if (e instanceof ApiHttpError) {
        throw e;
      }

      if (
        e instanceof HttpErrorResponse &&
        e.error["error"] &&
        e.error["message"]
      ) {
        throw new ApiHttpError(e.error);
      }

      throw new ApiHttpError({
        error: true,
        message: "Login failed",
        code: "LOGIN_FAILED",
      });
    }
  }

  public async logout() {
    await this.apiHttpAccess.post("logout", {});
    this.onLoginChange.next(false);
  }

  public canResetUserSwitchWithURL() {
    return Boolean(this.environmentService.environment.userSwitchResetURL);
  }

  public resetUserSwitchWithURL() {
    location.href = this.environmentService.environment.userSwitchResetURL;
  }

  /**
   * Reset user switch by logging in to previous user
   * @param {string} password
   */
  public async resetUserSwitchWithPassword(password: string) {
    try {
      await this.apiHttpAccess.post("resetuserswitch", {
        password: password,
      });
    } catch (e) {
      if (e instanceof ApiHttpError) {
        throw e;
      }

      if (
        e instanceof HttpErrorResponse &&
        e.error["error"] &&
        e.error["message"]
      ) {
        throw new ApiHttpError(e.error);
      }

      throw new ApiHttpError({
        error: true,
        message: "User switch reset failed",
        code: "USER_SWITCH_RESET_FAILED",
      });
    }
  }
}
