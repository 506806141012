import { DataSource } from "./datasource.interface";
import {
  AdditionalExportColumn,
  ColumnDefinition,
  ComponentConfiguration,
  ProcessedRow,
} from "./column-definition.interface";
import { Type } from "@angular/core";
import { AggregateDefinition } from "../../data-table-aggregation/arithmetic-strategy";
import { PropertyPathAccessor } from "@incert/incert-core";
import { MenuItem } from "primeng/api";
import { DataTableFilterDefinition } from "./data-table-filter-definition.interface";
import { DataTablePersistence } from "./data-table-persistence.interface";

export interface DataTableConfig<T> {
  data: DataSource<T>;
  columns: ColumnDefinition<T>[];
  rows: number;
  displayAsNewIncList?: boolean;
  resetSubtablesToRegularStyle?: boolean;
  dragDropList?: boolean;
  onRowReorder?: (
    rows: ProcessedRow[],
    dragIndex: number,
    dropIndex: number,
  ) => any;
  class?: string;
  rowIdentifier?: keyof T | PropertyPathAccessor<T>;
  emptyMessage?: string;
  selectionMode?: "multiple" | "single";
  selectionDisabled?: (row: T) => boolean;
  expansion?: {
    expandSingle?: boolean;
    singleRowExpanded?: boolean;
    component?: Type<any> | ComponentConfiguration<any>;
    condition?: (row: T) => boolean;
    hasPadding?: boolean;
    additionalInfoRowText?: (row: T) => string; //Text being used in a 2nd row that doesn't need extra expansion. Have a look at the HTML to see what i mean ;)
  };
  multipleSelectionItems?: MenuItem[];
  mode?: "pagination" | "scroll" | "fixed";
  summaryData?: any[];
  aggregateData?: AggregateDefinition<T>[];
  additionalHeaderComponents?: Type<any>[] | ComponentConfiguration<any>[];
  export?: boolean;
  columnEdit?: boolean;
  noHeader?: boolean;
  filterArea?: {
    filters: DataTableFilterDefinition<T>[];
  };
  persistence?: {
    persistenceId?: string;
    persistenceService?: PersistenceService;
  };
  alwaysShowPagination?: boolean;
  additionalExportColumns?: AdditionalExportColumn<T>[];
}

export interface PersistenceService {
  getDataTablePersistence(
    dataTableId: string,
  ): Promise<DataTablePersistence | null>;
  updateDataTablePersistence(persistence: DataTablePersistence): Promise<void>;
}
