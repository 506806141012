import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Language } from "./language.model";

@DataConversion()
export class ProductDescription {
  @Required()
  public name: string;

  @Optional()
  public displayShortName: string;

  @Optional()
  public description: string;

  @Optional()
  public descriptionPrint: string;

  @Optional()
  public shortDescription: string;

  @Required()
  public language: Language;

  @Optional()
  public languageId: number;
}
