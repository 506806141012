import { DataTableConfigPersistor } from "@incert/incert-core";
import { Injectable } from "@angular/core";
import { WidgetHandle } from "@incert/dashboard";

@Injectable()
export class GmsWidgetDataTablePersistor implements DataTableConfigPersistor {
  public constructor(private widgetHandle: WidgetHandle) {}

  load(key: string): {
    columnsByProperty;
    columnsWithFilters;
    filterPositions;
  } {
    return {
      columnsByProperty: this.widgetHandle.state.columnsByProperty,
      columnsWithFilters: this.widgetHandle.state.columnsWithFilters,
      filterPositions: this.widgetHandle.state.filterPositions,
    };
  }

  save(
    data: { columnsByProperty; columnsWithFilters; filterPositions },
    key: string,
  ): void {
    this.widgetHandle.state = {
      columnsByProperty: data.columnsByProperty,
      columnsWithFilters: data.columnsWithFilters,
      filterPositions: data.filterPositions,
    };
  }
}
