export interface WidgetConfiguration {
  configuration: {
    [id: string]: {
      get: () => any;
      set: (value: any) => void;
    };
  };

  isConfigurationValid(): boolean;

  initConfiguration(): void | Promise<void>;
}
