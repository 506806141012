import { DataConversion, Optional, Required } from "@incert/data-conversion";

@DataConversion()
export class MenuEntry {
  @Required()
  title: string;

  @Required()
  action: string;

  @Optional()
  routerLink: string;
}
