import { MenuResponseModel } from "./menu-response.model";
import { MenuStateHandle } from "./menu-state-handle";

export class MenuStateEntry {
  public key: string;
  public name: string;
  public permission: string;
  public hasPermission: boolean;
  public hidden: boolean;
  public onlyHiddenEntries = false;
  public route: string;
  public path: MenuStateEntry[];
  public expanded = true;
  public markedAsFavorite = false;
  public entries: MenuStateEntry[];
  public tags: string[] = [];
  public helpCategories: number[] = [];
  public helpSections: number[] = [];
  public helpArticles: number[] = [];
  private _active = false;
  public additionalData: any = {};
  public searchable = true;

  public get active() {
    return this._active;
  }

  public get hasHelp() {
    return (
      this.helpCategories.length ||
      this.helpSections.length ||
      this.helpArticles.length
    );
  }

  public select(toggle = true, additionalData = null) {
    if (this.entries && this.entries.length) {
      if (toggle) {
        this.expanded = !this.expanded;
      } else {
        this.expanded = true;
      }
    } else {
      this.expanded = true;
    }
    this.active = true;
    this.menuStateHandle.selectMenuEntry(this, additionalData);
  }

  public toggleFavorite() {
    if (!this.markedAsFavorite) {
      this.menuStateHandle.addFavorite(this);
    } else {
      this.menuStateHandle.removeFavorite(this);
    }
  }

  public set active(value: boolean) {
    // activate
    if (value === true) {
      // deactivate all;
      this.menuStateHandle.deactivateAll();
      // activate path entries
      for (const entry of this.path) {
        entry._active = true;
      }
    }
    // deactivate
    else {
      this._active = false;
      if (this.entries) {
        for (const entry of this.entries) {
          entry.active = false;
        }
      }
    }
  }

  protected constructor(
    key: string,
    name: string,
    path: MenuStateEntry[],
    protected menuStateHandle: MenuStateHandle,
  ) {
    this.key = key;
    this.name = name;
    this.path = [...path, this];

    if (path.length) {
      this.searchable = path[path.length - 1].searchable;
    }

    // not set in root
    if (this.menuStateHandle) {
      // resolve name
      if (this.menuStateHandle.nameResolver) {
        this.name = this.menuStateHandle.nameResolver(key);
      }
      // resolve tags
      if (this.menuStateHandle.tagsResolver) {
        this.tags = this.menuStateHandle.tagsResolver(key);
      }
    }
  }

  public find(findFn: (entry: MenuStateEntry) => boolean): MenuStateEntry[] {
    let entries: MenuStateEntry[] = [];
    if (this.entries) {
      for (const entry of this.entries) {
        entries = [...entries, ...entry.find(findFn)];
      }
    }
    if (findFn(this)) {
      entries.push(this);
    }
    return entries;
  }

  protected _build(menuResponse: MenuResponseModel) {
    this.entries = [];
    this.onlyHiddenEntries = true;
    for (const key of Object.keys(menuResponse)) {
      const menuResponseEntry = menuResponse[key];
      const menuStateEntry = new MenuStateEntry(
        key,
        key,
        this.path,
        this.menuStateHandle,
      );
      if (menuResponseEntry.route) {
        menuStateEntry.route = menuResponseEntry.route;
      }
      if (menuResponseEntry.searchable === false) {
        menuStateEntry.searchable = false;
      }
      if (menuResponseEntry.entries) {
        menuStateEntry._build(menuResponse[key].entries);
      }
      if (menuResponseEntry.zendeskHelp) {
        const entries = menuResponseEntry.zendeskHelp
          .split("|")
          .map((numbers) => {
            if (numbers === "") {
              return [];
            }
            return numbers.split(",").map((a) => parseInt(a, 10));
          });
        menuStateEntry.helpCategories = entries[0];
        menuStateEntry.helpSections = entries[1];
        menuStateEntry.helpArticles = entries[2];
      }
      if (menuResponseEntry.permission) {
        menuStateEntry.permission = menuResponseEntry.permission;
      }
      if (menuResponseEntry.hasPermission) {
        menuStateEntry.hasPermission = menuResponseEntry.hasPermission;
      }
      if (menuResponseEntry.hidden) {
        menuStateEntry.hidden = menuResponseEntry.hidden;
      }
      if (!menuStateEntry.hidden) {
        this.onlyHiddenEntries = false;
      }

      this.entries.push(menuStateEntry);
    }
  }
}
