import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class SummaryConfiguration {
  @Optional()
  orderTotalId = "";

  @Optional()
  accountId = "";

  @Optional()
  departmentCode = "";

  @Optional()
  taxRate = "";
}
