import { DataConversion, Required, Optional } from "@incert/data-conversion";
import { EventReservation } from "./event-reservation.model";

@DataConversion()
export class EventReservationHistory {
  @Required()
  id: number;

  @Optional()
  reservation: EventReservation;

  @Optional()
  comment: string;

  @Required()
  dateAdded: Date;
}
