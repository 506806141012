import { Component, Input } from "@angular/core";
import { GMSUrlResolver } from "@incert/gms-core";

@Component({
  selector: "incert-invoice-download",
  templateUrl: "./invoice-download.component.html",
  styleUrls: ["./invoice-download.component.scss"],
})
export class InvoiceDownloadComponent {
  @Input()
  invoiceArchiveId = "";

  constructor(public urlResolver: GMSUrlResolver) {}

  public async downloadInvoiceFromArchive() {
    window.location.assign(
      this.urlResolver.getInvoiceArchiveUrl() + this.invoiceArchiveId,
    );
  }
}
