import { Component, forwardRef, Input, OnInit, OnDestroy } from "@angular/core";
import { NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "incert-sorted-multiselect",
  templateUrl: "./sorted-multiselect.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SortedMultiselectComponent),
      multi: true,
    },
  ],
})
export class SortedMultiselectComponent implements OnInit, OnDestroy {
  @Input()
  public options: any;

  @Input()
  public placeholder: string;

  private subscription: Subscription;

  private _onChange: (_: any) => void;
  private _onTouched: (_: any) => void;

  private _selectedOptions: any[] = [];
  private _touched = false;

  public fc = new UntypedFormControl([]);
  form: UntypedFormControl;

  constructor() {}

  ngOnInit(): void {
    this.subscription = this.fc.valueChanges.subscribe((v) => {
      this._selectedOptions = v;
      this.change();
    });

    const formDefinition = {};
    for (const group of this.options) {
      formDefinition[group.value] = [false];
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  writeValue(arr: any[]): void {
    if (Array.isArray(arr)) {
      this._selectedOptions = arr;
      this.fc.setValue(arr, { emitEvent: false });
    }
  }

  onPanelShow() {
    this.options.sort((a, b) => {
      const isSelectedA = this.fc.value.includes(a.value);
      const isSelectedB = this.fc.value.includes(b.value);

      // Sort selected items to the top
      if (isSelectedA && !isSelectedB) return -1;
      if (!isSelectedA && isSelectedB) return 1;

      // Sort by label if both are selected or both are unselected
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    });

    // this.options = this._selectedOptions;
    this.writeValue(this.fc.value);
    this.change();
  }

  private change() {
    if (this._onChange) {
      this._onChange(this._selectedOptions);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  markAsTouched() {
    if (!this._touched) {
      if (this._onTouched) this._onTouched(this._touched);
      this._touched = true;
    }
  }
}
