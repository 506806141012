export { DashboardModule } from "./lib/dashboard.module";
export * from "./lib/shared/dashboard-config.model";
export * from "./lib/shared/interface/configurable-widget.interface";
export * from "./lib/shared/interface/subtitle-widget.interface";
export * from "./lib/shared/dashboard-filter.service";
export * from "./lib/google-charts/google-chart.service";
export * from "./lib/shared/title-resolver/title-resolver.interface";
export * from "./lib/shared/widget-handle.service";
export * from "./lib/shared/widget.decorator";
export * from "./lib/shared/widget-configuration/widget-configuration.service";
export * from "./lib/dashboard-i18n.token";
export * from "./lib/shared/widget-configuration/widget-configuration.interface";
export * from "./lib/shared/widget-configuration/widget-configuration.description";
export * from "./lib/shared/widget-configuration/global-widget-configration-key.const";
export * from "./lib/shared/interface/widget-properties.interface";
export * from "./lib/shared/widget-filter/dashboard-widget-filter.interface";
export * from "./lib/shared/persistor/dashboard-persistor.interface";
export * from "./lib/shared/persistor/dashboard-local-storage-persistor.service";
export * from "./lib/shared/widget.model";
