import { Component, OnInit } from "@angular/core";
import {
  ConfirmService,
  LoadingService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { ContactGroupAddComponent } from "./contact-group-add/contact-group-add.component";
import { ContactGroup } from "./contact-group.model";
import { ContactGroupManagerService } from "./contact-group-manager.service";
import { ContactEmail } from "../contact-email/contact-email.model";
import { SelectItem } from "primeng/api";
import { ContactGroupContactsComponent } from "./contact-group-contacts/contact-group-contacts.component";
import { ContactEmailManagerService } from "../contact-email/contact-email-manager.service";
import { AuthManager } from "../../../core/auth";
import { AccountSystemGroupManager } from "../../account-system-group/account-system-group-manager.service";

export enum ContactGroupFrequency {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

@Component({
  selector: "contact-group-overview",
  templateUrl: "./contact-group.component.html",
  styleUrls: ["./contact-group.component.css"],
})
export class ContactGroupComponent implements OnInit {
  constructor(
    private overlayService: OverlayService,
    private contactGroupManager: ContactGroupManagerService,
    private contactEmailManager: ContactEmailManagerService,
    private accountSystemGroupManager: AccountSystemGroupManager,
    private loadingService: LoadingService,
    private confirmService: ConfirmService,
    private authManager: AuthManager,
  ) {}

  contactGroups: ContactGroup[] = [];
  dtConfig: DataTableConfig<ContactGroup>;
  accountSystems: SelectItem[] = [];
  accountSystemGroups: any[] = [];
  contactRoles: SelectItem[] = [];
  reportDashboards: SelectItem[] = [];

  async ngOnInit() {
    await this.loadingService.load(async () => {
      await this.init();
    });
    this.accountSystemGroups =
      await this.accountSystemGroupManager.getAccountSystemGroupsSI();
    this.reportDashboards = await this.contactGroupManager.getDashboards();
    this.contactRoles = await this.contactEmailManager.getContactRoles();
  }

  async init() {
    await this.loadingService.load(async () => {
      this.contactGroups = await this.contactGroupManager.getContactGroups();
      await this.createDtConfig();
    });
  }

  private async createDtConfig() {
    const arrayDataSource = new ArrayDataSource(this.contactGroups);
    this.dtConfig = {
      mode: "pagination",
      rows: 15,
      data: arrayDataSource,
      emptyMessage: "Keine Systeme zugeteilt",
      filterArea: {
        filters: [
          {
            header: "Bezeichnung",
            property: (v) => v.get("name"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
        ],
      },
      columns: [
        {
          header: "Bezeichnung",
          property: (v) => v.get("name"),
          sort: true,
        },
        {
          header: "Aktionen",
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "edit",
                  onClick: (row: ContactGroup) => this.groupUpsertOverlay(row),
                  tooltip: "Bearbeiten",
                  hidden: !this.writeContactGroup,
                },
                {
                  icon: "file",
                  onClick: (row: ContactGroup) =>
                    this.groupContactsOverlay(row),
                  tooltip: "Empfänger",
                  hidden: !this.readContactGroup,
                },
                {
                  icon: "delete",
                  onClick: (row: ContactGroup) => this.groupDelete(row),
                  tooltip: "Löschen",
                  hidden: !this.executeContactGroup,
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              icon: "plus",
              label: "Kontaktgruppe",
              onClick: () => this.groupUpsertOverlay(),
            },
          ],
        }),
      ],
    };
  }

  public async groupUpsertOverlay(contactGroup?: ContactGroup) {
    await this.overlayService.show<ContactGroupAddComponent>({
      size: OverlaySize.medium,
      type: ContactGroupAddComponent,
      header: contactGroup
        ? "Kontaktgruppe bearbeiten"
        : "Kontaktgruppe hinzufügen",
      displayAsSidebar: true,
      init: (component) => {
        component.contactGroup = contactGroup;
        component.contactGroupComp = this;
        component.accountSystemGroups = this.accountSystemGroups;
        component.contactRoles = this.contactRoles;
        component.reportDashboards = this.reportDashboards;
      },
      actions: [
        {
          label: "Speichern",
          action: (component) => component.upsertContactGroup(),
        },
        {
          label: "Abbrechen",
          action: () => true,
          displayAsLink: true,
        },
      ],
    });
  }

  public async groupContactsOverlay(contactGroup: ContactGroup) {
    await this.overlayService.show<ContactGroupContactsComponent>({
      size: OverlaySize.large,
      type: ContactGroupContactsComponent,
      displayAsSidebar: true,
      header: "Empfänger",
      init: async (component) => {
        component.contactGroup = contactGroup;
        component.contactGroupComp = this;
      },
      actions: [
        {
          label: "Schließen",
          action: () => true,
          displayAsLink: true,
        },
      ],
    });
  }

  public async groupDelete(contactGroup?: ContactGroup) {
    const response = await this.confirmService.confirmError(
      "",
      "Kontaktgruppe löschen",
    );
    if (response) {
      await this.contactGroupManager.deleteContactGroup(contactGroup.id);
      await this.init();
    }
  }

  public get readContactGroup(): boolean {
    return this.authManager.hasPermission("readContactGroup");
  }

  public get writeContactGroup(): boolean {
    return this.authManager.hasPermission("writeContactGroup");
  }

  public get executeContactGroup(): boolean {
    return this.authManager.hasPermission("executeContactGroup");
  }
}
