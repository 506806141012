import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { VoucherDownloadHistoryType } from "../enums/voucher-download-history.enum";

@DataConversion()
export class VoucherDownloadHistoryModel {
  @Required()
  id: number;

  @Optional()
  type: VoucherDownloadHistoryType;

  @Optional()
  code: string;

  @Optional()
  created: Date;
}
