import { Injectable } from "@angular/core";
import { ApiHttpAccess } from "../http/api-http-access.service";
import { ApiHttpError } from "../http/api-http.error";
import { HttpErrorResponse } from "@angular/common/http";
import { ZendeskArticleModel } from "./zendesk-article.model";

@Injectable({ providedIn: "root" })
export class ZendeskService {
  public constructor(private apiHttpAccess: ApiHttpAccess) {}

  public async getArticlesFromCategory(locale: string, category: number) {
    try {
      return await this.apiHttpAccess.get<ZendeskArticleModel[]>(
        `zendesk/articles/category/${locale}/${category}`,
        ZendeskArticleModel,
      );
    } catch (e) {
      if (e instanceof ApiHttpError) {
        throw e;
      }

      if (
        e instanceof HttpErrorResponse &&
        e.error["error"] &&
        e.error["message"]
      ) {
        throw new ApiHttpError(e.error);
      }

      throw e;
    }
  }

  public async getArticlesFromSection(locale: string, section: number) {
    try {
      return await this.apiHttpAccess.get<ZendeskArticleModel[]>(
        `zendesk/articles/section/${locale}/${section}`,
        ZendeskArticleModel,
      );
    } catch (e) {
      if (e instanceof ApiHttpError) {
        throw e;
      }

      if (
        e instanceof HttpErrorResponse &&
        e.error["error"] &&
        e.error["message"]
      ) {
        throw new ApiHttpError(e.error);
      }

      throw e;
    }
  }

  public async getArticle(locale: string, article: number) {
    try {
      return await this.apiHttpAccess.get<ZendeskArticleModel>(
        `zendesk/article/${locale}/${article}`,
        ZendeskArticleModel,
      );
    } catch (e) {
      if (e instanceof ApiHttpError) {
        throw e;
      }

      if (
        e instanceof HttpErrorResponse &&
        e.error["error"] &&
        e.error["message"]
      ) {
        throw new ApiHttpError(e.error);
      }

      throw e;
    }
  }
}
