import { DataConversion, Required } from "@incert/data-conversion";
import { ConfigurationEntry } from "./configuration-entry.model";

@DataConversion()
export class TranslatedConfigurationEntry extends ConfigurationEntry {
  @Required()
  title: string;

  @Required()
  description: string;
}
