import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { TmaTaskStatus } from "../enums/tma-task-status.enum";

@DataConversion()
export class TmaTaskModel {
  @Required()
  uuid: string;
  @Required()
  createdAt: string;
  @Required()
  expectedTriggerTime: string;
  @Optional()
  triggerTime: string;
  @Required()
  targetEmailAddress: string;
  @Required()
  status: TmaTaskStatus;
  @Required()
  tmaEventId: number;
  @Required()
  triggerEvent: string;
}
