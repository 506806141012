import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Review } from "./review.model";

@DataConversion()
export class ReviewImage {
  @Required()
  review: Review;

  @Required()
  link: string;

  @Optional()
  altText: string;
}
