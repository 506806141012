import {
  DataConversion,
  Required,
  Optional,
  ArrayType,
} from "@incert/data-conversion";
import { Customer } from "./customer.model";
import { EventReservationProduct } from "./event-reservation-product.model";
import { Order } from "./order.model";
import { EventReservationParticipantHistory } from "./event-reservation-participant-history.model";
import { OrderProduct } from "./order-product.model";

@DataConversion()
export class EventReservationProductParticipant {
  @Required()
  id: number;

  @Optional()
  reservationProduct: EventReservationProduct;

  @Optional()
  customer: Customer;

  @Required()
  state: string;

  @Optional()
  order: Order;

  @Optional()
  dateReserved: Date;

  @Required()
  checked: boolean;

  @Optional()
  reservationFeeOrderProduct: OrderProduct;

  @Optional()
  @ArrayType(() =>
    import("./event-reservation-participant-history.model").then(
      (mod) => mod.EventReservationParticipantHistory,
    ),
  )
  participantHistory: EventReservationParticipantHistory[];

  @Optional()
  additionalData: any;
}
