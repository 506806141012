import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SortedMultiselectComponent } from "./sorted-multiselect.component";
import { MultiSelectModule } from "primeng/multiselect";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [SortedMultiselectComponent],
  exports: [SortedMultiselectComponent],
  imports: [CommonModule, MultiSelectModule, ReactiveFormsModule],
})
export class SortedMultiselectModule {}
