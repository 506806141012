import { Injector, ProviderToken } from "@angular/core";
import { DataTableRefresh, DT_REFRESH } from "../data-table-refresh.token";
import { ROW_DATA } from "./row-data.token";

export class ColumnHostInjector implements Injector {
  constructor(
    private parent: Injector,
    private data: any,
    private refreshFn: DataTableRefresh,
  ) {}

  get<T>(token: ProviderToken<T>, notFoundValue?: T): T {
    if (token === ROW_DATA) {
      return this.data;
    }
    if (token === DT_REFRESH) {
      return this.refreshFn as T;
    }

    return this.parent.get(token, notFoundValue);
  }
}
