import { Inject, Injectable } from "@angular/core";
import { Environment } from "../../models/environment.model";
import { IncertI18nLoader } from "@incert/incert-core";
import { MenuResponseModel } from "./menu-response.model";
import { MenuI18n } from "./menu-i18n.model";
import { ApiHttpAccess } from "../http/api-http-access.service";
import { MenuState } from "./menu-state";
import { ENVIRONMENT } from "../environment.token";

@Injectable({
  providedIn: "root",
})
export class MenuResolver {
  public constructor(
    private httpAccess: ApiHttpAccess,
    private incerti18nLoader: IncertI18nLoader,
    @Inject(ENVIRONMENT) private environment: Environment,
  ) {}

  public async resolve(
    menuState: MenuState,
    preserverPermission = false,
    customerId = null,
  ) {
    let url = "menu" + (preserverPermission ? "?preservePermission" : "");

    if (customerId) {
      url += "&customerId=" + customerId;
    }

    const language = this.environment.currentLanguage.code;
    const additionalLanguage = language === "en" ? "de" : "en";

    // load menu
    const menuResponsePromise = this.httpAccess.get(url);
    // load menui18n
    const menuI18nPromise = this.incerti18nLoader.load("gms/menu", language);
    const addLangMenuI18nPromise = this.incerti18nLoader.load(
      "gms/menu",
      additionalLanguage,
    );

    let favorites = null;
    if (!preserverPermission) {
      // load favorites
      const favoritesPromise = this.httpAccess.get("menu/favorites");
      favorites = <string[]>await favoritesPromise;
    }
    const menuResponse = <MenuResponseModel>await menuResponsePromise;
    const addLangMenuI18n = <MenuI18n>await addLangMenuI18nPromise;
    const menuI18n = <MenuI18n>await menuI18nPromise;

    if (menuI18n && menuI18n.gms) {
      // set name resolver
      menuState.nameResolver = (key) => {
        if (key in menuI18n.gms.menu) {
          return menuI18n.gms.menu[key].name;
        }
        return key;
      };
      // set tag resolver
      menuState.tagsResolver = (key) => {
        let tags = [];
        if (
          key in menuI18n.gms.menu &&
          Array.isArray(menuI18n.gms.menu[key].tags)
        ) {
          tags = [...menuI18n.gms.menu[key].tags];
        }
        if (key in addLangMenuI18n.gms.menu) {
          const tagsEn = Array.isArray(addLangMenuI18n.gms.menu[key].tags)
            ? addLangMenuI18n.gms.menu[key].tags
            : [];
          tags = [...tags, ...tagsEn, addLangMenuI18n.gms.menu[key].name];
        }
        return tags;
      };
    }

    // build menu
    menuState.build(menuResponse, favorites);
  }
}
