import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class LoginResponse {
  @Required()
  public access_token: string;

  @Required()
  public username: string;

  @Required()
  public token_type: string;

  @Required()
  public customerId: number;
}
