import { EventEmitter, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { EnvironmentService } from "../environment.service";

@Injectable()
export class GMSAuthGuard implements CanActivate {
  public onInvalidAccess = new EventEmitter();

  public constructor(private environmentService: EnvironmentService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    if (
      this.environmentService.environment === null ||
      this.environmentService.environment.adminLogin === false
    ) {
      this.onInvalidAccess.emit();
      return false;
    }
    return true;
  }
}
