import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class BookingConfiguration {
  @Optional()
  products_id: string;

  @Optional()
  products_tax_class_id: number;

  @Optional()
  tax_rate: number;

  @Optional()
  products_name: string;

  @Optional()
  account_id: string;

  @Optional()
  dc_order: string;

  @Optional()
  dc_redemption: string;

  @Optional()
  dc_expire: string;
}
