import { firstValueFrom, ReplaySubject } from "rxjs";
import { ApiHttpAccess } from "./http/api-http-access.service";
import { Injectable } from "@angular/core";
import { ProductListEntry } from "../models";
import { SelectItem } from "primeng/api";

@Injectable({ providedIn: "root" })
export class ProductService {
  private _productListEntries$ = new ReplaySubject<ProductListEntry[]>(1);
  private _productListEntriesInit = false;

  private _ticketProductListEntries$ = new ReplaySubject<ProductListEntry[]>(1);
  private _ticketProductListEntriesInit = false;

  public constructor(private httpAccess: ApiHttpAccess) {}

  public refreshProductListEntries() {
    this.httpAccess
      .get<ProductListEntry[]>("productList", ProductListEntry)
      .then((v) => this._productListEntries$.next(v));
  }

  public get productListEntries$() {
    if (!this._productListEntriesInit) {
      this.refreshProductListEntries();
      this._productListEntriesInit = true;
    }
    return this._productListEntries$.asObservable();
  }

  public async refreshTicketProductListEntries() {
    const productListEntries = await this.httpAccess.get<ProductListEntry[]>(
      "productList?showOnlyTickets",
      ProductListEntry,
    );
    this._ticketProductListEntries$.next(productListEntries);
  }

  public get ticketProductListEntries$() {
    if (!this._ticketProductListEntriesInit) {
      this.refreshTicketProductListEntries().then();
      this._ticketProductListEntriesInit = true;
    }
    return this._ticketProductListEntries$.asObservable();
  }

  public async getTicketProductSI(): Promise<SelectItem[]> {
    return (await firstValueFrom(this.ticketProductListEntries$)).map((ple) => {
      return {
        label: ple.name,
        value: ple.id,
      };
    });
  }
}
