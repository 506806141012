import {
  booleanAttribute,
  Component,
  forwardRef,
  Inject,
  Input,
  numberAttribute,
  OnDestroy,
} from "@angular/core";
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
} from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { CurrencyFormatPipe } from "../currency-format-pipe/currency-format.pipe";
import { Subscription } from "rxjs";
import { isNumeric } from "@incert/incert-core";
import { I18nService } from "@incert/i18n";
import { INCERT_GUI_I18N, IncertGUII18n } from "../incert-gui-i18n.token";

@Component({
  selector: "incert-number-input",
  templateUrl: "./number-input.component.html",
  styleUrls: ["./number-input.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent
  implements ControlValueAccessor, OnDestroy, Validator
{
  private readonly POINT_SEPARATOR = ".";
  private readonly COMMA_SEPARATOR = ",";

  @Input()
  public placeholder = "";

  @Input({ transform: numberAttribute })
  public decimals?: number;

  @Input({ transform: numberAttribute })
  public min?: number;

  @Input({ transform: numberAttribute })
  public max?: number;

  @Input()
  public set prefix(v: string) {
    this._prefix = v ? v + " " : "";
  }

  @Input()
  public set postfix(v: string) {
    this._postfix = v ? " " + v : "";
  }

  @Input({ transform: booleanAttribute })
  public isCurrency = false;

  @Input()
  public currencyCode: string | undefined = undefined;

  @Input({ transform: booleanAttribute })
  allowZeroValue = false;

  private _prefix = "";
  private _postfix = "";
  private subscription: Subscription;
  private errors: ValidationErrors | null = null;

  public fc = new UntypedFormControl();
  private _onChange = (_: any) => {};

  constructor(
    private currencyFormatPipe: CurrencyFormatPipe,
    private i18n: I18nService,
    @Inject(INCERT_GUI_I18N) private i18nGUI: IncertGUII18n,
  ) {
    this.subscription = this.fc.valueChanges
      .pipe(debounceTime(250))
      .subscribe((v) => {
        if (v) {
          this._onChange(this.validateNumber(v));
        } else {
          this._onChange(undefined);
        }
      });
    this.i18n.load("core/validation").then((r) => r);
  }

  validate(): ValidationErrors | null {
    return this.errors;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private validateNumber(v: string): number {
    this.errors = null;

    v = v.toString().replace(/\s+/g, "");

    const fullPrefix = this.fullPrefix.replace(/\s+/g, "");
    if (v.startsWith(fullPrefix)) {
      v = v.substring(fullPrefix.length);
    }

    const fullPostfix = this.fullPostfix.replace(/\s+/g, "");
    if (v.endsWith(fullPostfix)) {
      v = v.substring(0, v.length - fullPostfix.length);
    }

    const separator = this.getSeparator(v);

    let groupCharRegex = /,/g;
    if (separator === this.COMMA_SEPARATOR) {
      groupCharRegex = /\./g;
    }

    v = v.replace(groupCharRegex, "").replace(/,/g, ".");

    if (v !== "" && !isNumeric(v)) {
      this.errors = {
        numberEnsure: true,
      };
      return undefined;
    }

    const maxDecimals =
      this.decimals === undefined && this.isCurrency ? 2 : this.decimals;
    if (maxDecimals !== undefined) {
      const parts = v.split(".");
      if (parts.length === 2) {
        if (maxDecimals == 0) {
          this.errors = { numberNoDecimals: true };
          return undefined;
        } else if (parts[1].length > maxDecimals) {
          this.errors = { numberMaxDecimals: { decimals: maxDecimals } };
          return undefined;
        }
      }
    }

    if (v === "") {
      return undefined;
    }

    const number = parseFloat(v);
    if (this.min !== undefined && number < this.min) {
      this.errors = {
        numberMin: {
          min: this.min,
        },
      };
      return undefined;
    }
    if (this.max !== undefined && number > this.max) {
      this.errors = {
        numberMax: {
          max: this.max,
        },
      };
      return undefined;
    }
    return number;
  }

  public correctDisplayValue(): void {
    const separator = this.getSeparator(this.fc.value ?? "");
    const number = this.validateNumber(this.fc.value ?? "");
    if (number === undefined) {
      return;
    }

    if (number === 0 && !this.allowZeroValue) {
      this.fc.patchValue(undefined, { emitEvent: false });
    } else {
      let displayValue = number
        .toString()
        .replace(this.POINT_SEPARATOR, separator);
      if (this.isCurrency) {
        displayValue = this.currencyFormatPipe.transform(
          number,
          false,
          this.currencyCode,
        );
      }
      this.fc.patchValue(this._prefix + displayValue + this._postfix, {
        emitEvent: false,
      });
    }
  }

  private getSeparator(v: string): string {
    v = v.toString();
    const result = v.match(/[.,]/gm);
    return result ? result.pop() : this.POINT_SEPARATOR;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.fc.patchValue(obj, { emitEvent: false });
    this.correctDisplayValue();
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.fc.disable({ emitEvent: false });
    } else {
      this.fc.enable({ emitEvent: false });
    }
  }

  private get fullPrefix() {
    const currencyData = this.currencyData;
    return (
      this._prefix +
      (currencyData.symbolLeft ? currencyData.symbolLeft + " " : "")
    );
  }

  private get fullPostfix() {
    const currencyData = this.currencyData;
    return (
      this._postfix +
      (currencyData.symbolRight ? " " + currencyData.symbolRight : "")
    );
  }

  private get currencyData(): { symbolLeft: string; symbolRight: string } {
    if (!this.isCurrency) {
      return { symbolLeft: "", symbolRight: "" };
    }

    const currencyCode = this.currencyCode ?? this.i18nGUI.currency;
    return (
      this.i18nGUI.currencyByCode[currencyCode] ?? {
        symbolLeft: "",
        symbolRight: currencyCode,
      }
    );
  }
}
