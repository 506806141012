import { DataConversion, Required } from "@incert/data-conversion";
import { TmaEventGroup } from "../enums";

@DataConversion()
export class TmaEventType {
  @Required()
  type: string;

  @Required()
  group: TmaEventGroup;

  @Required()
  emailType: string;
}
