import { Inject, Injectable } from "@angular/core";
import { ApiHttpAccess } from "./http/api-http-access.service";
import { ENVIRONMENT } from "./environment.token";
import { Environment } from "../models";

@Injectable()
export class AccessTokenService {
  public accessToken: string = null;
  private _accessTokenPromise = null;
  private _customerId: number;

  public constructor(
    private httpAccess: ApiHttpAccess,
    @Inject(ENVIRONMENT) private environment: Environment,
  ) {}

  public async getRequestToken() {
    if (
      !this._accessTokenPromise ||
      this._customerId !== this.environment.customer.id
    ) {
      this._accessTokenPromise = new Promise<void>(async (resolve) => {
        this.accessToken = await this.httpAccess.get("environment/accessToken");
        resolve();
      });
      this._customerId = this.environment.customer.id;
    }
    await this._accessTokenPromise;
    return this.accessToken;
  }
}
