import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InvoiceDownloadComponent } from "./invoice-download.component";
import { IconButtonModule } from "../icon-button/icon-button.module";
import { I18nModule } from "@incert/i18n";

@NgModule({
  imports: [CommonModule, IconButtonModule, I18nModule],
  exports: [InvoiceDownloadComponent],
  declarations: [InvoiceDownloadComponent],
})
export class InvoiceDownloadModule {}
