import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class SeatsIOChartModel {
  @Required()
  archived: boolean;

  @Required()
  draftVersionThumbnailUrl: string;

  @Required()
  events: string;

  @Required()
  id: number;

  @Required()
  key: string;

  @Required()
  name: string;

  @Required()
  publishedVersionThumbnailUrl: string;

  @Required()
  status: "NOT_USED" | "PUBLISHED" | "PUBLISHED_WITH_DRAFT";

  @Required()
  tags: string[];

  @Required()
  validation: string;
}
