import { Injectable } from "@angular/core";
import { OpeningTime } from "../models/opening-time.model";
import { ApiHttpAccess } from "../services";

@Injectable()
export class OpeningTimeDAO {
  public constructor(private httpAccess: ApiHttpAccess) {}

  public async getAll(): Promise<OpeningTime[]> {
    return await this.httpAccess.get<OpeningTime[]>("openingTime", OpeningTime);
  }

  public async create(openingTime: OpeningTime): Promise<void> {
    await this.httpAccess.post("openingTime", openingTime);
  }

  public async update(openingTime: OpeningTime): Promise<void> {
    await this.httpAccess.put("openingTime", openingTime);
  }

  public async delete(openingTime: OpeningTime): Promise<void> {
    await this.httpAccess.delete("openingTime/" + openingTime.id);
  }
}
