import { Component, OnInit } from "@angular/core";

@Component({
  selector: "incert-default-invalid-widget-placeholder",
  templateUrl: "./default-invalid-widget-placeholder.component.html",
  styleUrls: ["./default-invalid-widget-placeholder.component.css"],
})
export class DefaultInvalidWidgetPlaceholderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
