<form [formGroup]="formGroup">
  <form-field label="Bezeichnung">
    <input pInputText type="text" [formControlName]="nameofForm('name')" placeholder="Bezeichnung">
  </form-field>
  <form-field label="System Filter">
    <p-dropdown
      [options]="accountSystemFilters"
      [formControlName]="nameofForm('accountSystemFilter')"
      (onChange)="changeFilter()"
      appendTo="body"
      [showClear]="true"
      placeholder="System Filter">
    </p-dropdown>
  </form-field>
  <form-field label="Systeme">
    <incert-sorted-multiselect
      [options]="accountSystemSI"
      [formControlName]="nameofForm('accountSystems')">
    </incert-sorted-multiselect>
  </form-field>
</form>
