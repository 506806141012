import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { ProductOption } from "./product-option.model";

@DataConversion()
export class ProductOptionSelectionType {
  @Required()
  productOption: ProductOption;

  @Optional()
  selectionType: string;

  @Optional()
  filterType: string;

  @Optional()
  isVariantSet: boolean;
}
