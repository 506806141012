import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class OrderPickUpInfo {
  @Optional()
  identifierGroup: string;

  @Optional()
  date: Date;

  @Optional()
  dateSecondaryDescriptor: string;

  @Optional()
  location: string;
}
