import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { AccessGroupModel } from "./access-group.model";
import { AccessLevelModel } from "./access-level.model";
import { Manufacturer } from "./manufacturer.model";

@DataConversion()
export class AccessProfileModel {
  @Required()
  public id: number;
  @Optional()
  public title: string;
  @Optional()
  public description: string;
  @Optional()
  @ArrayType(() =>
    import("./access-group.model").then((mod) => mod.AccessGroupModel),
  )
  public accessGroups: AccessGroupModel[];
  @Optional()
  @ArrayType(() =>
    import("./access-level.model").then((mod) => mod.AccessLevelModel),
  )
  public accessLevels: AccessLevelModel[];
  @Optional()
  @ArrayType(() =>
    import("./manufacturer.model").then((mod) => mod.Manufacturer),
  )
  public manufacturers: Manufacturer[];
  @Optional()
  public interfaceType: string;
  @Optional()
  public interfaceEntries: any;
}
