import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextFilterComponent } from "./text-filter.component";
import { ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { FormExtensionModule } from "../../form-extension";
import { TooltipModule } from "../../tooltip/tooltip.module";

@NgModule({
  declarations: [TextFilterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    FormExtensionModule,
    TooltipModule,
  ],
})
export class TextFilterModule {}
