import { DataConversion, Required } from "@incert/data-conversion";
import { TrackingSettingModel } from "./tracking-setting.model";
import { TrackingEventModel } from "./tracking-event.model";

@DataConversion()
export class TrackingModel {
  @Required()
  id: number;

  @Required()
  short: string;

  @Required()
  sortOrder: number;

  @Required()
  subShopId: number;

  @Required()
  enabled: boolean;

  @Required()
  pageViewEnabled: boolean;

  @Required()
  contactEnabled: boolean;

  @Required()
  viewProductEnabled: boolean;

  @Required()
  viewProductListingEnabled: boolean;

  @Required()
  viewCartEnabled: boolean;

  @Required()
  addToCartEnabled: boolean;

  @Required()
  removeFromCartEnabled: boolean;

  @Required()
  useDiscountEnabled: boolean;

  @Required()
  initiateCheckoutEnabled: boolean;

  @Required()
  checkoutConfirmationEnabled: boolean;

  @Required()
  purchaseEnabled: boolean;

  @Required()
  trackingSettings: TrackingSettingModel[];
}
