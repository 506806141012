import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class PaymentMethodLang {
  @Optional()
  id: number;

  @Optional()
  module: string;

  @Optional()
  title: string;

  @Optional()
  titleShop: string;

  @Optional()
  shortDescription: string;

  @Optional()
  description: string;
}
