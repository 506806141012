import { DataConversion, Optional, Required } from "@incert/data-conversion";

@DataConversion()
export class PasswordResetModel {
  @Optional()
  public token?: string;

  /** The old password. This is ignored when the user is forced to change his password. */
  @Required()
  public oldPassword: string;

  @Required()
  public password: string;

  @Required()
  public passwordConfirm: string;

  /** Determines if the password must be reset after the first login */
  @Required()
  public oneTime: boolean;
}
