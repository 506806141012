import { HttpAccess, HttpAccessInterface } from "@incert/incert-core";
import { DataConverter, ObjectOrArrayType } from "@incert/data-conversion";
import { BonResponseType } from "@incert/bon/bon-core/src/lib/http/bon-response-type.enum";
import { BonHttpError } from "@incert/bon/bon-core/src/lib/http/bon-http-error";
import { inject, InjectionToken } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ENVIRONMENT } from "@incert/gms-core";

export const BON_V1_HTTP_ACCESS = new InjectionToken<HttpAccessInterface>(
  "BON_V1_HTTP_ACCESS",
  {
    providedIn: "root",
    factory: () => {
      const environment = inject(ENVIRONMENT);
      return new BonHttpAccess(
        inject(HttpClient),
        environment.bonUrlV1 + "/api/v1/",
      );
    },
  },
);

export const BON_V2_HTTP_ACCESS = new InjectionToken<HttpAccessInterface>(
  "BON_V2_HTTP_ACCESS",
  {
    providedIn: "root",
    factory: () => {
      const environment = inject(ENVIRONMENT);
      const httpAccess = new HttpAccess(inject(HttpClient));
      httpAccess.baseHref = environment.bonUrlV2 + "/api/";
      return httpAccess;
    },
  },
);

class BonHttpAccess implements HttpAccessInterface {
  private httpAccess: HttpAccess;

  public constructor(
    protected httpClient: HttpClient,
    baseHref: string,
  ) {
    this.httpAccess = new HttpAccess(httpClient);
    this.httpAccess.baseHref = baseHref;
  }

  async get(path: string): Promise<any>;
  async get<T extends any>(
    path: string,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
  async get<T extends any>(path: any, type?: any) {
    const response = await this.httpAccess.get(path);
    return await this.processResponse(response, type);
  }

  async post(path: string, payload: any): Promise<any>;
  async post<T extends any>(
    path: string,
    payload: any,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
  async post<T extends any>(path: any, payload: any, type?: any) {
    const response = await this.httpAccess.post(path, payload);
    return await this.processResponse(response, type);
  }

  async delete(path: string): Promise<any>;
  async delete<T extends any>(
    path: string,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
  async delete<T extends any>(path: any, type?: any) {
    const response = await this.httpAccess.delete(path);
    return await this.processResponse(response, type);
  }

  async put(path: string, payload: any): Promise<any>;
  async put<T extends any>(
    path: string,
    payload: any,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
  async put<T extends any>(path: any, payload: any, type?: any) {
    const response = await this.httpAccess.put(path, payload);
    return await this.processResponse(response, type);
  }

  private async processResponse(response: any, type?: any) {
    if (response.type === BonResponseType.success) {
      if (type) {
        return await DataConverter.convert<any>(type, response.data);
      } else {
        return response.data;
      }
    } else {
      throw new BonHttpError(response.message);
    }
  }
}
