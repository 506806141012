import {
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Role } from "../role";
import { RoleManager } from "../role-manager.service";
import { User } from "../../user";
import { Permission } from "../permission";
import {
  ConfirmService,
  createNameof,
  LoadingService,
  NotificationService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { PermissionChangelist } from "../permission-changelist";
import { Subscription } from "rxjs";
import { IAuthManager } from "../../../core/auth";
import { DataTablePermissionCheckbox } from "../../../core/data-table-components/data-table-checkbox/permission-checkbox.component";
import { RoleConfirmDialogComponent } from "../role-confirm-dialog/role-confirm-dialog.component";

export const SELECTED_ROLES = new InjectionToken("SELECTED_ROLES");

@Component({
  selector: "role-overview",
  templateUrl: "./role-overview.component.html",
  styleUrls: ["./role-overview.component.scss"],
  providers: [
    {
      provide: SELECTED_ROLES,
      useValue: new UntypedFormGroup({}),
    },
  ],
})
export class RoleOverviewComponent implements OnInit, OnDestroy {
  public roles: Array<Role>;
  public statusDetailDataTable = 0;
  public selectedRole: Role;
  public assignedUsers: Array<User> = [];
  public permissionsPerRole: Array<Permission> = [];
  public permissions: Array<Permission> = [];
  public modifiedRole: Role;
  public permissionsChangelist: PermissionChangelist;
  public groupedPermissions: Array<Permission[]>;
  public showRoleConfirmDialog: boolean;
  public dynamicTables: Array<DataTableConfig<Permission>> = [];
  public tableGroups: Array<string> = [];
  public isEditRolesTable: boolean;
  public isNewRole: boolean;
  public roleTableConfig: DataTableConfig<Role>;
  public permissionTableConfig: DataTableConfig<Permission>;
  public assignedUsersTableConfig: DataTableConfig<User>;
  public nameofRoleOverviewForm = createNameof<Role>();
  public roleSubscription = new Subscription();

  constructor(
    @Inject("AuthManager") private authManager: IAuthManager,
    @Inject(SELECTED_ROLES) public form: UntypedFormGroup,
    private roleManager: RoleManager,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private overlayService: OverlayService,
    private confirmService: ConfirmService,
  ) {
    this.form.valueChanges.subscribe(
      (modifiedRole) => (this.modifiedRole = modifiedRole),
    );
    this.roleSubscription = this.roleManager.roleHasChanged.subscribe(() =>
      this.onPermissionsChanged(),
    );

    this.form.addControl(
      this.nameofRoleOverviewForm("name"),
      new UntypedFormControl(""),
    );
    this.form.addControl(
      this.nameofRoleOverviewForm("description"),
      new UntypedFormControl(""),
    );
    this.form.addControl(
      this.nameofRoleOverviewForm("permissions"),
      new UntypedFormGroup({}),
    );
  }

  public async ngOnInit() {
    await this.initialize();
  }

  private async initialize() {
    await this.loadingService.load(async () => {
      await this.loadRoleData();
      this.createRoleTableConfig();
    });
  }

  private async loadRoleData() {
    await this.getInitialRoleData();
  }

  public async getInitialRoleData() {
    try {
      this.roles = await this.roleManager.getRoles();
      this.permissions = await this.roleManager.getPermissions();
    } catch (e) {
      this.notificationService.notifyError(
        "Rollen anzeigen",
        "Es ist ein Fehler aufgetreten.",
      );
    }
  }

  public createRoleTableConfig() {
    this.roleTableConfig = {
      mode: "pagination",
      data: new ArrayDataSource(this.roles),
      rows: 25,
      columns: [
        {
          header: "Name",
          property: (v) => v.get("name"),
        },
        {
          header: "Aktionen",
          hidden: !this.writeRole,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "edit",
                  hidden: !this.writeRole,
                  onClick: (role: Role) =>
                    this.showEditPermissionsTable(role, false),
                  tooltip: "Bearbeiten",
                },
                {
                  icon: "info",
                  hidden: !this.writeRole,
                  onClick: (role: Role) => this.showAssignedPermissions(role),
                  tooltip: "Rechte",
                },
                {
                  icon: "community",
                  hidden: !this.writeRole,
                  onClick: (role: Role) => this.showAssignedUsers(role),
                  tooltip: "Benutzer",
                },
                {
                  icon: "delete",
                  hidden: !this.executeRole,
                  onClick: (role: Role) => this.deleteRole(role),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              icon: "plus",
              label: "Rolle",
              onClick: () => this.createRole(),
              hidden: !this.writeRole,
            },
          ],
        }),
      ],
    };
  }

  public createPermissionTableConfig() {
    this.permissionTableConfig = {
      mode: "pagination",
      rows: 20,
      data: new ArrayDataSource(this.permissions),
      columns: [
        {
          header: "name",
          property: (v) => v.get("name"),
          filter: createFilterDefinition(TextFilterComponent, {}),
        },
        {
          header: "groupName",
          property: (v) => v.get("groupName"),
          filter: createFilterDefinition(TextFilterComponent, {}),
        },
      ],
    };
  }

  public async createAssignedUsersTableConfig() {
    this.assignedUsersTableConfig = {
      mode: "pagination",
      data: new ArrayDataSource(this.assignedUsers),
      rows: 25,
      columns: [
        {
          header: "Vorname",
          property: (v) => v.get("firstName"),
        },
        {
          header: "Nachname",
          property: (v) => v.get("lastName"),
        },
      ],
    };
  }

  public createRolesTableConfig(selectedRole: Role) {
    if (this.groupedPermissions) {
      this.dynamicTables = [];
      this.groupedPermissions.forEach((groupedPermission: Permission[]) => {
        if (groupedPermission) {
          this.dynamicTables.push({
            mode: "pagination",
            data: new ArrayDataSource(groupedPermission),
            rows: 25,
            columns: [
              {
                header: "Bezeichnung",
                property: (v) => v.get("name"),
                sort: true,
              },
              {
                header: "Zuweisen",
                hidden: !this.isEditRolesTable || selectedRole.id === 1,
                component: createComponentConfiguration(
                  DataTablePermissionCheckbox,
                  {
                    resolveRowData: (row: Permission) => {
                      return {
                        permission: row,
                        permissionToEditRole: this.writeRole,
                        role: this.selectedRole,
                      };
                    },
                  },
                ),
              },
              {
                header: "Zuweisen",
                hidden: this.isEditRolesTable || selectedRole.id === 1,
                component: createComponentConfiguration(
                  DataTableIconButtonsComponent,
                  {
                    iconConfig: [
                      {
                        icon: "delete",
                        onClick: (row: Permission) =>
                          this.deletePermission(row),
                      },
                    ],
                  },
                ),
              },
            ],
          });
        }
      });
    }
  }

  public async createRole() {
    this.selectedRole = {
      name: "",
      description: "",
      permissions: [],
    };

    await this.showEditPermissionsTable(this.selectedRole, true);
  }

  public async saveChanges() {
    if (this.isNewRole) {
      await this.getPermissionChanges();
    } else {
      this.selectedRole.permissions =
        await this.roleManager.getPermissionsByRoleId(this.selectedRole.id);
      await this.getPermissionChanges();
    }
    if (this.permissionsChangelist) {
      await this.openRoleConfirmOverlay();
    }
  }

  public async getPermissionChanges() {
    const removed = [];
    const added = [];

    Object.keys(this.modifiedRole.permissions).forEach((permissionId) => {
      const currentUserRole: Permission = this.selectedRole.permissions.find(
        (p) => p.id === +permissionId,
      );

      if (this.modifiedRole.permissions[permissionId]) {
        if (!currentUserRole) {
          added.push(this.permissions.find((p) => p.id === +permissionId));
        }
      } else {
        if (currentUserRole) {
          removed.push(this.permissions.find((p) => p.id === +permissionId));
        }
      }
    });

    if (removed.length > 0 || added.length > 0) {
      this.permissionsChangelist = {
        id: this.selectedRole.id ? this.selectedRole.id : -1,
        name: this.form.get("name").value,
        description: this.form.get("description").value,
        addedPermissions: added,
        removedPermissions: removed,
      };

      this.showRoleConfirmDialog = true;
      this.modifiedRole.permissions = null;
    } else {
      await this.roleManager.updateRole({
        id: this.selectedRole.id,
        name: this.form.get("name").value,
        description: this.form.get("description").value,
      });
      await this.initialize();
      this.notificationService.notifySuccess(
        "Rolle aktualisiert",
        "Aktualisierung erfolgreich",
      );
    }
  }

  public async deleteRole(role: Role) {
    const response = await this.confirmService.confirmError(
      "",
      "Rolle löschen",
    );
    if (response) {
      try {
        await this.roleManager.deleteRole(role.id);
        this.roleManager.markForChange();
        this.notificationService.notifySuccess(
          "Rolle: " + role.name + " wurde erfolgreich gelöscht",
          "Rolle löschen",
        );
      } catch (e) {
        if (e.status === 403) {
          this.notificationService.notifyError(
            "Rolle konnte nicht gelöscht werden",
            "Rolle löschen",
          );
        } else if (e.status === 404) {
          this.notificationService.notifyError(
            "Rolle konnte nicht gelöscht werden",
            "Rolle löschen",
          );
        }
      } finally {
        await this.loadRoleData();
      }
    }
  }

  public async showAssignedPermissions(role: Role) {
    this.selectedRole = role;
    this.statusDetailDataTable = 2;
    this.isEditRolesTable = false;

    try {
      const userPermissions: Array<Permission> =
        await this.roleManager.getPermissionsByRoleId(this.selectedRole.id);
      this.groupedPermissions =
        this.groupPermissionsByCategory(userPermissions);
      this.createRolesTableConfig(this.selectedRole);
    } catch (e) {
      this.notificationService.notifyError(
        "Da ist etwas schiefgelaufen",
        " Permissions konnten nicht abgerufen werden",
      );
    }
  }

  public async showEditPermissionsTable(role: Role, isNewRole: boolean) {
    this.modifiedRole.permissions = [];
    this.selectedRole = role;
    this.isEditRolesTable = true;
    this.isNewRole = isNewRole;
    this.statusDetailDataTable = 3;

    if (!isNewRole) {
      await this.setRolesForUser(role);
    }

    this.form.patchValue(
      {
        name: role.name,
        description: role.description,
      },
      { emitEvent: false },
    );

    this.groupedPermissions = this.groupPermissionsByCategory(this.permissions);
    this.createRolesTableConfig(role);
    this.createPermissionTableConfig();
  }

  private async setRolesForUser(role: Role) {
    this.permissionsPerRole = await this.roleManager.getPermissionsByRoleId(
      role.id,
    );
    this.permissions = await this.roleManager.getPermissions();

    this.permissionsPerRole.forEach((assignedPermissions: Permission) => {
      const p = this.permissions.find(
        (el: Permission) => el.id === assignedPermissions.id,
      );

      if (p) {
        p.selected = true;
      }
    });
  }

  public async showAssignedUsers(role: Role) {
    this.assignedUsers = [];
    this.selectedRole = role;
    this.statusDetailDataTable = 1;

    try {
      this.assignedUsers = await this.roleManager.getUsersByRoleId(
        this.selectedRole.id,
      );
      await this.createAssignedUsersTableConfig();
    } catch (e) {
      // TODO notification
    }
  }

  public async deletePermission(permission: Permission) {
    const response = await this.confirmService.confirmError(
      "",
      "Recht löschen",
    );
    if (response) {
      try {
        await this.roleManager.deleteRolePermission(
          this.selectedRole.id,
          permission.id,
        );
        this.notificationService.notifySuccess(
          "Das Recht wurde erfolgreich gelöscht",
          "Recht löschen",
        );
      } catch (e) {
        if (e.status === 404) {
          this.notificationService.notifyError(
            "Recht konnte nicht gelöscht werden",
            "Recht löschen",
          );
        } else if (e.status === 403) {
          this.notificationService.notifyError(
            "Die Rolle muss mindestens ein Recht haben.",
            "Recht löschen",
          );
        }
      } finally {
        await this.showAssignedPermissions(this.selectedRole);
      }
    }
  }

  public groupPermissionsByCategory(permissions: any) {
    permissions.forEach((permission: Permission) => {
      if (!this.tableGroups.includes(permission.groupName)) {
        this.tableGroups.push(permission.groupName);
      }
    });

    return this.tableGroups.map((groupName: string) => {
      return permissions.filter(
        (permission: Permission) => permission.groupName === groupName,
      );
    });
  }

  public get cardHeight(): number {
    return window.innerHeight - 200;
  }

  public setStatusHeader(): string {
    if (this.statusDetailDataTable === 1) {
      return "Zugewiesene Benutzer (" + this.selectedRole.name + ")";
    } else if (this.statusDetailDataTable === 2) {
      return "Zugewiesene Rechte (" + this.selectedRole.name + ")";
    } else if (this.statusDetailDataTable === 3 && !this.isNewRole) {
      return "Rolle bearbeiten (" + this.selectedRole.name + ")";
    } else if (this.statusDetailDataTable === 3) {
      return "Neue Rolle anlegen";
    } else {
      return "Keine Rolle selektiert";
    }
  }

  public getAccordionTabHeader(
    index: number,
    permissionTable: DataTableConfig<Permission>,
  ) {
    return this.tableGroups[index] + " (" + permissionTable.data.total + ")";
  }

  async onPermissionsChanged() {
    await this.initialize();
    await this.showAssignedPermissions(this.roles[this.roles.length - 1]);
  }

  ngOnDestroy(): void {
    this.roleSubscription.unsubscribe();
  }

  public async openRoleConfirmOverlay() {
    await this.overlayService.show<RoleConfirmDialogComponent>({
      size: OverlaySize.medium,
      type: RoleConfirmDialogComponent,
      header: "Benutzerrechte ändern: " + this.permissionsChangelist.name,
      init: (component) => {
        component.toggled = this.showRoleConfirmDialog;
        component.changeList = this.permissionsChangelist;
        component.isCreate = this.isNewRole;
      },
      actions: [
        {
          label: "Abbrechen",
          action: (t) => t.dismissDialog(),
          displayAsLink: true,
        },
        {
          label: "Änderungen speichern",
          action: (t) => t.saveNewRolePermissions(),
        },
      ],
    });
  }

  public get writeRole(): boolean {
    return this.authManager.hasPermission("writeRole");
  }

  public get executeRole(): boolean {
    return this.authManager.hasPermission("executeRole");
  }
}
