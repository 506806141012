import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { Manufacturer } from "./manufacturer.model";

@DataConversion()
export class AccessLevelModel {
  @Required()
  public id: number;
  @Optional()
  public name: string;
  @Required()
  public viewGroup: string;
  @Optional()
  @ArrayType(() =>
    import("./manufacturer.model").then((mod) => mod.Manufacturer),
  )
  public manufacturers: Manufacturer[];
}
