import { DataConversion, Optional } from "@incert/data-conversion";
import { TouristAttractionUserModel } from "./tourist-attraction-user.model";
import { TouristAttractionModel } from "./tourist-attraction.model";
import { Challenge } from "./tourist-attraction-challenge.model";

/**
 * In compliant with Schema.org
 * Thing > Place > TouristDestination
 */

@DataConversion()
export class TouristAttractionChallengeUserModel {
  @Optional()
  id: string;
  @Optional()
  touristAttraction: TouristAttractionModel;
  @Optional()
  challenge: Challenge;
  @Optional()
  user: TouristAttractionUserModel;
}

@DataConversion()
export class TouristAttractionChallenge {
  challenge: Challenge;
}
