const textAlignMap = {
  right: "ql-align-right",
  center: "ql-align-center",
  justify: "ql-align-justify",
};

export function transformQuillInput(src: string): string;
export function transformQuillInput(src: HTMLElement): void;
export function transformQuillInput(src: unknown): unknown {
  let el: HTMLElement;
  if (typeof src === "string") {
    el = document.createElement("DIV");
    el.innerHTML = src;
  } else {
    el = <HTMLElement>src;
  }

  for (const child of <HTMLElement[]>(
    Array.from(el.querySelectorAll("[style]"))
  )) {
    if (textAlignMap[child.style.textAlign]) {
      child.classList.add(textAlignMap[child.style.textAlign]);
    }
  }

  if (typeof src === "string") {
    return el.innerHTML;
  }
}

export function transformQuillOutput(src: string): string;
export function transformQuillOutput(src: HTMLElement): void;
export function transformQuillOutput(src: unknown): unknown {
  let el: HTMLElement;
  if (typeof src === "string") {
    el = document.createElement("DIV");
    el.innerHTML = src;
  } else {
    el = <HTMLElement>src;
  }

  for (const align in textAlignMap) {
    const alignClass = textAlignMap[align];
    for (const elem of <HTMLElement[]>(
      Array.from(el.getElementsByClassName(alignClass))
    )) {
      elem.classList.remove(alignClass);
      if (elem.classList.length === 0) {
        elem.removeAttribute("class");
      }
      elem.style.textAlign = align;
    }
  }

  if (typeof src === "string") {
    return el.innerHTML;
  }
}
