import { DataConversion, Required } from "@incert/data-conversion";
import { Optional } from "@angular/core";

@DataConversion()
export class TmaEventModel {
  @Required()
  id: number;

  @Optional()
  triggerEvent: string;

  @Optional()
  emailTemplateId: number;

  @Optional()
  emailTemplateName: string;

  @Optional()
  categoryIds: number[];

  @Optional()
  companyLevelIds: number[];

  @Optional()
  points: number;

  @Optional()
  clubStatus: string[];

  @Optional()
  jubileeIds: number[];

  @Optional()
  customerStatusIds: number[];

  @Optional()
  couponId: number;

  @Optional()
  campaignId: number;

  @Optional()
  sendAfterTime: number;

  @Optional()
  active: number;

  @Optional()
  testMode: boolean;

  @Optional()
  blockedEventSequences: number[];

  @Optional()
  sendToParticipants: boolean;
}

@DataConversion()
export class EmailTemplateModel {
  @Required()
  id: number;

  @Required()
  name: string;
}

@DataConversion()
export class EmailTemplateDescriptionModel {
  @Required()
  id: number;

  @Optional()
  languageId: number;

  @Optional()
  editorConfig: string;

  @Optional()
  subject: string;

  @Optional()
  body: string;
}
