import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { StatusListEntryModel } from "../models";
import { ApiHttpAccess } from "./http/api-http-access.service";

@Injectable({ providedIn: "root" })
export class ShippingStatusService {
  private _shippingStatusEntries$ = new ReplaySubject<StatusListEntryModel[]>(
    1,
  );
  private _shippingStatusListEntriesInit = false;

  public constructor(private httpAccess: ApiHttpAccess) {}

  public refreshShippingStatusListEntries() {
    this.httpAccess
      .get<StatusListEntryModel[]>("orders/orderStatus", StatusListEntryModel)
      .then((v) =>
        this._shippingStatusEntries$.next(
          v.filter((status) => status.typ === 7),
        ),
      );
  }

  public get shippingStatusEntries$() {
    if (!this._shippingStatusListEntriesInit) {
      this.refreshShippingStatusListEntries();
      this._shippingStatusListEntriesInit = true;
    }
    return this._shippingStatusEntries$.asObservable();
  }
}
