export enum OrderEmailType {
  CUSTOMER_ORDER = 1,
  ADMIN_ORDER = 2,
  CUSTOMER_DELIVERY_NOTE = 3,
  ADMIN_DELIVERY_NOTE = 4,
  LOGISTIC_PARTNER_DELIVERY_NOTE = 5,
  LOGISTIC_PARTNER_VOUCHER = 6,
  ARCHIVE_VOUCHER = 7,
  CUSTOMER_VOUCHER = 8,
  FORWARD_VOUCHER = 9,
  ADMIN_ORDER_CANCEL = 10,
  CUSTOMER_ORDER_CANCEL = 11,
  ORDER_CHANGED = 12,
  ORDER_CHANGED_INTERNAL = 13,
  OVERDUE = 14,
  REBUILD_VOUCHER = 15,
  REDEEMED_VOUCHER = 16,
}
