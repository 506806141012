import { ReplaySubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiHttpAccess, ProductOptionSelectionType } from "@incert/gms-core";

@Injectable({ providedIn: "root" })
export class ProductOptionService {
  private _productOptionEntries$ = new ReplaySubject<
    ProductOptionSelectionType[]
  >(1);
  private _productOptionEntriesInit = false;

  public constructor(private httpAccess: ApiHttpAccess) {}

  public refreshProductOptionEntries() {
    this.httpAccess
      .get<
        ProductOptionSelectionType[]
      >("productsOptions/group/list", ProductOptionSelectionType)
      .then((v) => {
        const productOptions = [];
        v.forEach((entry) => productOptions.push(entry.productOption));
        this._productOptionEntries$.next(productOptions);
      });
  }

  public get productOptionEntries$() {
    if (!this._productOptionEntriesInit) {
      this.refreshProductOptionEntries();
      this._productOptionEntriesInit = true;
    }
    return this._productOptionEntries$.asObservable();
  }
}
