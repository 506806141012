import { ReplaySubject } from "rxjs";
import { ApiHttpAccess } from "@incert/gms-core";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class SetFunctionService {
  public constructor(private httpAccess: ApiHttpAccess) {}

  setFunctionToSubject$: { [setFn: string]: ReplaySubject<any[]> } = {};
  setFunctionToBoolean: { [setFn: string]: boolean } = {};

  public refreshValues(setFunction: string) {
    this.httpAccess
      .get("configuration/value/" + setFunction)
      .then((v) => this.setFunctionToSubject$[setFunction].next(v));
  }

  public getValues$(setFunction: string) {
    if (this.setFunctionToBoolean[setFunction] == null) {
      this.setFunctionToSubject$[setFunction] = new ReplaySubject<any[]>(1);
      this.refreshValues(setFunction);
      this.setFunctionToBoolean[setFunction] = true;
    }
    return this.setFunctionToSubject$[setFunction].asObservable();
  }
}
