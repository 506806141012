import {
  DataConversion,
  DateConversion,
  Required,
} from "@incert/data-conversion";

@DataConversion()
export class RouteHistoryModel {
  @Required()
  id: number;

  @Required()
  languageCode: string;

  @Required()
  oldSlug: string;

  @Required()
  newSlug: string;

  @Required()
  @DateConversion()
  expirationDate: Date;

  @Required()
  subShopId: number;

  @Required()
  autoGenerated: boolean;
}
