import { Component, OnInit } from "@angular/core";
import { ContactGroup } from "../contact-group.model";
import {
  ArrayDataSource,
  createFilterDefinition,
  DataTableConfig,
  TextFilterComponent,
} from "@incert/incert-gui";
import { ContactGroupComponent } from "../contact-group.component";
import { ContactEmail } from "../../contact-email/contact-email.model";
import { ContactGroupManagerService } from "../contact-group-manager.service";

@Component({
  selector: "incert-internal-tools-contact-group-contacts",
  templateUrl: "./contact-group-contacts.component.html",
})
export class ContactGroupContactsComponent implements OnInit {
  contactGroup: ContactGroup;
  contactEmails: ContactEmail[];
  arrayDataSource: ArrayDataSource<ContactEmail>;
  dtConfig: DataTableConfig<ContactEmail>;
  contactGroupComp: ContactGroupComponent;

  constructor(private contactGroupManager: ContactGroupManagerService) {}

  async ngOnInit() {
    await this.createDtConfig();
  }

  private async createDtConfig() {
    this.contactEmails = await this.contactGroupManager.getContactGroupEmails(
      this.contactGroup?.id,
    );
    this.arrayDataSource = new ArrayDataSource(this.contactEmails ?? []);
    this.dtConfig = {
      mode: "pagination",
      rows: 15,
      data: this.arrayDataSource,
      emptyMessage: "Keine Kontakte zugeteilt",
      export: true,
      filterArea: {
        filters: [
          {
            header: "Kürzel",
            property: (v) => v.get("accountSystem", "short"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Email",
            property: (v) => v.get("email"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Rolle",
            property: (v) => v.get("contactRole", "title"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
        ],
      },
      columns: [
        {
          header: "Kürzel",
          property: (v) => v.get("accountSystem", "short"),
          sort: true,
        },
        {
          header: "Email",
          property: (v) => v.get("email"),
          sort: true,
        },
        {
          header: "Rolle",
          property: (v) => v.get("contactRole", "title"),
          sort: true,
        },
      ],
    };
  }
}
