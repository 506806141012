<div *ngIf="formGroup">
  <div [formGroup]="formGroup">
    <div class="grid">
      <form-field label="Systemgruppe" class="col-12">
        <p-dropdown
          formControlName="accountSystemGroup"
          [options]="accountSystemGroups"
          optionValue="id"
          optionLabel="name"
          placeholder="Systemgruppe"
          appendTo="body"
          [showClear]="true">
        </p-dropdown>
      </form-field>
      <div class="col-6">
        <form-field label="Kategorie">
          <p-dropdown
            [options]="notificationCategoriesSI"
            placeholder="Kategorie"
            formControlName="category"
            appendTo="body">
          </p-dropdown>
        </form-field>
      </div>
      <div class="col-6">
        <form-field label="Verhalten">
          <p-dropdown
            [options]="notificationBehaviors"
            placeholder="Verhalten"
            formControlName="behavior"
            appendTo="body">
          </p-dropdown>
        </form-field>
      </div>
      <div class="col-6">
        <form-field label="Recht">
          <p-multiSelect
            [options]="notificationPermissions"
            formControlName="permissions"
            appendTo="body"
            filter="true"
            optionLabel="name"
            placeholder="Recht">
          </p-multiSelect>
        </form-field>
      </div>
      <div class="col-6">
        <form-field label="URI/Weiterleitung">
          <input name="uri" pInputText type="text" formControlName="uri" placeholder="URI/Weiterleitung">
        </form-field>
      </div>
      <div class="col-6">
        <form-field label="Gültig von">
          <p-calendar
            formControlName="startDate"
            showIcon="true"
            showTime="true"
            hourFormat="24"
            placeholder="Gültig von"
            dateFormat="dd/mm/yy">
          </p-calendar>
        </form-field>
      </div>
      <div class="col-6">
        <form-field label="Gültig bis">
          <p-calendar
            formControlName="endDate"
            showIcon="true"
            showTime="true"
            hourFormat="24"
            placeholder="Gültig bis"
            dateFormat="dd/mm/yy"></p-calendar>
        </form-field>
      </div>
    </div>


    <h1>Inhalt</h1>
    <div *ngFor="let translation of translations?.controls; let i = index" formArrayName="translations">
      <div class="box-entry separator-bottom" [formGroupName]="i">
        <div class="box-entry-content">
          <div class="box-entry-left">
            <form-field label="Sprache">
              <p-dropdown
                [formControlName]="nameofFormTranslation('language')"
                [options]="languageSI"
                placeholder="Sprache"
                appendTo="body">
              </p-dropdown>
            </form-field>
            <form-field label="Titel">
              <input pInputText type="text" [formControlName]="nameofFormTranslation('title')" placeholder="Titel">
            </form-field>
            <form-field label="Aktion">
              <input pInputText type="text" [formControlName]="nameofFormTranslation('cta')"
                     placeholder="Aktion">
            </form-field>
            <form-field label="Beschreibung">
              <incert-quill-editor
                [formControlName]="nameofFormTranslation('text')">
              </incert-quill-editor>
            </form-field>
          </div>
          <div class="box-entry-right">
            <icon-button iconOnly='true' iconType="close" (click)="deleteTranslation(i)"
                         displayIconButtonAsButton="true">
            </icon-button>
          </div>
        </div>
      </div>
    </div>
    <div class="align-right">
      <icon-button iconType="plus" (click)="addTranslation()" label="Übersetzung"></icon-button>
    </div>
  </div>
</div>
