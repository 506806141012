import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class SocialMediaType {
  @Required()
  id: number;

  @Required()
  title: string;
}
