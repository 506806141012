import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class OrderTotal {
  @Optional()
  class: string;
  @Optional()
  value: number;
  @Optional()
  title: string;
  @Optional()
  text: string;
}
