import { Injector } from "@angular/core";
import { I18nService } from "@incert/i18n";
import { IncertGUII18n } from "@incert/incert-gui";
import { ENVIRONMENT } from "./services";
import { PrimeNGConfig } from "primeng/api";

export function gmsIncertGuiI18nFactory(injector: Injector): IncertGUII18n {
  const translate = injector.get(I18nService);
  const environment = injector.get(ENVIRONMENT);
  const primengConfig = injector.get(PrimeNGConfig);
  const i18n = {
    languages: environment.languages,
    currentLanguage: environment.currentLanguage,
    currency: environment.defaultCurrency,
    currencyByCode: environment.currencies.reduce<
      IncertGUII18n["currencyByCode"]
    >(
      (p, c) => {
        p[c.code] = c;
        return p;
      },
      <any>{},
    ),
    locale: environment.currentLanguage
      ? environment.currentLanguage.code
      : "de",
    abort: translate.instant("core.action.abort"),
    ok: translate.instant("core.action.ok"),
    clear: translate.instant("core.action.clear"),
    dateFormat: translate.instant("core.dateTime.dateFormat"),
    timeFormat: translate.instant("core.dateTime.timeFormat"),
    timeFormatIncludeSeconds: translate.instant(
      "core.dateTime.timeFormatIncludeSeconds",
    ),

    monthNames: {
      january: translate.instant("core.dateTime.monthNames.january"),
      february: translate.instant("core.dateTime.monthNames.february"),
      march: translate.instant("core.dateTime.monthNames.march"),
      april: translate.instant("core.dateTime.monthNames.april"),
      may: translate.instant("core.dateTime.monthNames.may"),
      june: translate.instant("core.dateTime.monthNames.june"),
      july: translate.instant("core.dateTime.monthNames.july"),
      august: translate.instant("core.dateTime.monthNames.august"),
      september: translate.instant("core.dateTime.monthNames.september"),
      october: translate.instant("core.dateTime.monthNames.october"),
      november: translate.instant("core.dateTime.monthNames.november"),
      december: translate.instant("core.dateTime.monthNames.december"),
    },

    monthNamesShort: {
      january: translate.instant("core.dateTime.monthNamesShort.january"),
      february: translate.instant("core.dateTime.monthNamesShort.february"),
      march: translate.instant("core.dateTime.monthNamesShort.march"),
      april: translate.instant("core.dateTime.monthNamesShort.april"),
      may: translate.instant("core.dateTime.monthNamesShort.may"),
      june: translate.instant("core.dateTime.monthNamesShort.june"),
      july: translate.instant("core.dateTime.monthNamesShort.july"),
      august: translate.instant("core.dateTime.monthNamesShort.august"),
      september: translate.instant("core.dateTime.monthNamesShort.september"),
      october: translate.instant("core.dateTime.monthNamesShort.october"),
      november: translate.instant("core.dateTime.monthNamesShort.november"),
      december: translate.instant("core.dateTime.monthNamesShort.december"),
    },

    dayNames: {
      monday: translate.instant("core.dateTime.dayNames.monday"),
      tuesday: translate.instant("core.dateTime.dayNames.tuesday"),
      wednesday: translate.instant("core.dateTime.dayNames.wednesday"),
      thursday: translate.instant("core.dateTime.dayNames.thursday"),
      friday: translate.instant("core.dateTime.dayNames.friday"),
      saturday: translate.instant("core.dateTime.dayNames.saturday"),
      sunday: translate.instant("core.dateTime.dayNames.sunday"),
    },

    dayNamesShort: {
      monday: translate.instant("core.dateTime.dayNamesShort.monday"),
      tuesday: translate.instant("core.dateTime.dayNamesShort.tuesday"),
      wednesday: translate.instant("core.dateTime.dayNamesShort.wednesday"),
      thursday: translate.instant("core.dateTime.dayNamesShort.thursday"),
      friday: translate.instant("core.dateTime.dayNamesShort.friday"),
      saturday: translate.instant("core.dateTime.dayNamesShort.saturday"),
      sunday: translate.instant("core.dateTime.dayNamesShort.sunday"),
    },

    dayNamesMin: {
      monday: translate.instant("core.dateTime.dayNamesMin.monday"),
      tuesday: translate.instant("core.dateTime.dayNamesMin.tuesday"),
      wednesday: translate.instant("core.dateTime.dayNamesMin.wednesday"),
      thursday: translate.instant("core.dateTime.dayNamesMin.thursday"),
      friday: translate.instant("core.dateTime.dayNamesMin.friday"),
      saturday: translate.instant("core.dateTime.dayNamesMin.saturday"),
      sunday: translate.instant("core.dateTime.dayNamesMin.sunday"),
    },

    multiSelect: {
      selectedItemsLabel: translate.instant(
        "core.multiSelect.selectedItemsLabel",
      ),
      defaultLabel: translate.instant("core.multiSelect.defaultLabel"),
    },

    day: translate.instant("core.dateTime.day"),
    allDay: translate.instant("core.dateTime.allDay"),
    month: translate.instant("core.dateTime.month"),
    week: translate.instant("core.dateTime.week"),
    today: translate.instant("core.dateTime.today"),
    list: translate.instant("core.list"),
    more: translate.instant("core.more"),
    noEvents: translate.instant("core.noEvents"),
    success: translate.instant("core.status.success"),
    error: translate.instant("core.status.error"),
    info: translate.instant("core.status.info"),
    warning: translate.instant("core.status.warning"),
    confirmDefault: translate.instant("core.dateTime.confirmDefault"),
    barcodeScanner: {
      header: "Scan Codes",
      error:
        "Camera cannot be accessed. Please allow the usage or close other applications that use the camera.",
    },
  };
  setPrimeNgLocale(i18n, primengConfig);
  return i18n;
}

function setPrimeNgLocale(
  incertGUII18n: IncertGUII18n,
  primeNGConfig: PrimeNGConfig,
) {
  primeNGConfig.setTranslation({
    clear: incertGUII18n.clear,
    today: incertGUII18n.today,
    firstDayOfWeek: 1,
    dateFormat: iso8601ToCalendar(incertGUII18n.dateFormat),
    dayNames: [
      incertGUII18n.dayNames.sunday,
      incertGUII18n.dayNames.monday,
      incertGUII18n.dayNames.tuesday,
      incertGUII18n.dayNames.wednesday,
      incertGUII18n.dayNames.thursday,
      incertGUII18n.dayNames.friday,
      incertGUII18n.dayNames.saturday,
      incertGUII18n.dayNames.sunday,
    ],
    dayNamesShort: [
      incertGUII18n.dayNamesShort.sunday,
      incertGUII18n.dayNamesShort.monday,
      incertGUII18n.dayNamesShort.tuesday,
      incertGUII18n.dayNamesShort.wednesday,
      incertGUII18n.dayNamesShort.thursday,
      incertGUII18n.dayNamesShort.friday,
      incertGUII18n.dayNamesShort.saturday,
      incertGUII18n.dayNamesShort.sunday,
    ],
    dayNamesMin: [
      incertGUII18n.dayNamesMin.sunday,
      incertGUII18n.dayNamesMin.monday,
      incertGUII18n.dayNamesMin.tuesday,
      incertGUII18n.dayNamesMin.wednesday,
      incertGUII18n.dayNamesMin.thursday,
      incertGUII18n.dayNamesMin.friday,
      incertGUII18n.dayNamesMin.saturday,
      incertGUII18n.dayNamesMin.sunday,
    ],
    monthNames: [
      incertGUII18n.monthNames.january,
      incertGUII18n.monthNames.february,
      incertGUII18n.monthNames.march,
      incertGUII18n.monthNames.april,
      incertGUII18n.monthNames.may,
      incertGUII18n.monthNames.june,
      incertGUII18n.monthNames.july,
      incertGUII18n.monthNames.august,
      incertGUII18n.monthNames.september,
      incertGUII18n.monthNames.october,
      incertGUII18n.monthNames.november,
      incertGUII18n.monthNames.december,
    ],
    monthNamesShort: [
      incertGUII18n.monthNamesShort.january,
      incertGUII18n.monthNamesShort.february,
      incertGUII18n.monthNamesShort.march,
      incertGUII18n.monthNamesShort.april,
      incertGUII18n.monthNamesShort.may,
      incertGUII18n.monthNamesShort.june,
      incertGUII18n.monthNamesShort.july,
      incertGUII18n.monthNamesShort.august,
      incertGUII18n.monthNamesShort.september,
      incertGUII18n.monthNamesShort.october,
      incertGUII18n.monthNamesShort.november,
      incertGUII18n.monthNamesShort.december,
    ],
  });
}

function iso8601ToCalendar(format: string) {
  return format.toLowerCase().replace(/yy/g, "y");
}
