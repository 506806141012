import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { ShoppingCartRuleModel } from "./shopping-cart-rule.model";

@DataConversion()
export class ShoppingCartRuleCallbackModel {
  @Required()
  public id: number;

  @Optional()
  public rule: ShoppingCartRuleModel;

  @Required()
  public event: number;

  @Required()
  public callback: number;

  @Required()
  public target: number;
}
