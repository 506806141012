import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { ShoppingCartRuleAssignmentModel } from "./shopping-cart-rule-assignment.model";
import { ShoppingCartRuleTargetDescriptor } from "../enums/shopping-cart-rule-target-descriptor.enum";

@DataConversion()
export class ShoppingCartRuleAssignmentTargetModel {
  @Optional()
  public assignment: ShoppingCartRuleAssignmentModel;

  @Required()
  public targetId: number;

  @Required()
  public targetDescriptor: ShoppingCartRuleTargetDescriptor;

  @Required()
  public targetRecursive: number;
}
