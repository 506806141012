import { DataConversion, Optional } from "@incert/data-conversion";
import { FrontendEngineDistanceUnits } from "../enums/frontend-engine-distance-unit.enum";

@DataConversion()
export class FrontendSchemaConfig {
  @Optional()
  $id = "";
  @Optional()
  $schema = "";
  @Optional()
  title = "";
  @Optional()
  defaultUnit: FrontendEngineDistanceUnits;
  @Optional()
  customCss = "";
  @Optional()
  properties: any | null = null;
  @Optional()
  units: { [key: string]: boolean };
}
