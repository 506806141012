import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { MenuResolver } from "./menu-resolver.service";
import { MenuState } from "./menu-state";

@Injectable({ providedIn: "root" })
export class MenuLoadGuard implements CanActivate {
  public constructor(
    private menuResolver: MenuResolver,
    private menuState: MenuState,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.menuResolver.resolve(this.menuState);
    return true;
  }
}
