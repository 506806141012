import { MenuResponseModel } from "./menu-response.model";
import { MenuStateEntry } from "./menu-state-entry";
import { FavoriteMenuStateEntry } from "./favorite-menu-state-entry";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { NameResolver, TagsResolver } from "./menu-state-handle";
import { NotificationService } from "@incert/incert-core";
import { I18nService } from "@incert/i18n";

@Injectable({
  providedIn: "root",
})
export class MenuState extends MenuStateEntry {
  private favoritesEntry: MenuStateEntry;
  public favoriteLimit = 10;
  public favoriteCount = 10;

  private _favoritesChange$ = new Subject<MenuStateEntry[]>();
  public get favoritesChange$() {
    return this._favoritesChange$.asObservable();
  }

  private _entrySelect = new Subject<{
    entry: MenuStateEntry;
    additionalData: any;
  }>();

  public get menuEntrySelect$() {
    return this._entrySelect.asObservable();
  }

  public constructor(
    private notificationService: NotificationService,
    private i18n: I18nService,
  ) {
    super("", "", [], null);

    // override path => start at firstLevel
    this.path = [];

    // init menuState handle
    this.menuStateHandle = {
      addFavorite: (v) => this.addFavorite(v),
      removeFavorite: (v) => this.removeFavorite(v),
      selectMenuEntry: (v, a) =>
        this._entrySelect.next({ entry: v, additionalData: a }),
      deactivateAll: () => (this.active = false),
    };
  }

  public set nameResolver(value: NameResolver) {
    this.menuStateHandle.nameResolver = value;
  }

  public set tagsResolver(value: TagsResolver) {
    this.menuStateHandle.tagsResolver = value;
  }

  public build(menuResponse: MenuResponseModel, favoriteKeys: string[] = null) {
    let rootFavoritesEntry: MenuStateEntry = null;
    if (favoriteKeys) {
      rootFavoritesEntry = new MenuStateEntry(
        "favorites",
        "favorites",
        this.path,
        this.menuStateHandle,
      );
      // init favorite entry
      this.favoritesEntry = new MenuStateEntry(
        "favorites",
        "favorites",
        this.path,
        this.menuStateHandle,
      );
      rootFavoritesEntry.entries = [this.favoritesEntry];
      this.favoritesEntry.entries = [];
    }

    super._build(menuResponse);
    // handle favorite keys

    if (rootFavoritesEntry) {
      this.entries.unshift(rootFavoritesEntry);
      for (const key of favoriteKeys) {
        const menuStateEntries = this.find((entry) => entry.key === key);
        if (menuStateEntries.length) {
          this.addFavorite(menuStateEntries[0]);
        }
      }
    }
  }

  private addFavorite(menuStateEntry: MenuStateEntry) {
    if (this.favoritesEntry.entries.length < 10) {
      this.favoriteCount++;
      if (!menuStateEntry.markedAsFavorite) {
        const favMenuStateEntry = new FavoriteMenuStateEntry(
          menuStateEntry.key,
          menuStateEntry.name,
          this.favoritesEntry.path,
          this.menuStateHandle,
        );
        favMenuStateEntry.route = menuStateEntry.route;
        favMenuStateEntry.sourceEntry = menuStateEntry;
        favMenuStateEntry.markedAsFavorite = true;
        favMenuStateEntry.searchable = false;
        menuStateEntry.markedAsFavorite = true;
        this.favoritesEntry.entries.push(favMenuStateEntry);
        this._favoritesChange$.next(this.favoritesEntry.entries);
      }
    } else {
      this.notificationService.notifyError(
        this.i18n.instant("gms.layout.favoriteLimitReached", {
          limit: this.favoriteLimit,
        }),
      );
    }
  }

  private removeFavorite(menuStateEntry: MenuStateEntry) {
    // handle favorite entry
    if (menuStateEntry instanceof FavoriteMenuStateEntry) {
      menuStateEntry = menuStateEntry.sourceEntry;
    }

    const index = this.favoritesEntry.entries.findIndex(
      (v: FavoriteMenuStateEntry) => v.sourceEntry === menuStateEntry,
    );
    if (index !== -1) {
      menuStateEntry.markedAsFavorite = false;
      this.favoritesEntry.entries.splice(index, 1);
      this._favoritesChange$.next(this.favoritesEntry.entries);
    }
  }
}
