import { DashboardPersistorInterface } from "./dashboard-persistor.interface";
import { WidgetModel } from "../widget.model";
import { Injectable } from "@angular/core";

@Injectable()
export class DashboardLocalStoragePersistor
  implements DashboardPersistorInterface
{
  delayed = false;

  async load(id: string) {
    if (localStorage.getItem(this.getLocalStorageKey(id)) != null) {
      return (<WidgetModel[]>(
        JSON.parse(localStorage.getItem(this.getLocalStorageKey(id)))
      )) as WidgetModel[];
    }
    return [];
  }

  async save(id: string, widgets: WidgetModel[]) {
    localStorage.setItem(this.getLocalStorageKey(id), JSON.stringify(widgets));
  }

  private getLocalStorageKey(id: string) {
    return "dashboard_" + id;
  }
}
