import { MenuEntry } from "./menu-entry.model";
import { ArrayType, DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class ModuleGroupMenuEntry {
  isOpen = false;

  @Required()
  title: string;

  @Required()
  @ArrayType(() => import("./menu-entry.model").then((mod) => mod.MenuEntry))
  menuEntries: MenuEntry[];
}
