import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class StatusListEntryModel {
  @Required()
  id: number;

  @Required()
  name: string;

  @Required()
  allowSetPaymentDate: boolean;

  @Required()
  typ: number;

  @Required()
  const: string;
}
