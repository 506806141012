import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvironmentService } from "../environment.service";

@Injectable()
export class BonAuthHttpInterceptor implements HttpInterceptor {
  public constructor(private environmentService: EnvironmentService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      this.environmentService.environment.bonUrlV1 &&
      req.url
        .toString()
        .indexOf(this.environmentService.environment.bonUrlV1) !== -1
    ) {
      req = req.clone({
        withCredentials: false,
        headers: req.headers
          .append("X-API-KEY", this.environmentService.environment.bonApiKeyV1)
          .append(
            "X-SOURCE-SYSTEM",
            this.environmentService.environment.partId,
          ),
      });
    }
    if (
      this.environmentService.environment.bonUrlV2 &&
      req.url
        .toString()
        .indexOf(this.environmentService.environment.bonUrlV2) !== -1
    ) {
      req = req.clone({
        withCredentials: false,
        headers: req.headers.append(
          "X-API-KEY",
          this.environmentService.environment.bonApiKeyV2,
        ),
      });
    }
    return next.handle(req);
  }
}
