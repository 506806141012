import { Injectable } from "@angular/core";
import { OpeningTimeEntry } from "../models/opening-time-entry.model";
import { ApiHttpAccess } from "../services";

@Injectable()
export class OpeningTimeEntryDAO {
  public constructor(private httpAccess: ApiHttpAccess) {}

  public async fetch(openingTimeId: number): Promise<OpeningTimeEntry[]> {
    return await this.httpAccess.get<OpeningTimeEntry[]>(
      "openingTimeEntry?openingTimeId=" + openingTimeId,
      OpeningTimeEntry,
    );
  }

  public async insert(
    openingTimeId: number,
    openingTimeEntries: Partial<OpeningTimeEntry>[],
  ) {
    await this.httpAccess.post(
      "openingTimeEntry/" + openingTimeId,
      openingTimeEntries,
    );
  }
}
