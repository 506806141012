export enum TouristAttractionDiscr {
  EVENT = "event",
  GASTRO = "gastronomy",
  ACCOM = "accommodation",
  ACCOM_GASTRO = "accommodationgastro",
  ACTIVITY = "sight",
  REG_PROD = "regionalproduct",
  HEALTH = "health",
  TOUR = "tour",
}

export enum PropertyTypeParentIdentifier {
  ACCOMMODATION_TYPE = "accommodation",
  GASTRO_TYPE = "gastro",
  CUISINE_TYPE = "cuisine",
  ACTIVITY_TYPE = "sight",
  REGIONAL_PRODUCT_TYPE = "regionalproduct",
  EVENT_TYPE = "event",
  HEALTH_TYPE = "health",
  TOUR_TYPE = "tour",
}

export interface HeaApiResponse {
  title: string;
  errorCode: number;
  detail: string;
}

export interface PropertyTypeParent {
  id: string;
  name: string;
  identifier: string;
  code?: string;
  translations: PropertyTypeTranslation[];
}

export interface PropertyTypeTranslation {
  id: string;
  name: string;
  code: string;
}

export interface PropertyTypeChildren {
  id: string;
  description: string;
  parent: PropertyTypeParent;
  translations: PropertyTypeTranslation[];
  name: string;
}

export interface CreatePropertyTypeChildren {
  id: string;
  parent: number;
  children: PropertyTypeChildren[];
}
