import { DataConversion, Optional } from "@incert/data-conversion";
import {
  PropertyTypeParent,
  PropertyTypeTranslation,
  TouristAttractionDiscr,
} from "./tourist-attraction.common.model";
import { Reward } from "@incert/bon/bon-core/src/lib/model/reward.model";

/**
 * In compliant with Schema.org
 * Thing > Place > TouristDestination
 */

@DataConversion()
export class TouristAttractionTranslation {
  @Optional()
  id: string;
  @Optional()
  code: string;
  @Optional()
  name: string;
  @Optional()
  alternateName: string;
  @Optional()
  descriptionLong: string;
  @Optional()
  description: string;
  @Optional()
  noteByOwner: string;
  @Optional()
  tourTrailConditions: string;
  @Optional()
  eventInformation: string;
}

@DataConversion()
export class TouristDestination {
  @Optional()
  id: string;
  @Optional()
  name: string;
}

@DataConversion()
export class TouristAttractionTypeTranslation {
  @Optional()
  name: string;
  @Optional()
  code: string;
}

@DataConversion()
export class TouristAttractionType {
  @Optional()
  id: string;
  @Optional()
  discr: TouristAttractionDiscr;
  @Optional()
  translation: TouristAttractionTypeTranslation;
}

@DataConversion()
export class MarkerType {
  @Optional()
  id: string;
  @Optional()
  label: string;
  @Optional()
  icon: string;
  @Optional()
  type: string;
}

@DataConversion()
export class TouristAttractionImage {
  @Optional()
  imageName: string;
  @Optional()
  path: string;
}

@DataConversion()
export class PostalAddressTranslation {
  @Optional()
  code: string;
  @Optional()
  addressCountry: string;
  @Optional()
  addressLocality: string;
  @Optional()
  addressRegion: string;
}

@DataConversion()
export class PostalAddress {
  @Optional()
  addressStreet: string;
  @Optional()
  postalCode: number;
  @Optional()
  addressLocality: string;
}

@DataConversion()
export class TouristAttractionModel {
  @Optional()
  id: string;
  @Optional()
  latitude: number;
  @Optional()
  longitude: number;
  @Optional()
  discr: string;
  @Optional()
  contactPartner: string;
  @Optional()
  isInChallenge: boolean;
  @Optional()
  qrcodeAddress: string;
  @Optional()
  rewardAmount: number;
  @Optional()
  reward: {
    id: string;
    rewardAmount: string;
    headline: string;
    description: string;
    shortDescription: string;
    clubLevel: number;
    disallowValueOverwrite: boolean;
  };
  @Optional()
  email: string;
  @Optional()
  phoneNumber: string;
  @Optional()
  webAddress: string;
  @Optional()
  link: string;
  @Optional()
  distanceToPublicTransport: string;
  @Optional()
  isInHouseParkingAvailable: boolean;
  @Optional()
  isBarrierFree: boolean;
  @Optional()
  isPublicParkingAvailable: boolean;
  @Optional()
  deleted: number;
  @Optional()
  isActive: boolean;
  @Optional()
  openingTime: any;
  @Optional()
  divergentOpeningTime: any[];
  @Optional()
  tourDifficulty: string;
  @Optional()
  tourEstimation: number;
  @Optional()
  tourDistance: number;
  @Optional()
  tourPace: number;
  @Optional()
  tourAltitudeUp: number;
  @Optional()
  tourAltitudeDown: number;
  @Optional()
  assets: MediaLibraryItem[];
  @Optional()
  qualitySeals: MediaLibraryItem[];
  @Optional()
  markerType: MarkerType;
  @Optional()
  categories: TouristAttractionTag[];
  @Optional()
  addressStreet: string;
  @Optional()
  postalCode: string;
  @Optional()
  addressLocality: string;
  @Optional()
  type: TouristAttractionType;
  @Optional()
  destination: TouristDestination;
  @Optional()
  translations: TouristAttractionTranslation[];
  @Optional()
  linkedAttractions: TouristAttractionModel[];
  @Optional()
  typeOfProperties: PropertyType[];
  @Optional()
  tags: TouristAttractionTag[];

  /**
   * primitive values
   */
  @Optional()
  checkInTime: Date;
  @Optional()
  checkOutTime: Date;
  @Optional()
  breakfastTimeFrom: Date;
  @Optional()
  breakfastTimeTo: Date;
  @Optional()
  seatsInside: string;
  @Optional()
  seatsOutside: string;
  @Optional()
  linkToWebshop: string;
  @Optional()
  isAnimalsAllowed: boolean;
}

@DataConversion()
export class PropertyType {
  @Optional()
  id: string;
  @Optional()
  identifier: string;
  @Optional()
  translations: Array<PropertyTypeTranslation>;
  @Optional()
  parent: PropertyTypeParent;
}

@DataConversion()
export class TouristAttractionTag {
  @Optional()
  id: string;
  @Optional()
  name: string;
  @Optional()
  translations: Array<{ id: string; code: string; name: string }>;
}

@DataConversion()
export class TouristAttractionSubType {}

export interface OpeningTimeDay {
  from: string;
  to: string;
  closed: boolean;
}

@DataConversion()
export class TouristAttractionOpeningTime {
  monday: OpeningTimeDay;
  tuesday: OpeningTimeDay;
  wednesday: OpeningTimeDay;
  thursday: OpeningTimeDay;
  friday: OpeningTimeDay;
  saturday: OpeningTimeDay;
  sunday: OpeningTimeDay;
}

export interface MediaLibraryItem {
  mediaLibraryId: number;
  position: number;
  type: "video" | "image" | null | "mediaLibraryImage";
  link: string;
}
