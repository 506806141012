import { Injectable } from "@angular/core";
import { Gender } from "../models";
import { ApiHttpAccess } from "./http/api-http-access.service";

@Injectable({
  providedIn: "root",
})
export class GenderAdministrationService {
  constructor(private apiHttpAccess: ApiHttpAccess) {}

  async getGender(id: number): Promise<Gender | Gender[]> {
    return await this.apiHttpAccess.get<Gender[]>("gender/" + id, Gender);
  }

  async getGenderOverview(): Promise<Gender[]> {
    return await this.apiHttpAccess.get<Gender[]>("genderOverview", Gender);
  }

  async updateGender(gender: Gender): Promise<void> {
    await this.apiHttpAccess.post("gender", gender);
  }

  async updateGenderSort(sortedGenderArray: Gender[]): Promise<void> {
    await this.apiHttpAccess.put("gender/sorts", sortedGenderArray);
  }
}
