import { DataConversion, Required, Optional } from "@incert/data-conversion";

@DataConversion()
export class ProductImages {
  @Required()
  imageId: number;

  @Required()
  imageNr: number;

  @Required()
  languageCode: number;

  @Required()
  imageName: string;

  @Optional()
  sortOrder: number;

  @Optional()
  displayOrder: number;
}
