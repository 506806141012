import { Injectable, NgModule } from "@angular/core";
import { I18nLoaderInterface, I18nModule } from "@incert/i18n";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { IncertCoreModule, MockHttpAccess } from "@incert/incert-core";
import {
  IncertGUIModule,
  OverlayOutletModule,
  ToastOutletModule,
} from "@incert/incert-gui";
import { GMSCoreModule } from "../gms-core.module";
import { GmsTestWrapper } from "./gms-test-wrapper.component";
import { ApiHttpAccess } from "../services";

@Injectable({ providedIn: "root" })
export class NullI18nLoader implements I18nLoaderInterface {
  async load(path: string, language: string): Promise<any> {
    return {};
  }
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    I18nModule.forRoot({ loader: NullI18nLoader }),
    IncertCoreModule.forRoot(),
    GMSCoreModule.forRoot(),
    IncertGUIModule.forRoot(),
    OverlayOutletModule,
    ToastOutletModule,
  ],
  declarations: [GmsTestWrapper],
  exports: [GmsTestWrapper],
  providers: [
    NullI18nLoader,
    {
      provide: ApiHttpAccess,
      useValue: new MockHttpAccess(),
    },
  ],
})
export class GmsTestingModule {}
