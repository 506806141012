import { DataConversion, Required, Optional } from "@incert/data-conversion";

@DataConversion()
export class DrawWinnersRequestModel {
  @Required()
  numWinners: number;

  @Optional()
  manufacturerId: number | string | null = null;

  @Optional()
  dryRun = false;

  @Required()
  seed: number;

  @Required()
  status: number[];
}
