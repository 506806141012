import { DataConversion, Optional, Required } from "@incert/data-conversion";

export interface ExportIndexSignature {
  [model: string]: string[];
}

@DataConversion()
export class ExportColumnSelectionModel {
  @Required()
  public id: number;
  @Optional()
  public name: string;
  @Optional()
  public selection: ExportIndexSignature;
  @Optional()
  public additionalSettings: any;
}
