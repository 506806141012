import { DataConversion, Required, Optional } from "@incert/data-conversion";
import { EventReservationProductParticipant } from "./event-reservation-product-participant.model";

@DataConversion()
export class EventReservationParticipantHistory {
  @Required()
  id: number;

  @Optional()
  participant: EventReservationProductParticipant;

  @Required()
  state: string;

  @Optional()
  comment: string;

  @Required()
  dateChanged: Date;
}
