import { Observable } from "rxjs";
import { WidgetConfigurationValueResolver } from "./widget-configuration/widget-configuration.service";

export type ChangeStateCallback = (state: any) => void;

export class WidgetHandle {
  public isConfigurable = false;
  public title: string = null;
  public subtitle: string = null;
  public localFilterActive = false;
  public tooltip: string = null;

  public get state() {
    return this._state ? this._state : {};
  }

  public set state(value: any) {
    this._state = value;
    this._changeStateCallback(this._state);
  }

  filterChange$ = new Observable();

  public constructor(
    private _state: any,
    public resize$: Observable<void>,
    public showConfiguration$: Observable<void>,
    private _changeStateCallback: ChangeStateCallback,
    public configurationChange$: Observable<WidgetConfigurationValueResolver>,
  ) {}
}
