import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class ReceptionStaff {
  @Optional()
  receptionStaffId: number;

  @Optional()
  active: boolean;

  @Optional()
  alias: string;

  @Optional()
  dateChanged: Date;

  @Optional()
  firstName: string;

  @Optional()
  lastLogon: Date;

  @Optional()
  lastName: string;
}
