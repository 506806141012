import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AccessGroupModel } from "../../../../gms-core/src/lib/models/access-group.model";
import { AccessLevelModel } from "../../../../gms-core/src/lib/models/access-level.model";
import { I18nService } from "@incert/i18n";
import { AccessGroupToAccessLevelMap } from "./interfaces/access-manager.interfaces";

@Component({
  selector: "incert-access-manager",
  templateUrl: "./access-manager.component.html"
})
export class AccessManagerComponent implements OnInit {
  @Input()
  public accessGroups: AccessGroupModel[];
  @Input()
  public accessLevels: AccessLevelModel[];
  @Input()
  public accessMap: AccessGroupToAccessLevelMap;

  @Input()
  public selectedAccessGroups: AccessGroupModel[];
  @Input()
  public selectedAccessLevels: AccessLevelModel[];

  @Output()
  public addAccessGroup: EventEmitter<AccessGroupModel> =
    new EventEmitter<AccessGroupModel>();
  @Output()
  public removeAccessGroup: EventEmitter<AccessGroupModel> =
    new EventEmitter<AccessGroupModel>();
  @Output()
  public addAccessLevel: EventEmitter<AccessLevelModel> =
    new EventEmitter<AccessLevelModel>();
  @Output()
  public removeAccessLevel: EventEmitter<AccessLevelModel> =
    new EventEmitter<AccessLevelModel>();

  constructor(public i18n: I18nService) {}

  ngOnInit(): void {}

  public hasAccessGroup(accessGroup: AccessGroupModel): boolean {
    for (let i = 0; i < this.selectedAccessGroups.length; i++) {
      if (this.selectedAccessGroups[i].id == accessGroup.id) {
        return true;
      }
    }
    return false;
  }

  public updateAccessGroup(accessGroup: AccessGroupModel) {
    if (this.hasAccessGroup(accessGroup)) {
      this.removeAccessGroup.emit(accessGroup);
    } else {
      this.addAccessGroup.emit(accessGroup);
    }
  }

  public hasAccessLevel(accessLevelModel: AccessLevelModel): boolean {
    for (let i = 0; i < this.selectedAccessLevels.length; i++) {
      if (this.selectedAccessLevels[i].id == accessLevelModel.id) {
        return true;
      }
    }
    return false;
  }

  public updateAccessLevel(accessLevel: AccessLevelModel) {
    if (this.hasAccessLevel(accessLevel)) {
      this.removeAccessLevel.emit(accessLevel);
    } else {
      this.addAccessLevel.emit(accessLevel);
    }
  }

  public getAccessGroupActiveChildren(accessGroup: AccessGroupModel) {
    //Return number of active accessGroups
    let number = 0;
    if (accessGroup.children) {
      for (let i = 0; i < accessGroup.children.length; i++) {
        if (this.hasAccessGroup(accessGroup.children[i])) {
          number++;
        }
      }
    }
    return number;
  }
}
