import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QuillEditorComponent } from "./quill-editor.component";
import { I18nModule } from "@incert/i18n";
import { SourceViewOverlayComponent } from "./source-view-overlay/source-view-overlay.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CodeEditorModule } from "../code-editor/code-editor.module";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { IconModule } from "../..";

@NgModule({
  declarations: [QuillEditorComponent, SourceViewOverlayComponent],
  exports: [QuillEditorComponent, SourceViewOverlayComponent],
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    CodeEditorModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    IconModule,
  ],
})
export class QuillEditorModule {}
