import { Component, ComponentRef, Type, ViewContainerRef } from "@angular/core";
import { OverlayService } from "@incert/incert-core";

@Component({
  selector: "test-outlet",
  template: `
    <overlay-outlet></overlay-outlet>
    <toast-outlet></toast-outlet>
  `,
  providers: [OverlayService],
})
export class GmsTestWrapper<T> {
  private _componentType: Type<T>;
  private _initFN: (c: T) => void;
  private _ref: ComponentRef<T>;

  public get instance() {
    return this._ref.instance;
  }
  public constructor(private vcr: ViewContainerRef) {}
  init(componentType: Type<T>, initFN?: (c: T) => void) {
    this._componentType = componentType;
    this._initFN = initFN;
    this.render();
  }
  private render() {
    if (this._componentType) {
      if (this._ref) {
        this._ref.hostView.destroy();
      }
      this._ref = this.vcr.createComponent(this._componentType);
      if (this._initFN) {
        this._initFN(this._ref.instance);
      }
    }
  }
}
