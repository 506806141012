import { firstValueFrom, ReplaySubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiHttpAccess, GMSUrlResolver } from "@incert/gms-core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class GoogleFontService {
  private _googleFontEntries$ = new ReplaySubject<[]>(1);
  private _googleFontEntriesInit = false;

  public constructor(
    private httpAccess: ApiHttpAccess,
    private gmsUrlResolver: GMSUrlResolver,
    private httpClient: HttpClient,
  ) {}

  public refreshGoogleFontEntries() {
    this.httpAccess
      .get("fonts/googleFont")
      .then((v) => this._googleFontEntries$.next(v?.items ?? []));
    this._googleFontEntriesInit = true;
  }

  public get googleFontEntries$() {
    if (!this._googleFontEntriesInit) {
      this.refreshGoogleFontEntries();
    }
    return this._googleFontEntries$.asObservable();
  }

  public async getFontFace(font: string) {
    const url = this.gmsUrlResolver.getGoogleFontServiceUrl(font);
    if (url) {
      return await firstValueFrom(
        this.httpClient.get(url, {
          responseType: "text",
        }),
      );
    }
    return "";
  }
}
