<div #dashboardContainer>
    <div id="dashboard-top">
        <h1>
            Dashboard
        </h1>
        <div class="dashboard-filters">
            <ng-container *ngFor="let filter of filters">
                <ng-container *ngTemplateOutlet="filter"></ng-container>
            </ng-container>
        </div>
        <div class="dashboard-actions">
            <icon-button (click)="displayAddWidget=true"
                         iconType="plus"
                         [label]="'dashboard.widgets.addWidgetButton' | i18n"
                         displayIconButtonAsButton="true">
            </icon-button>
            <icon-button (click)="export()"
                         iconType="print"
                         [incTooltip]="'dashboard.widgets.printDashboard' | i18n">
            </icon-button>
            <icon-button (click)="setGlobalFilter()"
                         iconType="globalfilter"
                         [incTooltip]="'dashboard.widgets.setGlobalFilter' | i18n">
            </icon-button>
        </div>
    </div>

    <div id="dashboard-container" >
        <gridster [options]="config">
            <gridster-item style="page-break-inside: avoid" [item]="widget.position" *ngFor="let widget of widgets;let i = index">
                    <widget-host (widgetRemove)="removeWidget(widget)"
                                 [attr.id]="'widget_' + i"
                                 (widgetStateChanged)="dashboardChanged()"
                                 [widget]="widget">
                    </widget-host>
            </gridster-item>
        </gridster>
    </div>

    <p-sidebar position="right"
               [style]="{width:'80%'}"
               [(visible)]="displayAddWidget">
        <dashboard-add-widget *ngIf="displayAddWidget"
                              [dashboardConfig]="dashboardConfig"
                              (addWidget)="addWidget($event)">
        </dashboard-add-widget>
    </p-sidebar>

    <ng-template #container></ng-template>
</div>
