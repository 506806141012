import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Country } from "./country.model";

@DataConversion()
export class AddressBookModel {
  @Required()
  id: number;

  @Required()
  gender: string;

  @Required()
  title: string;

  @Required()
  company: string;

  @Optional()
  department: string;

  @Required()
  firstName: string;

  @Required()
  lastName: string;

  @Required()
  streetAddress: string;

  @Required()
  postcode: string;

  @Required()
  countryId: number;

  @Required()
  city: string;

  @Optional()
  country: Country;
}
