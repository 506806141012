import { MockHttpAccessRequest } from "@incert/incert-core";

export const getEnvironment: MockHttpAccessRequest = {
  route: "environment",
  method: "GET",
  resp: {
    storeName: "Demo Professional Gutscheinsystem (003)",
    languages: [
      {
        imageUrl:
          "https://003.dev-myincert.com/lang/german/admin/images/icon.gif",
        languageId: "2",
        id: 2,
        name: "Deutsch",
        code: "de",
        image: "icon.gif",
        directory: "german",
        sortOrder: 1,
        languageCharset: "utf-8",
        active: 1,
        adminActive: 1,
      },
      {
        imageUrl:
          "https://003.dev-myincert.com/lang/english/admin/images/icon.gif",
        languageId: "8",
        id: 8,
        name: "Englisch",
        code: "en",
        image: "icon.gif",
        directory: "english",
        sortOrder: 2,
        languageCharset: "utf-8",
        active: 1,
        adminActive: 1,
      },
      {
        imageUrl:
          "https://003.dev-myincert.com/lang/croatian/admin/images/icon.gif",
        languageId: "11",
        id: 11,
        name: "Kroatisch",
        code: "hr",
        image: "icon.gif",
        directory: "croatian",
        sortOrder: 5,
        languageCharset: "utf-8",
        active: 1,
        adminActive: 0,
      },
      {
        imageUrl:
          "https://003.dev-myincert.com/lang/polish/admin/images/icon.gif",
        languageId: "12",
        id: 12,
        name: "Polnisch",
        code: "pl",
        image: "icon.gif",
        directory: "polish",
        sortOrder: 6,
        languageCharset: "utf-8",
        active: 1,
        adminActive: 0,
      },
      {
        imageUrl:
          "https://003.dev-myincert.com/lang/french/admin/images/icon.gif",
        languageId: "15",
        id: 15,
        name: "Franz\u00f6sisch",
        code: "fr",
        image: "icon.gif",
        directory: "french",
        sortOrder: 4,
        languageCharset: "utf-8",
        active: 1,
        adminActive: 0,
      },
      {
        imageUrl:
          "https://003.dev-myincert.com/lang/slovenian/admin/images/icon.gif",
        languageId: "19",
        id: 19,
        name: "Slowenisch",
        code: "si",
        image: "icon.gif",
        directory: "slovenian",
        sortOrder: 12,
        languageCharset: "utf-8",
        active: 1,
        adminActive: 0,
      },
    ],
    currentLanguage: {
      imageUrl:
        "https://003.dev-myincert.com/lang/english/admin/images/icon.gif",
      languageId: "8",
      id: 8,
      name: "Englisch",
      code: "en",
      image: "icon.gif",
      directory: "english",
      sortOrder: 2,
      languageCharset: "utf-8",
      active: 1,
      adminActive: 1,
    },
    partId: "devc1_003",
    adminLogin: true,
    defaultCurrency: "EUR",
    incertAdminEnabled: "true",
    i18nServiceUrl: "https://resources.myincert.com/i18n/",
    isSuperAdmin: true,
    passwordResetAdminAllowedReceiverType: "any",
    status: {
      id: 15,
      name: "PROJZEND-2471",
    },
    sessionId: "avp1vbb95oesmu459m1ij9p2pi",
    customer: {
      id: 1,
      firstName: "SUPPORT",
      lastName: "INCERT",
    },
    permissions: [
      "depositM",
      "deposit_transactions",
      "manual_transactions",
      "edit_partner_customer_status",
      "configuration",
      "postings_table",
      "modules",
      "countries",
      "currencies",
      "zones",
      "geo_zones",
      "tax_classes",
      "tax_rates",
      "accounting",
      "server_info",
      "languages",
      "orders_status",
      "shipping_status",
      "customers",
      "customers_list",
      "customer_info_box",
      "create_account",
      "customers_status",
      "orders",
      "orders_new",
      "customer_cards",
      "activate_customer_cards",
      "oeticket_cards",
      "card_range_activation",
      "card_range_redemption",
      "undo_card_activate",
      "generate_card_codes",
      "card_assign_man",
      "campaigns",
      "popup_memo",
      "coupon_admin",
      "gv_sent",
      "gv_birthday",
      "validproducts",
      "validcategories",
      "categories",
      "new_attributes",
      "products_attributes",
      "manufacturers",
      "specials",
      "stats_products_viewed",
      "stats_products_purchased",
      "stats_customers",
      "stats_sales_report",
      "partner_accounting",
      "partner_accounting_mail",
      "stats_campaigns",
      "start",
      "content_manager",
      "content_preview",
      "orders_edit",
      "orders_edit_address",
      "orders_edit_products",
      "orders_edit_others",
      "orders_edit_options",
      "allow_orders_table_edit",
      "popup_image",
      "fck_wrapper",
      "voucherRedeem",
      "update_redeem_entry",
      "voucher_preview",
      "reception_order_new",
      "reception_order_staff",
      "reception_order_config",
      "rebuild_order",
      "win_vouchers",
      "win_campaigns",
      "bon_campaigns",
      "csv_coup_export",
      "packaging",
      "csv_products_export",
      "win_campaigns_stats",
      "win_check_duplicate_custs",
      "win_campaigns_config",
      "win_email_master_editor",
      "download_csv",
      "edit_product",
      "rebuild_order_cust",
      "rebuild_voucher_cust",
      "redeem_stations",
      "import_redeem_stations",
      "show_orders",
      "show_orders_details",
      "cancel_v",
      "cancel_o",
      "cancel_c",
      "f_redeem_v",
      "p_redeem_v",
      "vi_redeem_v",
      "un_redeem_v",
      "card_activate_redeem_v",
      "redeem_history",
      "change_redeem_station",
      "lookup_orders_for_redemption",
      "new_redeem_station",
      "csv_win_cust_export",
      "orders_journal",
      "orders_journal_stat",
      "orders_journal_products_att",
      "orders_journal_redeem",
      "orders_journal_invalidated",
      "orders_journal_tax",
      "orders_journal_cards",
      "join_to_one_pdf",
      "facebook",
      "marketing_list",
      "marketing_vouchers",
      "ticket_vouchers",
      "imported_vouchers",
      "quick_redemption",
      "voucher_inclusives",
      "bonus_pointM",
      "bonus_point_start",
      "bonus_point_report",
      "bonus_point_report_redirect",
      "bonus_point_list",
      "bonus_point_list_redirect",
      "bonus_point_page",
      "bonus_point_page_redirect",
      "bonus_point_design_form",
      "general_config",
      "image_admin",
      "lang_text_config",
      "imported_vouchers_allow_edit",
      "accounting_list",
      "reception_show_debitor_box",
      "booking_configuration",
      "switch_user",
      "theme_admin",
      "event_create",
      "event_overview",
      "event_customer",
      "event_config",
      "ticket_orders",
      "products_meta_data",
      "allow_graduated_prices",
      "email_text",
      "email_text_file_handling_functions",
      "slot_reservation",
      "slot_reservation_admin",
      "event_waiting_list",
      "bonus_point_journal",
      "abo_customers",
      "abo_deliveries",
      "voucher_bulk",
      "expired_voucher_bulk",
      "expired_ticket_voucher_bulk",
      "rksv",
      "abo_config",
      "opening_time",
      "privacy_policy",
      "event_product_assignment",
      "route_history",
      "sub_shop_settings",
      "abo_delivery_dates",
      "use_img_for_all_lang",
      "orders_journal_attributes",
      "manage_templates",
      "edit_man_bank_data",
      "consent_manager_settings",
      "interface_settings",
      "product_type_settings",
      "delete_partner_accounting_history",
      "system_notifications",
      "pick_up_journal",
      "take_away_slot_generation",
      "take_away_event_create",
      "take_away_event_overview",
      "module_group_take_away",
      "voucher_exchange",
      "manage_access",
      "payment_requests",
      "change_order_payment",
      "do_overbooking",
      "sepa_xml_import",
      "faq",
      "show_all_orders",
      "show_all_vouchers",
      "social_media_settings",
      "media_library",
      "shopping_cart_rules",
      "ticket_transfer",
      "extensions",
      "lock_voucher",
      "unlock_voucher",
      "locked_vouchers",
      "event_reservation",
      "bon_poi_manage",
      "card_import",
    ],
    manufacturerId: 1,
    isRootOrAdmin: true,
    userSwitch: false,
    userSwitchUsername: "",
    userSwitchResetURL: "",
    forcePasswordReset: false,
    forcePasswordResetOneTime: false,
    isRemoteIncertOffice: true,
  },
};
