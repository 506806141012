import { Injectable, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataTableComponent } from "./data-table.component";
import { ColumnHostComponent } from "./column-host/column-host.component";
import { TableModule } from "primeng/table";
import { LayoutModule } from "@angular/cdk/layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MultiSelectI18nModule } from "../i18n/multiselect-i18n/multi-select-i18n.module";
import { DataTableInnerComponent } from "./data-table-inner/data-table-inner.component";
import { SplitButtonModule } from "primeng/splitbutton";
import { MultiSelectModule } from "primeng/multiselect";
import { SpinnerModule } from "primeng/spinner";
import { AccordionModule } from "primeng/accordion";
import { AggregatePipe } from "./data-table-aggregation/aggregate-pipe.pipe";
import { OrderedMultiSelectModule } from "../ordered-multi-select/ordered-multi-select.module";
import { StaticFilterHostComponent } from "./static-filter-host/static-filter-host.component";
import { PanelModule } from "primeng/panel";
import { DragDropModule } from "primeng/dragdrop";
import { IconModule } from "../icon/icon.module";
import { I18nModule } from "@incert/i18n";
import { IconButtonModule } from "../icon-button/icon-button.module";
import { MenuModule } from "primeng/menu";
import { SubDataTableComponent } from "./sub-data-table/sub-data-table.component";
import { FormExtensionModule } from "../form-extension";
import { NoDataInfoModule } from "../no-data-info/no-data-info.module";
import { RadioButtonModule } from "primeng/radiobutton";
import { InputTextModule } from "primeng/inputtext";
import { InputSwitchModule } from "primeng/inputswitch";
import { DataTableColumnOverlayComponent } from "./data-table-column-overlay/data-table-column-overlay.component";
import { DataTableExportOverlayComponent } from "./data-table-export-overlay/data-table-export-overlay.component";
import { DataTableFilterAreaComponent } from "./data-table-filter/data-table-filter-area.component";
import { DataTableFilterOverlayComponent } from "./data-table-filter/data-table-filter-overlay/data-table-filter-overlay.component";
import { ComponentOutletModule } from "../component-outlet/component-outlet.module";
import { DataTableFilterProfileOverlayComponent } from "./data-table-filter/data-table-filter-profile-overlay/data-table-filter-profile-overlay.component";
import { DataTableInsertFilterProfileOverlayComponent } from "./data-table-filter/data-table-filter-profile-overlay/data-table-insert-filter-profile-overlay/data-table-insert-filter-profile-overlay.component";
import { DataTableFilterProfileExpansionComponent } from "./data-table-filter/data-table-filter-profile-overlay/data-table-filter-profile-expansion/data-table-filter-profile-expansion.component";
import { TooltipModule } from "../tooltip/tooltip.module";
import { LoadingWrapperModule } from "../loading-wrapper/loading-wrapper.module";

// Fix Import of Router for primeng
@Injectable()
export class OptionalRouterDep {
  constructor() {}
}

@NgModule({
  declarations: [
    DataTableComponent,
    ColumnHostComponent,
    DataTableInnerComponent,
    AggregatePipe,
    StaticFilterHostComponent,
    SubDataTableComponent,
    DataTableColumnOverlayComponent,
    DataTableExportOverlayComponent,
    DataTableFilterAreaComponent,
    DataTableFilterOverlayComponent,
    DataTableFilterProfileOverlayComponent,
    DataTableInsertFilterProfileOverlayComponent,
    DataTableFilterProfileExpansionComponent,
  ],
  exports: [
    DataTableComponent,
    ColumnHostComponent,
    DataTableInnerComponent,
    DataTableExportOverlayComponent,
    DataTableColumnOverlayComponent,
    DataTableFilterAreaComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    SplitButtonModule,
    AccordionModule,
    LayoutModule,
    SpinnerModule,
    MultiSelectModule,
    ReactiveFormsModule,
    MultiSelectI18nModule,
    OrderedMultiSelectModule,
    PanelModule,
    DragDropModule,
    IconModule,
    I18nModule,
    FormExtensionModule,
    IconButtonModule,
    MenuModule,
    NoDataInfoModule,
    RadioButtonModule,
    InputTextModule,
    InputSwitchModule,
    FormsModule,
    ComponentOutletModule,
    TooltipModule,
    LoadingWrapperModule,
  ],
  providers: [],
})
export class DataTableModule {}
