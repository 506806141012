import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DateFilterComponent } from "./date-filter.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CalendarModule } from "primeng/calendar";
import { FormExtensionModule } from "../../form-extension";
import { StopClickPropagationDirective } from "./stop-click-propagation.directive";
import { InputTextModule } from "primeng/inputtext";
import { TooltipModule } from "../../tooltip/tooltip.module";

@NgModule({
  declarations: [DateFilterComponent, StopClickPropagationDirective],
  imports: [
    CommonModule,
    FormsModule,
    FormExtensionModule,
    ReactiveFormsModule,
    CalendarModule,
    InputTextModule,
    TooltipModule,
  ],
})
export class DateFilterModule {}
