import { DataConversion, Optional } from "@incert/data-conversion";

@DataConversion()
export class ProductConfiguration {
  @Optional()
  productId: number = null;

  @Optional()
  productTaxRate: number = null;

  @Optional()
  taxRate = 0;

  @Optional()
  productName = "";

  @Optional()
  accountId = "";

  @Optional()
  dcOrder = "";

  @Optional()
  dcRedemption = "";

  @Optional()
  dcExpire = "";
}
