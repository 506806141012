export function getDirty(form: any) {
  const dirtyValues = {};

  Object.keys(form.controls).forEach((key) => {
    const currentControl = form.controls[key];

    if (currentControl.dirty) {
      if (currentControl.controls)
        dirtyValues[key] = this.getDirtyValues(currentControl);
      else dirtyValues[key] = currentControl.value;
    }
  });

  return dirtyValues;
}
