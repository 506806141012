import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Type,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  CategoryToWidget,
  DashboardConfig,
} from "../shared/dashboard-config.model";
import {
  DashboardConfigService,
  WidgetCategoryTree,
} from "../shared/dashboard-config.service";
import { Message } from "primeng/api";
import { NotificationService } from "@incert/incert-core";
import { debounceTime } from "rxjs/operators";
import { I18nService } from "@incert/i18n";

@Component({
  selector: "dashboard-add-widget",
  templateUrl: "./dashboard-add-widget.component.html",
})
export class DashboardAddWidgetComponent implements OnInit {
  public categoryTreeNode: WidgetCategoryTree;
  public filteredTreeNode: WidgetCategoryTree;
  private mainCategoryIndex: number = null;

  @Input()
  public dashboardConfig: DashboardConfig;

  @Output()
  public addWidget = new EventEmitter<string>();

  public form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public dashboardConfigService: DashboardConfigService,
    private notificationService: NotificationService,
    private i18n: I18nService,
  ) {
    this.form = this.fb.group({
      filter: [""],
    });
    this.setMainCategoryNode(0);

    this.form
      .get("filter")
      .valueChanges.pipe(debounceTime(500))
      .subscribe((v: string) => {
        this.filter(v);
      });
  }

  private filter(v: string) {
    this.filteredTreeNode = JSON.parse(JSON.stringify(this.categoryTreeNode));
    const filterCategoryTree = (node: WidgetCategoryTree) => {
      if (node.hasWidgets) {
        const newChildren: WidgetCategoryTree[] = [];
        for (const widget of node.children) {
          if (
            widget.title.toLocaleLowerCase().indexOf(v.toLocaleLowerCase()) >= 0
          ) {
            newChildren.push(widget);
          }
        }
        node.children = newChildren;
      } else {
        const newChildren: WidgetCategoryTree[] = [];
        for (const cat of node.children) {
          filterCategoryTree(cat);
          if (cat.children.length) {
            newChildren.push(cat);
          }
        }
        node.children = newChildren;
      }
    };
    filterCategoryTree(this.filteredTreeNode);
  }

  async ngOnInit() {
    if (!this.dashboardConfig) {
      throw Error("dashboardConfig not set");
    }
  }

  public widgetAddedSuccess(name: any) {
    this.notificationService.notifySuccess(
      this.i18n.instant("dashboard.widgets.widgetAdded", { name }),
    );
  }

  setMainCategoryNode(index: number) {
    this.mainCategoryIndex = index;
    this.categoryTreeNode =
      this.dashboardConfigService.widgetCategoryTree[index];
    this.filter(this.form.value.filter);
  }

  isArray(subMenu: CategoryToWidget | Type<any>[]) {
    return Array.isArray(subMenu);
  }
}
