import { inject, InjectionToken } from "@angular/core";
import { ApiHttpAccess } from "./http/api-http-access.service";
import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
export type LoadGmsConst = Promise<GmsConst>;
let loadPromise = null;
const mapping: GmsConst = {
  productTypeById: {},
  productTypeByConst: {},
  orderTypeById: {},
  paymentModuleByConst: {},
  shippingModuleByConst: {},
  orderStatusByConst: {},
  orderStatusById: {},
  orderStatusColorById: {},
  orderStatusColorByConst: {},
};

export const LOAD_GMS_CONST = new InjectionToken<LoadGmsConst>(
  "LOAD_GMS_CONST",
  {
    providedIn: "root",
    factory: async () => {
      const httpAccess = inject(ApiHttpAccess);
      if (!loadPromise) {
        loadPromise = new Promise<void>(async (resolve) => {
          const gmsConstMapping = await httpAccess.get(
            "environment/gmsConst",
            MappingResponse,
          );
          for (const entry of gmsConstMapping.productTypes) {
            mapping.productTypeById[entry.id] = entry.name;
            mapping.productTypeByConst[entry.const] = entry.name;
          }
          for (const entry of gmsConstMapping.orderTypes) {
            mapping.orderTypeById[entry.id] = entry.name;
          }
          for (const entry of gmsConstMapping.paymentModules) {
            mapping.paymentModuleByConst[entry.const] = entry.name;
          }
          for (const entry of gmsConstMapping.shippingModules) {
            mapping.shippingModuleByConst[entry.const] = entry.name;
            mapping.shippingModuleByConst[entry.const + "_" + entry.const] =
              entry.name;
          }
          for (const entry of gmsConstMapping.orderStatus) {
            mapping.orderStatusById[entry.id] = entry.name;
            mapping.orderStatusByConst[entry.const] = entry.name;
            mapping.orderStatusColorByConst[entry.const] = "#" + entry.color;
            mapping.orderStatusColorById[entry.id] = "#" + entry.color;
          }
          resolve();
        });
      }
      await loadPromise;
      return mapping;
    },
  },
);

export interface GmsConst {
  productTypeById: { [id: string]: string };
  productTypeByConst: { [id: string]: string };
  orderTypeById: { [id: string]: string };
  paymentModuleByConst: { [id: string]: string };
  shippingModuleByConst: { [id: string]: string };
  orderStatusByConst: { [id: string]: string };
  orderStatusById: { [id: string]: string };
  orderStatusColorById: { [id: string]: string };
  orderStatusColorByConst: { [id: string]: string };
}

@DataConversion()
class MappingResponseEntry {
  @Optional()
  id: string;
  @Optional()
  "const": string;
  @Optional()
  color: string;
  @Required()
  name: string;
}

@DataConversion()
class MappingResponse {
  @Required()
  @ArrayType(() => new Promise((resolve) => resolve(MappingResponseEntry)))
  productTypes: MappingResponseEntry[];

  @Required()
  @ArrayType(() => new Promise((resolve) => resolve(MappingResponseEntry)))
  orderTypes: MappingResponseEntry[];

  @Required()
  @ArrayType(() => new Promise((resolve) => resolve(MappingResponseEntry)))
  paymentModules: MappingResponseEntry[];

  @Required()
  @ArrayType(() => new Promise((resolve) => resolve(MappingResponseEntry)))
  shippingModules: MappingResponseEntry[];

  @Required()
  @ArrayType(() => new Promise((resolve) => resolve(MappingResponseEntry)))
  orderStatus: MappingResponseEntry[];
}
