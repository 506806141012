import { Optional, DataConversion } from "@incert/data-conversion";

@DataConversion()
export class ProductTypeModel {
  @Optional()
  id: number;
  @Optional()
  constant: string;
  @Optional()
  label: string;
  @Optional()
  name: string;
  @Optional()
  showInclusives: boolean;
  @Optional()
  showProductsStartEndDate: boolean;
  @Optional()
  downloadable: boolean;
  @Optional()
  isActive: boolean;
  @Optional()
  passbookType: string;
  @Optional()
  allowedDeliveryCountriesIsoCode2: string;
  @Optional()
  onlineRedeemable: boolean;
  @Optional()
  paperFormat: string;
  @Optional()
  forceJoinToOnePdf: string;
  @Optional()
  passbookEnabled: boolean;
  @Optional()
  addTextToOrderComment: boolean;
  @Optional()
  valueCheck: boolean;
  @Optional()
  allowInterfaces: boolean;
  @Optional()
  partialRedemption: boolean;
  @Optional()
  hasRecipient: boolean;
}

export type ToggleProductTypeItems = Pick<
  ProductTypeModel,
  | "showProductsStartEndDate"
  | "showInclusives"
  | "downloadable"
  | "isActive"
  | "passbookEnabled"
  | "onlineRedeemable"
  | "valueCheck"
  | "allowInterfaces"
  | "partialRedemption"
  | "hasRecipient"
  | "addTextToOrderComment"
>;
