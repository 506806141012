import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class SeatsIOCategory {
  @Required()
  contingent: number;

  @Required()
  key: string;

  @Required()
  label: string;
}
