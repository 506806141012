export enum OrderStatus {
  ORDER_CANCELED = 7,
  CANCELED = 8,
  REDEEMED = 9,
  NOT_REDEEMED = 10,
  CARD_NOT_ASSIGNED = 20,
  CARD_INACTIVE = 21,
  CARD_ACTIVE = 22,
  CARD_REDEEMED = 23,
  ORDER_LOCKED = 93,
}
