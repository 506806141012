import {
  ArrayType,
  DataConversion,
  Required,
  Optional,
} from "@incert/data-conversion";
import { Slot } from "./slot.model";
import { Customer } from "./customer.model";
import { Order } from "./order.model";

@DataConversion()
export class SlotReservation {
  @Required()
  id: number;

  @Required()
  slot: Slot;

  @Required()
  customer: Customer;

  @Required()
  reservationNr: string;

  @Required()
  quantity: number;

  @Optional()
  order: Order;

  @Required()
  isOnlineReservation: boolean;

  @Required()
  dateReserved: Date;

  @Required()
  active: boolean;

  @Optional()
  comment: string;
}
