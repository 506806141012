import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class ProductListEntry {
  @Required()
  id: number;

  @Required()
  name: string;

  @Required()
  useWithoutParent: boolean;

  @Required()
  type: number;
}
