import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Manufacturer } from "./manufacturer.model";
import { Currency } from "./currency.model";

@DataConversion()
export class ManufacturersCurrency {
  @Required()
  public manufacturer: Manufacturer;

  @Required()
  public currency: Currency;

  @Required()
  public context: number;

  @Optional()
  public allow: boolean;
}
