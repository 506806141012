import { DataConversion, Optional, Required } from "@incert/data-conversion";
import { Product } from "./product.model";
import { EventProductAssignment } from "./event-product-assignment.model";

@DataConversion()
export class EventProductAssignmentEntry {
  @Optional()
  product: Product;
  @Required()
  productQuota: number;
  @Required()
  productQuotaSold: number;

  @Optional()
  eventProductAssignment: EventProductAssignment;
}
