<form-field [label]="config.header" [floatingLabel]="floatingLabel" [clearSeparatorBottom]="floatingLabel">
  <input
    type="text" pInputText
    [formControl]="form.get('filter')"
    (keydown.enter)="filterExplicit()"
    (focusout)="filterExplicit()"
    [placeholder]="floatingLabel ? '' : config.header"
    [incTooltip]="config.tooltip"
    [tooltipPosition]="'bottom'"
    autocomplete="off"
  />
</form-field>
