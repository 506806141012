import { Inject, Injectable } from "@angular/core";
import { Environment } from "../models/environment.model";
import { ApiHttpAccess } from "./http/api-http-access.service";
import { ENVIRONMENT } from "./environment.token";

@Injectable()
export class EnvironmentService {
  public constructor(
    private httpAccess: ApiHttpAccess,
    @Inject(ENVIRONMENT) public environment: Environment,
  ) {}

  public async load() {
    // pass though language parameter
    let path = "environment";
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("language")) {
      path += "?language=" + urlParams.get("language");
    }

    const environment = await this.httpAccess.get(path, Environment);

    // assign to enviroment
    for (const key of Object.keys(environment)) {
      this.environment[key] = environment[key];
    }
  }

  public async setLanguage(languageId: number) {
    return this.httpAccess.put("environment/language/" + languageId, {});
  }

  public async setCurrency(currencyId: number) {
    await this.httpAccess.get("environment?currencyId=" + currencyId);
  }

  public async setBackendNew(backendNew: boolean) {
    return await this.httpAccess.put(
      "environment/backendNew/" + (backendNew ? "1" : "0"),
      {},
    );
  }
}
