import { DashboardConfig } from "./dashboard-config.model";
import { DashboardLocalStoragePersistor } from "./persistor/dashboard-local-storage-persistor.service";
import { DefaultTitleResolverService } from "./title-resolver/default-title-resolver.service";
import { DashboardPersistorInterface } from "./persistor/dashboard-persistor.interface";
import {
  TitleResolverInterface,
  WidgetTitle,
} from "./title-resolver/title-resolver.interface";
import { DashboardWidgetFilterInterface } from "./widget-filter/dashboard-widget-filter.interface";
import { Injectable, Injector, Type } from "@angular/core";
import {
  WidgetProperties,
  widgetPropertiesKey,
} from "./interface/widget-properties.interface";
import { WidgetSize } from "./interface/widget-size.interface";
import { DefaultInvalidWidgetPlaceholderComponent } from "../default-invalid-widget-placeholder/default-invalid-widget-placeholder.component";
import { isObject } from "@incert/incert-core";
import { DefaultWidgetImageResolverService } from "./widget-image-resolver/default-widget-image-resolver.service";
import { WidgetImageResolverInterface } from "./widget-image-resolver/widget-image-resolver.interface";

export interface WidgetCategoryTree {
  title: string;
  level: number;
  widgetAmount?: number;
  children?: WidgetCategoryTree[];
  hasWidgets?: boolean;
  id?: string;
  image?: string;
}

@Injectable()
export class DashboardConfigService {
  public persistor: DashboardPersistorInterface;
  public titleResolver: TitleResolverInterface;
  public widgetFilter: DashboardWidgetFilterInterface;
  public invalidWidgetPlaceholderComponent: Type<any>;

  private componentToComponentName: { [name: string]: Type<any> } = {};
  public widgetCategoryTree: WidgetCategoryTree[] = [];
  private imageResolver: WidgetImageResolverInterface;

  public constructor(private injector: Injector) {}

  public init(dashboardConfig: DashboardConfig) {
    if (!dashboardConfig.persistor) {
      this.persistor = this.injector.get(DashboardLocalStoragePersistor);
    } else {
      this.persistor = this.injector.get(dashboardConfig.persistor);
    }

    if (!dashboardConfig.imageResolver) {
      this.imageResolver = this.injector.get(DefaultWidgetImageResolverService);
    } else {
      this.imageResolver = this.injector.get(dashboardConfig.imageResolver);
    }

    if (!dashboardConfig.titleResolver) {
      this.titleResolver = this.injector.get(DefaultTitleResolverService);
    } else {
      this.titleResolver = this.injector.get(dashboardConfig.titleResolver);
    }

    if (dashboardConfig.widgetFilter) {
      this.widgetFilter = this.injector.get(dashboardConfig.widgetFilter);
    }
    if (dashboardConfig.invalidWidgetPlaceholderComponent) {
      this.invalidWidgetPlaceholderComponent =
        dashboardConfig.invalidWidgetPlaceholderComponent;
    } else {
      this.invalidWidgetPlaceholderComponent =
        DefaultInvalidWidgetPlaceholderComponent;
    }
    const processCategoryToWidgets = (
      categoryToWidgetTree: WidgetCategoryTree[],
      categoryToWidget: any,
      level = 0,
    ) => {
      let widgetAmount = 0;
      for (const key of Object.keys(categoryToWidget)) {
        const entry: WidgetCategoryTree = {
          title: this.titleResolver.resolveTitle("key", key, {
            id: key,
            title: key,
          }).title,
          id: key,
          widgetAmount: 0,
          children: [],
          level: level,
        };
        if (isObject(categoryToWidget[key])) {
          entry.widgetAmount = processCategoryToWidgets(
            entry.children,
            categoryToWidget[key],
            level + 1,
          );
        } else {
          for (const widget of categoryToWidget[key]) {
            if (widget && widgetPropertiesKey in widget) {
              const properties = <WidgetProperties<any>>(
                widget[widgetPropertiesKey]
              );
              if (
                this.widgetFilter &&
                !this.widgetFilter.filterWidget(widget)
              ) {
                // do nothing
              } else {
                this.componentToComponentName[properties.id] = widget;
                entry.children.push({
                  title: this.getWidgetTitle(properties.id).title,
                  id: properties.id,
                  image: this.imageResolver.resolveImage(
                    properties.id,
                    properties.title,
                    properties,
                  ),
                  level: level,
                });
              }
            }
          }
          entry.widgetAmount = entry.children.length;
          entry.hasWidgets = true;
        }
        if (entry.widgetAmount) {
          categoryToWidgetTree.push(entry);
        }
        widgetAmount += entry.widgetAmount;
      }
      return widgetAmount;
    };

    let categoryToWidget = {};
    if (Array.isArray(dashboardConfig.widgets)) {
      categoryToWidget = { default: dashboardConfig.widgets };
    } else {
      categoryToWidget = {
        all: dashboardConfig.widgets,
        ...dashboardConfig.widgets,
      };
    }

    processCategoryToWidgets(this.widgetCategoryTree, categoryToWidget);
  }

  public getWidgetIds() {
    return Object.keys(this.componentToComponentName);
  }

  public getComponent(widgetId: string) {
    return widgetId in this.componentToComponentName
      ? this.componentToComponentName[widgetId]
      : this.invalidWidgetPlaceholderComponent;
  }

  public getWidgetSize(
    widgetId: string,
    size: "default" | "min" | "max" = "default",
  ): WidgetSize {
    const component = this.getComponent(widgetId);
    if (component) {
      if (widgetPropertiesKey in component) {
        return (<WidgetProperties<any>>component[widgetPropertiesKey])[
          size + "WidgetSize"
        ];
      }
    }
  }

  public getWidgetTitle(widgetId: string): WidgetTitle {
    const component = this.getComponent(widgetId);
    if (component) {
      if (widgetPropertiesKey in component) {
        return this.titleResolver.resolveTitle(
          widgetId,
          (<WidgetProperties<any>>component[widgetPropertiesKey]).title,
          <WidgetProperties<any>>component[widgetPropertiesKey],
        );
      }
    }
    return null;
  }

  public getWidgetConfigurations(widgetId: string) {
    const component = this.getComponent(widgetId);
    if (component && widgetPropertiesKey in component) {
      return (<WidgetProperties<any>>component[widgetPropertiesKey])
        .configurations;
    }
  }

  public getWidgetDisablePrint(widgetId: string) {
    const component = this.getComponent(widgetId);
    if (component && widgetPropertiesKey in component) {
      return (
        (<WidgetProperties<any>>component[widgetPropertiesKey]).disablePrint ===
        true
      );
    }
    return false;
  }

  isEtrackerWidget(widgetId: string) {
    const component = this.getComponent(widgetId);
    if (component && widgetPropertiesKey in component) {
      return (
        (<WidgetProperties<any>>component[widgetPropertiesKey])
          .isEtrackerWidget === true
      );
    }
    return false;
  }
}
